// Customizable Area Start
import React from "react";
import { Grid, Input, InputAdornment, Button } from "@material-ui/core";
import { chatIcon } from "./assets";
import "./FeedBack.web.css";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";
import FeedBackController, { Props } from "./FeedBackController";
import TitleBox from "./components/TitleBox";
import FeedBackCustomInput from "./components/FeedBackCustomInput";
import CustomMenuSideBar from "../../../components/src/CustomMenuSideBar";
import ProtectedRouteWeb from "../../email-account-login/src/ProtectedRoute.web";
//@ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import ActivityPopup from "../../ActivityFeed/src/ActivityPopup.web";
// Customizable Area End

class FeedBack extends FeedBackController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }

  // Customizable Area Start

  render() {
    return (
      <ProtectedRouteWeb>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <section className="contactUsContainer sideBarContainer">
          <section className="sideBar">
            <CustomMenuSideBar />
          </section>
          <section
            className="sideBarInnerContainer "
            style={{ width: "798.23px", border: "1.6px solid #0F2C40" }}
          >
            <section className="">
              <div style={webStyle.frontbox}>
                <p style={webStyle.dialogBoxtitle}>FEEDBACK</p>
                <div style={webStyle.titleWrapper}>
                  <Grid>
                    <Input
                      id="title"
                      name="title"
                      placeholder="Type your title here..."
                      type="text"
                      style={webStyle.feedbackInput}
                      onChange={this.handleTitleTextInput}
                      value={this.state.TitleText}
                      endAdornment={
                        this.state.TitleText.length !== 0 ? (
                          <InputAdornment position="end">
                            {this.state.TitleText.length}/40
                          </InputAdornment>
                        ) : null
                      }
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>
                  <Grid style={{ textAlign: "end" }}>
                    <Button variant="contained" className="Feedbackbtn">
                      <img
                        src={chatIcon}
                        alt="FeedbackIcon"
                        style={webStyle.FeedbackIcon}
                      />{" "}
                      <p>Feedback</p>
                    </Button>
                  </Grid>
                </div>

                {/* Below box for Context */}
                <div style={{ marginTop: 35 }}>
                  <TitleBox
                    navigation={null}
                    id={""}
                    title="context"
                    contextImageUpload={this.contextImageUpload}
                    whatIsIt={"context"}
                    ImagePreview={this.state.contextImagePreview}
                    VideoUpload={this.state.contextVideoUpload}
                    removeImage={this.removeImage}
                    ErrorIn={
                      this.state.ErrorInContent ||
                      this.state.ErrorInContentMedia
                    }
                    imageCropPopup={this.state.imageCropPopup}
                    profileImageCrop={this.profileImageCrop}
                    handleDate={this.handleDate}
                    AddCropImageDailog={this.AddCropImageDailog}
                    setImageData={this.setImageDataFeedback}
                    ideaPicImageCrop={this.state.ideaPicImageCrop}
                  />

                  {this.state.contextImagePreview && (
                    <img
                      src={this.state.contextImagePreview}
                      style={webStyle.previewImage}
                    />
                  )}
                  {this.state.contextVideoUpload && (
                    <video width="100%" height="200" controls>
                      <source
                        src={window.URL.createObjectURL(
                          this.state.contextVideoUpload
                        )}
                        type="video/mp4"
                      />
                      {/* <source src={this.state.contextImageUpload}/> */}
                      Your browser does not support the video tag.
                    </video>
                  )}
                  <FeedBackCustomInput
                    navigation={null}
                    id={""}
                    handleTextChange={this.handleContextTextInput}
                    valueText={this.state.ContextText}
                    whatIsIt={"context"}
                  />
                </div>

                {/* Below box for Problem */}
                <div style={webStyle.probSolWrapper}>
                  <div style={webStyle.width345}>
                    <TitleBox
                      navigation={null}
                      id={""}
                      title="problem"
                      whatIsIt={"problem"}
                      contextImageUpload={this.contextImageUpload}
                      ImagePreview={this.state.problemImagePreview}
                      VideoUpload={this.state.problemVideoUpload}
                      removeImage={this.removeImage}
                      ErrorIn={
                        this.state.ErrorInProblem ||
                        this.state.ErrorInProblemMedia
                      }
                      imageCropPopup={this.state.imageCropPopup}
                      profileImageCrop={this.profileImageCrop}
                      handleDate={this.handleDate}
                      AddCropImageDailog={this.AddCropImageDailog}
                      setImageData={this.setImageDataFeedback}
                      ideaPicImageCrop={this.state.ideaPicImageCrop}
                    />
                    {this.state.problemImagePreview && (
                      <img
                        src={this.state.problemImagePreview}
                        style={webStyle.previewImage}
                      />
                    )}
                    {this.state.problemVideoUpload && (
                      <video width="100%" height="200" controls>
                        <source
                          src={window.URL.createObjectURL(
                            this.state.problemVideoUpload
                          )}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <FeedBackCustomInput
                      navigation={null}
                      id={""}
                      handleTextChange={this.handleProblemTextInput}
                      valueText={this.state.ProblemText}
                      whatIsIt={"problem"}
                    />
                  </div>

                  {/* Below box for Solution */}
                  <div style={webStyle.width345}>
                    <TitleBox
                      navigation={null}
                      id={""}
                      title="solution"
                      whatIsIt={"solution"}
                      contextImageUpload={this.contextImageUpload}
                      ImagePreview={this.state.solutionImagePreview}
                      VideoUpload={this.state.solutionVideoUpload}
                      removeImage={this.removeImage}
                      ErrorIn={
                        this.state.ErrorInSolution ||
                        this.state.ErrorInSolutionMedia
                      }
                      imageCropPopup={this.state.imageCropPopup}
                      profileImageCrop={this.profileImageCrop}
                      handleDate={this.handleDate}
                      AddCropImageDailog={this.AddCropImageDailog}
                      setImageData={this.setImageDataFeedback}
                      ideaPicImageCrop={this.state.ideaPicImageCrop}
                    />
                    {this.state.solutionImagePreview && (
                      <img
                        src={this.state.solutionImagePreview}
                        style={webStyle.previewImage}
                      />
                    )}
                    {this.state.solutionVideoUpload && (
                      <video width="100%" height="200" controls>
                        <source
                          src={window.URL.createObjectURL(
                            this.state.solutionVideoUpload
                          )}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <FeedBackCustomInput
                      navigation={null}
                      id={""}
                      handleTextChange={this.handleSolutionTextInput}
                      valueText={this.state.SolutionText}
                      whatIsIt={"solution"}
                    />
                  </div>
                </div>
                <div className="CustomErrorOverwrite">
                  <CustomErrorAlert
                    isError={this.state.openSnackBarError}
                    errorMsg={
                      "Text cannot contain any of the following characters: { } [ ] | "
                    }
                    handleError={this.onCustomAlertCloseButtonClick}
                    closeBtnDisabled={true}
                  />
                  <CustomErrorAlert
                    isError={this.state.totalFileError}
                    errorMsg={"Combined file size should be less than 50MB"}
                    handleError={this.onCustomAlertCloseButtonClick}
                  />
                  <CustomErrorAlert
                    isError={this.state.titleValidation}
                    errorMsg={"Please add title."}
                    handleError={this.onCustomAlertCloseButtonClick}
                  />
                  <CustomErrorAlert
                    isError={this.state.problemValidation}
                    errorMsg={"Either problem or solution are mandatory."}
                    handleError={this.onCustomAlertCloseButtonClick}
                  />
                  <CustomErrorAlert
                    isError={this.state.ExtentionError}
                    errorMsg={
                      "Only files with the following extensions are allowed: .png, .gif, .jpg, .jpeg, .mp4"
                    }
                    handleError={this.onCustomAlertCloseButtonClick}
                  />
                </div>
                <div style={{ position: "relative" }}>
                  <Button
                    style={webStyle.submitBtn}
                    variant="outlined"
                    onClick={this.submitFeedback}
                    id="submitBtnId"
                  >
                    SEND
                  </Button>
                </div>
                {this.state.isLoading && <p>Submiting. Please wait...</p>}
              </div>
            </section>
          </section>
        </section>

        <ActivityPopup
          testId="submitpopup"
          open={this.state.isAlertOpen}
          title={this.state.alertMessageText}
          leftBtnTxt="ok"
          handleLeftBtn={this.handleCloseAlertMessagePopup}
        />
      </ProtectedRouteWeb>
    );
  }

  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  frontbox: {
    color: "#6A8393",
    border: "none",
    padding: "60px 84px",
    fontFamily: "Jost-Regular",
    marginRight: "auto",
    fontWeight: 400,
    minHeight: "500",
    width: "auto"
  },
  dialogBoxtitle: {
    fontFamily: "Jost-Regular",
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "32px",
    letterSpacing: "0.1em",
    // TextTransform: "uppercase",
    color: "#6A8393",
    justifyContent: "center",
    paddingBottom: "36px",
    margin: "0px"
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0px 20px",
    maxHeight: 39,
    "& .Mui-focused": {
      background: "#012540",
      transition: "0.1s ease-in-out"
    }
  },
  probSolWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0px 20px",
    marginTop: "30px"
  },
  feedbackInput: {
    width: 500,
    color: "white",
    backgroundColor: "#091b27",
    maxHeight: "39px !important",
    padding: "11px 18px",
    fontFamily: "Jost-Regular",
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "0",
    height: "40px",
    borderRadius: "4px"
  },
  FeedbackIcon: {
    paddingRight: "1rem",
    color: "white,"
  },
  width345: {
    alignSelf: "flex-start",
    width: "345"
  },
  submitBtn: {
    border: "1.6px solid #F1F2F2",
    width: "200px",
    height: 40,
    BoxSizing: "border-box",
    borderRadius: "4px",
    color: "#F1F2F2",
    marginLeft: "auto",
    marginRight: "auto",
    Position: "relative",
    marginTop: 40,
    fontFamily: "Jost-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19px"
  },
  previewImage: {
    width: "100%",
    ObjectFit: "cover",
    height: "200px",
    borderRadius: "4px"
  },
};
export default FeedBack;
//@ts-ignore
// export default withStyles(useStyles)(FeedBack);
// Customizable Area End
