import React, { CSSProperties } from "react";
// Customizable Area Start
import { Grid, Tooltip, ClickAwayListener } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import FeedBackController, { Props } from "../FeedBackController";
import { infoIcon, PlusIcon } from "../../../postcreation/src/assets";
// @ts-ignore
import PopUpMenuFeedBack from "./PopUpMenuFedback.web";
import { reloadIcon } from "../assets";
import "./PopUpMenu.web.css";


const tooltiptext = {
    context: [
        "Where and when did you encounter the problem you would like to see solved by the Idea Atlas Team? Add a full screen screenshot if possible.",
    ],
    problem: [
        "What is the problem in your view? Please let us know if you encounter it before or if you believe it is a major and/or urgent issue. Feel free to add a specific screenshot or error code where applicable.",
        "If you would like to make a suggestion for a new feature or intending to share your UI/UX idea, you can leave this box empty."
    ],
    solution: [
        "Do you know how to solve the problem? How would you approach it? Outline the best option(s) or feel free to share a specific solution by attaching an image/screenshot, hyperlink, or video."
    ]
};

const useStyles = (theme: Theme) => ({
    LabelBox: {
        width: '100%',
        color: "#f1f2f2",
        backgroundColor: "#091E2A",
        borderRadius: "4px 4px 0 0",
        height: "auto",
        maxHeight: '39px',
        border: "none",
        padding: "11px 18px",
        fontFamily: "Jost-Regular",
        fontWeight: 400,
        marginBottom: "2px",
        display: "flex",
        justifyContent: "space-between",
        textTransform: 'uppercase',
    },
    formlabel: {
        fontSize: 16,
        fontFamily: "Jost-Regular",
        fontWeight: "500",
        lineHeight: "26px",
        letterSpacing: "0.1em"
    },

});


// Customizable Area End


class FeedBack extends FeedBackController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start 
    getStyle = (stateValue: any, style1: string, style2: string) => {
        return stateValue ? style1 : style2;
      };
    render() {
        console.log("ideaPicImageCrop",this.props.ideaPicImageCrop,this.props.imageCropPopup)
        return (
            <Grid container spacing={0}>
                <div className={`${this.props.classes.LabelBox} ${this.getStyle(
                    this.props.ErrorIn,
                    "Error",
                    ""
                  )}`} >
                    <label className="flex-box form-label">
                        <img data-testId="getImage" src={this.getImage(this.props.title || "")} />
                        <p className={this.props.classes.formlabel + " no-margin"}>{this.props.title}</p>
                        <Grid item>
                            <Tooltip
                                arrow={true}
                                classes={{ tooltip: "customToolTip" }}
                                title={tooltiptext[this.props.title!].map((e) => {
                                    return (
                                        <ul className="toolTipUL">
                                            <li>{e}</li>
                                        </ul>
                                    );
                                })}
                                placement="right-start"
                            >
                                <img src={infoIcon} alt="" className="info-icon" />
                            </Tooltip>
                        </Grid>
                    </label>

                    {(this.props.ImagePreview || this.props.VideoUpload) === null ? 
                    <ClickAwayListener onClickAway={this.handleTooltipCloseContent}>
                        <div className="w-15 addNewIdeaTooltip">
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true
                                }}
                                onClose={this.handleTooltipCloseContent}
                                open={this.state.openPlusDialogContent}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                classes={{
                                    tooltip: "menu-pop-up",
                                    arrow: "menu-pop-up-arrow"
                                }}
                                interactive={true}
                                title={
                                    <React.Fragment>
                                        <PopUpMenuFeedBack
                                                     navigation={undefined}
                                                     id={""}
                                                     contextImageUpload={this.props.contextImageUpload}
                                                     title={this.props.whatIsIt}
                                                     handleHyperLink={() => {}}
                                                     imageCropPopup={this.props.imageCropPopup}
                                                     profileImageCrop={this.props.profileImageCrop}
                                                     handleDate={this.props.handleDate}
                                                     AddCropImageDailog={this.props.AddCropImageDailog}
                                                     setImageData={this.props.setImageData}
                                                     ideaPicImageCrop={this.props.ideaPicImageCrop}
                                        />
                                    </React.Fragment>
                                }
                                arrow={true}
                                placement="right"
                            >
                                <img
                                    src={PlusIcon}
                                    className="plus-icon"
                                    alt=""
                                    onClick={this.handlePlusContent}
                                />
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                        : <div>
                            <img
                                src={reloadIcon}
                                className="plus-icon"
                                alt=""
                                onClick={this.handleReloadIcon}
                                title="Reset"
                            />
                        </div>
                    }
                </div>
            </Grid >
 // Customizable Area End
        );
    }
}

// Customizable Area Start

//@ts-ignore
export default withStyles(useStyles)(FeedBack)
export {FeedBack}
// Customizable Area End
