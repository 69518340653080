import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  MenuItem,
  ClickAwayListener,
  Tooltip,
  withStyles,
  Badge,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import "./WebHeader.css";
import { WebAddIconSvg, noProfileImg, NotificationsIconSvg } from "../assets";
import EditProfilepopUp from "./EditProfilePopUp";
import Popover from "@material-ui/core/Popover";
import NotificationPopUp from "./NotificationPopUp";
import CustomSearch from "../../../blocks/AdvancedSearch/src/CustomSearch.web";
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";

const WebHeader = () => {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" style={webStyle.appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className="topHeader">
          <NavLink style={webStyle.NavLink} to="/ActivityFeed">
            <img src={require("./images/logo.svg")} />
          </NavLink>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              marginLeft: "-11rem",
            }}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              className="menubarIcon"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MenuItem>
                <NavLink to="/" style={webStyle.linkName} className="iconHightlight">
                  <AddIcon className="iconPosition" />
                </NavLink>
              </MenuItem>
              <NavLink to="/" style={webStyle.linkName}>
                <NotificationsNoneIcon className="iconPosition" />
              </NavLink>
              <NavLink to="/" style={webStyle.linkName}>
                <img
                  src={require("./images/user_profile.png")}
                  alt="profile"
                  id="Web_user_profile1"
                  className="profileIcon"
                />
              </NavLink>
            </Menu>
            {/* <ResponsiveAppBarRoutes /> */}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <CustomSearch />
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />

          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            className="appBarRoute"
          >
            <ResponsiveAppBarRoutes />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
const ResponsiveAppBarRoutes = () => {

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [openNotification, setOpenNotification] = useState<boolean>(false)
  const [hideBadge, setHideBadge] = useState(false)
  const [badgeCount, setBadgeCount] = useState(0)
  const [unreadNotifications, setUnreadNotifications] = useState([])
  const [counter, setCounter] = useState(0)
  //  const counter = useRef([0])
  // var counter = 0
  // useCallback(()=>{
  //  console.log("useRef", counter)
  // }, [counter])

  useEffect(() => {
    getBadgeCount()

    let something = setInterval(async () => {
      setCounter(prev => prev + 1)
    }, [20000])

    return () => clearInterval(something);

  }, [])


  useEffect(() => {

    getBadgeCount()
  }, [counter])




  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeNotificationAsRead = () => {
    unreadNotifications.length > 0 && unreadNotifications.map((el: any) => {

      fetch(`${baseURL}/bx_block_notifications/notifications/${el.id}`, {
        method: "PATCH",
        headers: { "token": localStorage.getItem('authToken') || '' }
      })
        .then(response => response.json())
        .then(data => {
        }).catch(
          () => {

          }
        );

    })

  }


  const getBadgeCount = () => {
    if (!openNotification) {
      //console.log(openNotification, Date.now())
      let tempData: any = []
      fetch(`${baseURL}/bx_block_notifications/notifications`, {
        method: "GET",
        headers: { "token": localStorage.getItem('authToken') || '' }
      })
        .then(response => response.json())
        .then(res => {
          if (res?.data?.length > 0)
            tempData = res.data
        }).finally(() => {
          if (tempData?.length > 0) {
            let temp: any = []
            tempData.map((el: any) => {
              if (el.attributes.is_read !== true && (el.attributes.is_user_notify)) {
                if (el.attributes.is_notify) {
                  temp.push(el)
                }
              }
            })
            setBadgeCount(temp.length || 0)
            setUnreadNotifications(temp)
          }

        })
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  const NotificationToolTip = withStyles({
    tooltip: {
      minHeight: 50,
      backgroundColor: '#081b27 !important',
      maxWidth: 'max-content',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.502)'
    },
    arrow: {
      "&:before": {
        backgroundColor: '#081b27 !important',
      }
    },
  })(Tooltip)

  const setOpenNotificationFalse = () => {
    setTimeout(() => {
      setOpenNotification(false)
    }, 0);
  }
  const setOpenNotificationTrue = () => {
    setOpenNotification(!openNotification)
    setHideBadge(true)
    if (unreadNotifications.length > 0) {
      makeNotificationAsRead()
    }
  }

  useEffect(() => {
    //console.log(openNotification, 'openNotification')
  }, [openNotification])

  return (
    <>
      <NavLink to="/AddNewIdea" style={webStyle.linkName} className="iconHightlight webAddIcon">
        <WebAddIconSvg className="webAddIconImg" />
      </NavLink>
      <ClickAwayListener onClickAway={setOpenNotificationFalse}>
        <button style={webStyle.linkName} className= "btnLinkname iconHightlight" onClick={() => { setOpenNotificationTrue() }}>
          <NotificationToolTip
            PopperProps={{
              disablePortal: true,
              popperOptions: {
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: '40px, 20px',
                  },
                },
              },
            }}
            // onClose={ToggleToolTip}
            open={openNotification}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            interactive={true}
            classes={{
              tooltip: 'notificationTooltip'
            }}
            className="Notificationhello"
            title={
              <NotificationPopUp
                //@ts-ignore
                id={""}
                navigation={() => { }}
                closePopUP={setOpenNotificationFalse}
              />
            }
            arrow={true}
            placement="bottom-end"
          >
            <Badge badgeContent={badgeCount} max={1000} color="error" invisible={badgeCount == 0 ? true : hideBadge} >
              <NotificationsIconSvg className="MuiSvgIcon-root" />
            </Badge>
          </NotificationToolTip>
        </button>
      </ClickAwayListener>
      <button
        style={webStyle.linkName}
        onClick={handleClick}
        // onMouseEnter={handleClick}  
        className="btnLinkname iconHightlight"
      >
        <img
          src={
            (localStorage.getItem("profilepic") &&
              localStorage.getItem("profilepic") !== "null")
              ? (baseURL +
                localStorage.getItem("profilepic"))
              : noProfileImg
          }
          alt="profile"
          id="Web_user_profile2"
          className={localStorage.getItem("profilepic") && localStorage.getItem("profilepic") !== "null" ? "profileIcon" : "MuiSvgIcon-root"}
        />
      </button>
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className="EditprofilePopover"
        >
          <EditProfilepopUp handleClose={handleClose} />
        </Popover>
      </>
    </>
  );
};
const webStyle = {
  appBar: {
    backgroundColor: "#030F17",
    zIndex: 1501,
  },
  NavLink: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "inherit",
    height: 36
  },
  logoStyle: {
    height: "50px",
    marginLeft: "30px",
  },
  linkName: {
    // color: "rgb(4, 60, 116)",
    display: "block",
    margin: "7.5px",
    textDecoration: "none",
    fontFamily: "SF Pro Display",
    fontStyle: "normal",
    fontWeight: 400,
    border: "1.5px solid white",
    borderRadius: "50%",
    height: "37px",
    width: "37px",
    color: "white",
    background: "transparent",
  },
  connect: {
    backgroundColor: "orange",
    padding: "10px 20px",
    borderRadius: "5px",
    margin: "5px",
  },
  IconButton: {
    color: "rgb(4, 60, 116)",
  },
};

export default WebHeader;
