import React from "react";
import "./Common.web.css";

const CustomIconButton = (props: any) => {
    const {
      classNameStyle,
      handleOnClick,
      handleOnMouseEnter,
      handleOnMouseLeave,
      buttonIcon,
      hoverEnabled,
      hoverText,
      testId
    } = props;
    return (
      <button
        data-testid={testId}
        id="customIconButton"
        className={`customIconBtn ${classNameStyle ? classNameStyle: ""}`}
        onClick={handleOnClick}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <img src={buttonIcon} />
        {hoverEnabled && <p className="customIconBtnText">{hoverText}</p>}
      </button>
    );
  };

export default CustomIconButton;