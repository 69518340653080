import React from "react";
// Customizable Area Start
//@ts-ignore
import "./AddNewIdea.web.css";
import { ClickAwayListener, Select, MenuItem, Input as MUIInput, TextField, Button } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import {
  ContextIcon,
  SolutionIcon,
  ProblemIcon,
  crossIcon,
  logoIcon,
  LockIcon,
  WorldIcon,
  GroupIcon,
  PlusIcon,
  DropDownIcon,
  InfoReactIcon,
  revertIcons
} from "./assets";
import AddNewIdeaController from "./AddNewIdeaController";
import PopUpMenu from "./PopUpMenu.web";
import ProtectedRoute from "../../email-account-login/src/ProtectedRoute.web";
import { reloadIcon } from "../../customform/src/assets";
import HyperLinkModal from "./HyperLinkModal.web";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";
//@ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import CustomConfirmation from "../../../components/src/CustomConfirmation";
import { noProfileImg } from "../../user-profile-basic/src/assets";
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";
import CustomTypography from "../../../components/src/CustomTypography";



const getProfilePic = (contextPic: any) => {
  return (
    contextPic ||
    (localStorage.getItem("profilepic") &&
      localStorage.getItem("profilepic") !== "null"
      ? baseURL + localStorage.getItem("profilepic")
      : null) ||
    noProfileImg
  );
};

const DisplayImage = (props: any) => {
  const {
    imagePreview,
    imagePreviewStyle,
    videoUpload,
    textWithHyperLink,
  } = props;

  return (
    <>
      {imagePreview && <img src={imagePreview} className={imagePreviewStyle} />}
      {videoUpload && (
        <video width="100%" height="200" controls>
          <source
            src={window.URL.createObjectURL(videoUpload)}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      )}
      {textWithHyperLink && (
        <iframe
          width="100%"
          height="200"
          src={`${textWithHyperLink}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Attached HyperLink"
        />
      )}
    </>
  );
};

const getStyle = (stateValue: any, style1: string, style2: string) => {
  return stateValue ? style1 : style2;
};

// Customizable Area End

export default class AddNewIdea extends AddNewIdeaController {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start   
    // Customizable Area End

    render() {
        // Customizable Area Start

    const options = [
          {
              img: GroupIcon,
              text: "Contacts"
          },
          {
            img: WorldIcon,
            text: "Platform"
          },
          {
            img: LockIcon,
            text: "Private"
          }
        ];
    const tooltiptext = {
      content: [
        {
          id: 1,
          text:
            "Where and when did you encounter the problem you are trying to solve here? Explain the situation as clearly as you can.",
        },
        {
          id: 2,
          text:
            "It might be useful to attach a photo, map, graph, or video to provide useful insights or an article to provide background information.",
        },
      ],
      problem: [
        {
          id: 1,
          text:
            "What is the problem and why did you encounter it? Try to estimate the impact or frequency of the issue to be solved. (Also explain if it might be urgent or exceptionally important)",
        },
        {
          id: 2,
          text:
            "Alternatively, describe the value of a new opportunity you found and elucidate the specifics of the challenge you are facing.",
        },
      ],
      solution: [
        {
          id: 1,
          text:
            "Which solution would you implement and how would you approach it? Outline the best scenario or your first attempt at developing one.",
        },
        {
          id: 2,
          text:
            "Be specific and constructive in your framing of the solution. Try to identify the key stakeholders and partners your solution requires to work well.",
        },
      ],
    };
    return (
      <ProtectedRoute>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <CustomTypography text="Add new idea" />
        <form className="AddNewIdeaBox test">
          <div className="arrow-left">
            <div className="profileImage">
              <img
                src={getProfilePic(this.context.profile_pic)}
                alt="profilePic"
              />
            </div>
          </div>

          <div
            className="flex-box sm-view addTitleBox"
            style={{ justifyContent: "space-between" }}
          >
            <MUIInput
              id="title"
              name="title"
              placeholder="Type your title here..."
              type="text"
              className={`AddNewIdeaInput text-color-white ${getStyle(
                this.state.TitleText,
                "titleAdded",
                ""
              )}`}
              onChange={this.handleTitleChange}
              value={this.state.TitleText}
              endAdornment={
                this.state.TitleText.length !== 0 ? (
                  <InputAdornment position="end">
                    {this.state.TitleText.length}/44
                  </InputAdornment>
                ) : null
              }
              inputProps={{ maxLength: 44, width: "100%" }}
            />

            <Select
              renderValue={() => {
                return (
                  <div className="addNewIdeaSelectText">
                    <img
                      // src={PrivacyAddNewIdeaImg}
                      src={this.getImage(
                        this.state.PrivacySelectAddNewIdea,
                        options
                      )}
                      alt=""
                      className="drop-down-image"
                    />
                    <p>{this.state.PrivacySelectAddNewIdea}</p>
                  </div>
                );
              }}
              value={this.state.PrivacySelectAddNewIdea}
              label="Private"
              className="AddNewIdeaSelect dropdown-padding"
              MenuProps={{ classes: { paper: "work arrow-top" } }}
              onChange={this.addNewideaDropdownFun}
              IconComponent={DropDownIcon}
              disableUnderline
            >
              {options.map((e: { img: any; text: string }) => {
                if (e.text === (this.state.PrivacySelectAddNewIdea as string)) {
                  return false;
                } else {
                  return (
                    <MenuItem
                      key={e.text}
                      value={e.text}
                      className="AddNewIdeaDropDownItem"
                    >
                      <img src={e.img} alt="" className="drop-down-image " />
                      <p className={"SelectMenuItem"}>{e.text}</p>
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </div>

          <div
            className={`boxSeparator ${getStyle(
              this.state.ContentText,
              "editedIdea",
              ""
            )}`}
          >
            <div
              className={`LabelBox ${getStyle(
                this.state.ErrorInContent,
                "Error",
                ""
              )} ${getStyle(this.state.ErrorInContentMedia, "Error",
              "")}`}
            >
              <label className="form-label flex-box">
                <div className={"img-center"}>
                  <img src={ContextIcon} alt="ContextIcon" />
                </div>
                <p className="no-margin addNewIdeaTitleBoxFont18">CONTEXT</p>
                <Grid item>
                  <Tooltip
                    arrow={true}
                    classes={{ tooltip: "customToolTip" }}
                    title={tooltiptext.content.map((e) => {
                      return (
                        <ul className="toolTipUL" key={e.id}>
                          <li>{e.text}</li>
                        </ul>
                      );
                    })}
                    placement="right-start"
                  >
                    <InfoReactIcon
                      alt="infoIcon"
                      className={getStyle(
                        this.state.ErrorInContent,
                        "errInfoIcon",
                        ""
                      )}
                    />
                  </Tooltip>
                </Grid>
              </label>
              <div style={{ display: "flex" }}>
                {this.state.ContentText.length > 0 ?
                  <Button
                    className="cursorPointer feedHoverUndo"
                    data-title="Undo changes"
                    style={{
                      minWidth: 0,
                      padding: 0,
                      marginRight: "14px",
                      color: "Gray"
                    }}
                    onClick={this.handleContextUndo}
                  >
                    <img
                      src={revertIcons}
                      alt=""
                      color="gray"

                    />
                  </Button> : null}
                {this.checkContextMedia() ? (
                  <ClickAwayListener onClickAway={this.handleTooltipCloseContent}>
                    <div className="w-15 plusTooltipoption">
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        placement="right-start"
                        onClose={this.handleTooltipCloseContent}
                        open={this.state.openPlusDialogContent}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        classes={{
                          tooltip: "menu-pop-up",
                          arrow: "menu-pop-up-arrow",
                        }}
                        interactive={true}
                        title={
                          <React.Fragment>
                            <PopUpMenu
                              navigation={undefined}
                              id={""}
                              contextImageUpload={this.contextVideoUploadAddnewIdea}
                              title={"context"}
                              handleHyperLink={this.handleClickOpen}
                              imageCropPopup={this.state.imageCropPopup}
                              profileImageCrop={this.profileImageCrop}
                              handleDate={this.handleDate}
                              AddCropImageDailog={this.AddCropImageDailog}
                              setImageData={this.setImageDataAddIdea}
                              ideaPicImageCrop={this.state.ideaPicImageCrop}

                            />
                          </React.Fragment>
                        }
                        arrow={true}
                      >
                        <Button
                          style={{ minWidth: 0, padding: 0 }}
                          onClick={this.handlePlusContent}
                        >
                          <img
                            src={PlusIcon}
                            className="plus-icon"
                            alt=""
                            onClick={this.handlePlusContent}
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  </ClickAwayListener>
                ) : (
                  <div>
                    <img
                      src={reloadIcon}
                      className="plus-icon"
                      alt=""
                      onClick={this.handleCntxtRemoveIcon}
                      title="Reset"
                    />
                  </div>
                )}
              </div>
            </div>
            <DisplayImage
              imagePreview={this.state.contextImagePreview}
              imagePreviewStyle="contextPreviewImage"
              videoUpload={this.state.contextVideoUpload}
              textWithHyperLink={this.state.ContentTextWithHyperLink}
            />

            {/* <Markup content={this.state.ContentText} /> */}
            <TextField
              id="context"
              minRows={1}
              className="form-control"
              classes={{
                root: "height-auto",
              }}
              onChange={this.handleContextTextFun}
              value={this.state.ContentText}
              multiline
              placeholder="Type context here..."
              InputProps={{
                endAdornment: (
                  <>
                    {this.state.ContentText.length ? (
                      <InputAdornment
                        position="end"
                        className="text-field-Adornment"
                      >
                        {this.state.ContentText.length}/440
                      </InputAdornment>
                    ) : (
                      <InputAdornment
                        position="end"
                        className="text-field-Adornment"
                        style={{ visibility: "hidden" }}
                      >
                        {this.state.ContentText.length}/440
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />
          </div>

          <div className="flex-box justify-content smbottom">
            <div
              className={`smaller-input ${getStyle(
                this.state.ProblemText,
                "editedIdea",
                ""
              )}`}
            >
              <div
                className={`LabelBox ${getStyle(
                  this.state.ErrorInProblem,
                  "Error",
                  ""
                )} ${getStyle(this.state.ErrorInProblemMedia, "Error",
                "")}`}
              >
                <label className="form-label flex-box">
                  <div className={"img-center"}>
                    <img src={ProblemIcon} alt="ProblemIcon" />
                  </div>
                  <p className="no-margin addNewIdeaTitleBoxFont18">PROBLEM</p>
                  <Grid item>
                    <Tooltip
                      arrow={true}
                      classes={{ tooltip: "customToolTip" }}
                      title={tooltiptext.problem.map((e) => {
                        return (
                          <ul className="toolTipUL" key={e.id}>
                            <li>{e.text}</li>
                          </ul>
                        );
                      })}
                      placement="right-start"
                    >
                      <InfoReactIcon
                        alt="infoIcon"
                        className={getStyle(
                          this.state.ErrorInProblem,
                          "errInfoIcon",
                          ""
                        )}
                      />
                    </Tooltip>
                  </Grid>
                </label>
                <div style={{ display: "flex" }}>
                  {this.state.ProblemText.length > 0 ?
                    <Button
                      className="cursorPointer feedHoverUndo"
                      data-title="Undo changes"
                      style={{
                        minWidth: 0,
                        padding: 0,
                        marginRight: "14px",
                        color: "Gray"
                      }}
                      onClick={this.handleProblemUndo}
                    >
                      <img
                        src={revertIcons}
                        alt=""
                        color="gray"

                      />
                    </Button> : null}
                  {this.checkProblemMedia() ? (
                    <ClickAwayListener
                      onClickAway={this.handleTooltipCloseProblem}
                    >
                      <div className="w-15 plusTooltipoption">
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement="right-start"
                          onClose={this.handleTooltipCloseProblem}
                          open={this.state.openPlusDialogProblem}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          classes={{
                            tooltip: "menu-pop-up",
                            arrow: "menu-pop-up-arrow",
                          }}
                          interactive={true}
                          title={
                            <React.Fragment>
                              <PopUpMenu
                                navigation={undefined}
                                id={""}
                                contextImageUpload={this.contextVideoUploadAddnewIdea}
                                title={"problem"}
                                handleHyperLink={this.handleClickOpen}
                                imageCropPopup={this.state.imageCropPopup}
                                profileImageCrop={this.profileImageCrop}
                                handleDate={this.handleDate}
                                AddCropImageDailog={this.AddCropImageDailog}
                                setImageData={this.setImageDataAddIdea}
                                ideaPicImageCrop={this.state.ideaPicImageCrop}

                              />
                            </React.Fragment>
                          }
                          arrow={true}
                        >
                          <Button
                            style={{ minWidth: 0, padding: 0 }}
                            onClick={this.handlePlusProblem}
                          >
                            <img
                              src={PlusIcon}
                              className="plus-icon"
                              alt=""
                              onClick={this.handlePlusProblem}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <div>
                      <img
                        src={reloadIcon}
                        className="plus-icon"
                        alt=""
                        onClick={this.handleProbRemoveIcon}
                        title="Reset"
                      />
                    </div>
                  )}
                </div>
              </div>

              <DisplayImage
                imagePreview={this.state.problemImagePreview}
                imagePreviewStyle="previewImage"
                videoUpload={this.state.problemVideoUpload}
                textWithHyperLink={this.state.ProblemTextWithHyperLink}
              />

              <TextField
                id="problem"
                minRows={1}
                className="form-control"
                classes={{
                  root: "height-auto",
                }}
                onChange={this.problemTextFun}
                value={this.state.ProblemText}
                multiline
                placeholder="Type problem here..."
                InputProps={{
                  endAdornment: (
                    <>
                      {this.state.ProblemText.length ? (
                        <InputAdornment
                          position="end"
                          className="text-field-Adornment"
                        >
                          {this.state.ProblemText.length}/440
                        </InputAdornment>
                      ) : (
                        <InputAdornment
                          position="end"
                          className="text-field-Adornment"
                          style={{ visibility: "hidden" }}
                        >
                          {this.state.ProblemText.length}/440
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </div>
            <div
              className={`smaller-input ${getStyle(
                this.state.SolutionText,
                "editedIdea",
                ""
              )}`}
            >
              <div
                className={`LabelBox ${getStyle(
                  this.state.ErrorInSolution,
                  "Error",
                  ""
                )} ${getStyle(this.state.ErrorInSolutionMedia, "Error",
                "")}`}
              >
                <label className="form-label flex-box">
                  <div className={"img-center"}>
                    <img src={SolutionIcon} alt="SolutionIcon" />
                  </div>
                  <p className="no-margin addNewIdeaTitleBoxFont18">SOLUTION</p>
                  <Grid item>
                    <Tooltip
                      arrow={true}
                      classes={{ tooltip: "customToolTip" }}
                      title={tooltiptext.solution.map((e) => {
                        return (
                          <ul className="toolTipUL" key={e.id}>
                            <li>{e.text}</li>
                          </ul>
                        );
                      })}
                      placement="right-start"
                    >
                      <InfoReactIcon
                        alt="infoIcon"
                        className={getStyle(
                          this.state.ErrorInSolution,
                          "errInfoIcon",
                          ""
                        )}
                      />
                    </Tooltip>
                  </Grid>

                  <div />
                </label>
                <div style={{ display: "flex" }}>
                  {this.state.SolutionText.length > 0 ?
                    <Button
                      className="cursorPointer feedHoverUndo"
                      data-title="Undo changes"
                      style={{
                        minWidth: 0,
                        padding: 0,
                        marginRight: "14px",
                        color: "Gray"
                      }}
                      onClick={this.handleSolutionUndo}
                    >
                      <img
                        src={revertIcons}
                        alt=""
                        color="gray"

                      />
                    </Button> : null}
                  {this.checkSolutionMedia() ? (
                    <ClickAwayListener
                      onClickAway={this.handleTooltipCloseSolution}
                    >
                      <div className="w-15 plusTooltipoption">
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement="right-start"
                          onClose={this.handleTooltipCloseSolution}
                          open={this.state.openPlusDialogSolution}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          classes={{
                            tooltip: "menu-pop-up",
                            arrow: "menu-pop-up-arrow",
                          }}
                          interactive={true}
                          title={
                            <React.Fragment>
                              <PopUpMenu
                                navigation={undefined}
                                id={""}
                                contextImageUpload={this.contextVideoUploadAddnewIdea}
                                title={"solution"}
                                handleHyperLink={this.handleClickOpen}
                                imageCropPopup={this.state.imageCropPopup}
                                profileImageCrop={this.profileImageCrop}
                                handleDate={this.handleDate}
                                AddCropImageDailog={this.AddCropImageDailog}
                                setImageData={this.setImageDataAddIdea}
                                ideaPicImageCrop={this.state.ideaPicImageCrop}

                              />
                            </React.Fragment>
                          }
                          arrow={true}
                        >
                          <Button style={{ minWidth: 0, padding: 0 }}>
                            <img
                              src={PlusIcon}
                              className="plus-icon"
                              alt=""
                              onClick={this.handlePlusSolution}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <div>
                      <img
                        src={reloadIcon}
                        className="plus-icon"
                        alt="Reset ICon"
                        onClick={this.handleSolRemoveIcon}
                        title="Reset"
                      />
                    </div>
                  )}
                </div>
              </div>

              <DisplayImage
                imagePreview={this.state.solutionImagePreview}
                imagePreviewStyle="previewImage"
                videoUpload={this.state.solutionVideoUpload}
                textWithHyperLink={this.state.SolutionTextWithHyperLink}
              />

              <TextField
                id="solution"
                minRows={1}
                className={"form-control"}
                classes={{
                  root: "height-auto",
                }}
                onChange={(e) => {
                  this.setState(
                    { SolutionText: e.target.value.substring(0, 440) },
                    () => {
                      this.ErrorCheckInSolutionAddIdea();
                    }
                  );
                }}
                value={this.state.SolutionText}
                multiline
                placeholder="Type solution here..."
                InputProps={{
                  endAdornment: (
                    <>
                      {this.state.SolutionText.length ? (
                        <InputAdornment
                          position="end"
                          className="text-field-Adornment"
                        >
                          {this.state.SolutionText.length}/440
                        </InputAdornment>
                      ) : (
                        <InputAdornment
                          position="end"
                          className="text-field-Adornment"
                          style={{ visibility: "hidden" }}
                        >
                          {this.state.SolutionText.length}/440
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </div>
          </div>

          <div className="CustomErrorOverwrite">
            <CustomErrorAlert
              isError={this.state.openSnackBarError}
              errorMsg={
                "Text cannot contain any of the following characters: { } [ ] | "
              }
              handleError={this.onCustomAlertCloseButtonClick}
              closeBtnDisabled={true}
            />
            <CustomErrorAlert
              isError={this.state.totalFileError}
              errorMsg={"Combined file size should be less than 50MB"}
              handleError={this.onCustomAlertCloseButtonClick}
              closeBtnDisabled={true}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomConfirmation
                isSuccess={this.state.ideaAdded} //Overwrite: this.props.displayConfirmMsg this was making the confirmation appear on all ideas
                SuccessMsg={"Idea Added!"}
                closeIcon={true}
                handleSuccessCloseIcon={this.handleSuccessCloseIcon}
              />
            </div>
            <CustomErrorAlert
              isError={this.state.titleValidation}
              errorMsg={"Please add title."}
              handleError={this.onCustomAlertCloseButtonClick}
              closeBtnDisabled={true}
            />
            <CustomErrorAlert
              isError={this.state.problemValidation}
              errorMsg={"Either problem or solution are mandatory."}
              handleError={this.onCustomAlertCloseButtonClick}
            />
            <CustomErrorAlert
              isError={this.state.ExtentionError}
              errorMsg={
                "Only files with the following extensions are allowed: .png, .gif, .jpg, .jpeg, .mp4"
              }
              handleError={this.onCustomAlertCloseButtonClick}
              closeBtnDisabled={true}
            />
          </div>
          <div className="center">
            {/* Use Avatar can be implemented for below */}
            <Button
              onClick={this.crossButtonHandle}
              style={{ minWidth: 0, padding: 0 }}
            >
              <img
                src={crossIcon}
                alt=""
                className="footer-icon"
                title="Clear everything"
              />
            </Button>
            <Button
              onClick={this.submitIdea}
              style={{ minWidth: 0, padding: 0 }}
            >
              <img
                src={logoIcon}
                alt=""
                className="footer-icon"
                title="Submit"
              />
            </Button>
          </div>
        </form>

        {/* Model for HyperLink */}

        {this.state.openHyperLinkDialoge && (
          <HyperLinkModal
            handleClose={this.handleClose}
            handleClickOpen={this.handleClickOpen}
            submitHyperLink={this.submitHyperLink}
            title={this.state.HyperLinkModalTitle}
            hyperLinkError={this.state.hyperLinkError}
            onHyperlinkErrorClose={this.onHyperlinkErrorClose}
          />
        )}

        {/* </div> */}
      </ProtectedRoute>
    );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
