import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import "./ActivityFeed.css";
import {
  imgPasswordInVisible, imgPasswordVisible, lockIcon, worldIcon, reportIconWhite,
  revertIcon,
  hideIconWhite
} from "./assets";
import { BlurPost } from "./ActivityHelpers.web";
import moment from 'moment';
import 'moment-timezone';
export let profileId: number;
type errorType = boolean | string | string[];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  TitleText?: string;
  countNeeded?: number;
  whatIsIt?: string;
  contextImageUpload?: any;
  ProblemImageUpload?: any;
  SolutionImageUpload?: any;
  ImagePreview?: any;
  VideoUpload?: any;
  removeImage?: any;
  title?: "context" | "problem" | "solution";
  handleHyperLink?: any;
  feedFilter?: string;
  text?: string;
  handleFeedFilter?: any;
  openPlusDialogContent?: boolean;
  handleTooltipCloseContent?: any;
  handleClickOpen?: any;
  handlePlusContent?: any;
  PostData?: any;
  index?: number;
  handleUpdateIdea?: any;
  displayConfirmMsg?: boolean;
  handleSuccessCloseIcon?: any;
  isIdeaLiked?: boolean;
  upVoteCount?: number;
  upVoteCountAPI?: any;
  handleUpVote?: any;
  deleteIdea?: any;
  requestSentArray?: any;
  handleSendFriendRequest?: any;
  friendRequestSent?: any;
  feedFilterChange?: any;
  feedFilterChangeFalse?: any;
  history?: any;
  isNotify?: boolean;
  savedFeedFilter?: string;
  libraryFeedFilter?: string;
  // Customizable Area End
}

interface S {
  firstClickSolution: boolean;
  firstClickProblem: boolean;
  firstClickContext: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openPlusDialogProblem: boolean;
  openPlusDialogSolution: boolean;
  openPlusDialogContent: boolean;
  openPlusDialogFilter: boolean;
  TitleText: string;
  ContentText: string;
  ProblemText: string;
  SolutionText: string;
  SolutionTextOriginalIdea: string;
  ContextTextOriginalIdea: string;
  ProblemTextOriginalIdea: string;
  ErrorInTitle: boolean;
  ErrorInContent: boolean;
  ErrorInProblem: boolean;
  ErrorInSolution: boolean;
  ErrorInContentMedia: boolean;
  ErrorInProblemMedia: boolean;
  ErrorInSolutionMedia: boolean
  WrongLetterError: boolean;
  dummy: string;
  PrivacySelectAddNewIdea: any;
  showError: string;
  firstName: any;
  lastName: any;
  date: any;
  day: any;
  month: any;
  year: any;
  deleteModalOpen: boolean;

  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;

  contextImageUpload: any;
  contextVideoUpload: any;
  problemVideoUpload: any;
  solutionVideoUpload: any;
  problemImageUpload: any;
  solutionImageUpload: any;
  contextImagePreview: any;
  problemImagePreview: any;
  solutionImagePreview: any;
  ImagePreview: any;
  VideoUpload: any;
  isPrivacySelectEdited: boolean;
  openHyperLinkDialoge: boolean;
  hyperLink: string | null;
  hyperLinkText: string | null;
  ContentTextWithHyperLink: any;
  ProblemTextWithHyperLink: any;
  SolutionTextWithHyperLink: any;
  openSnackBarError: boolean;
  HyperLinkModalTitle: "context" | "problem" | "solution" | null
  hyperLinkError: boolean;
  isLoading: boolean;
  feedFilter: string;
  isNotify: boolean | null;
  isHide: boolean;
  isReport: boolean;
  isItrReported: boolean;
  isReportedUser: boolean;
  isHiddenUser: boolean;
  onMouseEnterContext: boolean;
  onMouseEnterProblem: boolean;
  onMouseEnterSolution: boolean;
  totalCount: number;
  currentPage: number;
  postsArray: object[];
  items: any;
  isEdited: boolean;
  isSolutonEdited: boolean;
  isProblemEdited: boolean;
  profilePicFromBackend: string;
  isDeleted: boolean;
  mouseOverfeedPrivacy: boolean;
  openPlusDialogPrivacy: boolean;
  contextImageUploadedbyUser: boolean;
  problemImageUploadedbyUser: boolean;
  solutionImageUploadedbyUser: boolean;
  contextVideoUploadedbyUser: boolean;
  problemVideoUploadedbyUser: boolean;
  solutionVideoUploadedbyUser: boolean;
  userName: string;
  userEmail: string;
  userBlocked: boolean;
  userReported: boolean;
  userIdeaVisibility: boolean;
  hashTags: string[];
  postTime: string;
  upVoteCount: number;
  downVoteCount: number;
  isUpVoted: boolean;
  isDownVoted: boolean;
  WIP: boolean;
  isIdeaUpdated: boolean | undefined;
  likedIdeas: number[];
  dislikedIdeas: number[];
  timeToCheckVoteCounts: boolean;
  isIdeaLiked: boolean;
  isShareBtnClicked: boolean;
  isSaveBtnClicked: boolean;
  isSharePost: boolean;
  saveIdeaCount: number;
  savedIdeas: number[];
  isViaSavedPost: boolean;
  shareIdeaCount: number;
  totalFileError: boolean;
  arrowPosition: number;
  URLcontext: string | null,
  URLproblem: string | null,
  URLsolution: string | null,
  imageContext: any,
  imageProblem: any,
  imageSolution: any,
  videoContext: any,
  videoProblem: any,
  videoSolution: any,
  postPrivacy: any,
  profileID: number,
  ideaID: number,
  toggleFriendText: "Add Contact" | "Remove Contact" | "Remove",
  requestSentArray: number[],
  isPopular: boolean,
  feedFilterChange: boolean,
  isViaUserProfile: boolean,
  bannerFromBackend: string,
  sharedIdeas: number[],
  WIPsave: boolean,
  addNewPostCheck: boolean
  // isViaPopularPost: boolean;
  savedFeedFilter: string;
  libraryFeedFilter: string;
  imageCropPopup: boolean;
  ideaPicImageCrop: any;
  hoverPostState: boolean;
  hoverNotify: boolean;
  hoverVisibility: boolean;
  hoverDelete: boolean;
  hoverReport: boolean;
  hoverEdit: boolean;
  DiscardChangeVal: boolean;
  showIterationList: boolean;
  ExtentionError: boolean;
  showhideItrPopup: boolean;
  showReportPopup: boolean;
  showDeletePopup: boolean;
  openPrivacyDialog: boolean;
  hoverOnIteration: boolean;
  hoveredContextText: string;
  hoveredProblemText: string;
  hoveredSolutionText: string;
  editIterationClicked?: boolean;
  parentID: any;
  openedListId: number;
  removeContactAPIResponse: any;
  searchByHashtagResponse: any;
  reportIdeaResponse: any;
  saveIdeaResponse: any;
  actiivityFeedApiResponse: any;
  allContactApiResponse: any;
  hideIdeaAPiResponse: any;
  unhideApiResponse: any;
  unBlockuserApiResponse: any;
  unhideideaApiResponse: any,
  deleteIdeaApiResponse: any;
  upVotingApiResponse: any;
  downVotingApiResponse: any;
  undoUpvotingApiResponse: any;
  undoDownVotingApiResponse: any;
  upVoteCountApiResponse: any;
  downVoteCountApiResponse: any;
  saveIdeaCountApiResponse: any;
  shareIdeaCountApiResponse: any;
  unSaveIdeaApiresponse: any;
  saveIdeaApiResponse: any;
  likeIdeaApiResponse: any;
  sharedIdeaApiResponse: any;
  disLikeIdeaApiResponse: any;
  updateideaApiResponse: any;
  editIdeaApiResponse: any;
  undoBtnContextHover: boolean;
  undoBtnProblemHover: boolean,
  undoBtnSolutionHover: boolean;
  iterationListTitle: string;
  isAlertOpen: boolean;
  alertMessageText: string;
  description: string;
  // Customizable Area End
}

interface SS {
  id: any;
  ref: any;
  // Customizable Area Start
  // Customizable Area End
}

class ActivityFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelName: string = "";
  labelFirstName: string = "";
  labelMiddleName: string = "";
  labelLastName: string = "";
  labelDateOfBirth: string = "";
  labelDay: string = "";
  labelMonth: string = "";
  labelYear: string = "";
  labelLocation: string = "";
  labelCity: string = "";
  labelCountry: string = "";
  labelBackground: string = "";
  labelBackgroundContent: string = "";

  labelArea: string = "";
  labelMobile: string = "";
  labelEmail: string = "";
  labelCurrentPassword: string = "";
  labelNewPassword: string = "";
  labelRePassword: string = "";
  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: any;
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  AddNewIdeaCallID: any;
  getAllIdeasCallID: any;
  notifyOnIdeaCallID: any;
  notifyOffIdeaCallID: any;
  hideIdeaCallID: any;
  unhideIdeaCallID: any;
  reportIdeaCallID: any;
  UnBlockUserCallID: any;
  unHideUserCallID: any;
  upVoteCountCallID: any;
  downVoteCountCallID: any;
  deleteIdeaCallID: any;
  upVotingCallID: any;
  UpdateIdeaCallID: any;
  EditIdeaCallID: any;
  userAttr: any;
  index: number = 0;
  downVotingCallID: any;
  likedIdeaCallID: any;
  dislikedIdeasCallID: any;
  saveIdeaCountCallID: any;
  saveIdeaCallID: any;
  savedIdeaCallID: any;
  shareIdeaCallID: any;
  shareIdeaCountCallID: any;
  undoDownVotingCallID: any;
  undoUpVotingCallID: any;
  unSaveIdeaCallID: any;
  sendFriendRequestCallID: any;
  friendRequestSentCallID: any;
  removeFriendRequestCallID: any;
  sharedIdeasCallID: any;
  searchByHashtagCallID: any;
  allContactsCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      parentID: null,
      ExtentionError: false,
      DiscardChangeVal: false,
      ideaPicImageCrop: "",
      isPrivacySelectEdited: false,
      firstClickSolution: false,
      firstClickProblem: false,
      firstClickContext: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      firstName: "",
      lastName: "",
      email: "",
      date: null,
      day: null,
      month: null,
      year: null,
      deleteModalOpen: false,
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      openPlusDialogProblem: false,
      openPlusDialogSolution: false,
      openPlusDialogContent: false,
      openPlusDialogFilter: false,
      openPlusDialogPrivacy: false,
      ContentText: '',
      ProblemText: '',
      SolutionText: '',
      SolutionTextOriginalIdea: "",
      ContextTextOriginalIdea: "",
      ProblemTextOriginalIdea: "",
      TitleText: '',
      dummy: 'something funny',
      PrivacySelectAddNewIdea: 'Private',
      ErrorInTitle: false,
      ErrorInContent: false,
      ErrorInProblem: false,
      ErrorInSolution: false,
      ErrorInContentMedia: false,
      ErrorInProblemMedia: false,
      ErrorInSolutionMedia: false,
      WrongLetterError: false,
      showError: '',
      contextImageUpload: null,
      problemImageUpload: null,
      solutionImageUpload: null,
      contextImagePreview: null,
      problemImagePreview: null,
      solutionImagePreview: null,
      contextVideoUpload: null,
      problemVideoUpload: null,
      solutionVideoUpload: null,
      ImagePreview: null,
      VideoUpload: null,
      openHyperLinkDialoge: false,
      hyperLink: null,
      hyperLinkText: null,
      ContentTextWithHyperLink: "",
      ProblemTextWithHyperLink: "",
      SolutionTextWithHyperLink: "",
      openSnackBarError: false,
      HyperLinkModalTitle: null,
      hyperLinkError: false,
      isLoading: true,
      feedFilter: 'default',
      isNotify: null,
      isHide: false,
      isReport: false,
      isItrReported: false,
      isReportedUser: false,
      isHiddenUser: false,
      onMouseEnterContext: true,
      onMouseEnterProblem: true,
      onMouseEnterSolution: true,
      totalCount: 100,
      currentPage: 1,
      postsArray: [],
      items: Array.from({ length: 20 }),
      isEdited: false,
      isSolutonEdited: false,
      isProblemEdited: false,
      profilePicFromBackend: '',
      isDeleted: false,
      mouseOverfeedPrivacy: false,
      contextImageUploadedbyUser: false,
      problemImageUploadedbyUser: false,
      solutionImageUploadedbyUser: false,
      contextVideoUploadedbyUser: false,
      problemVideoUploadedbyUser: false,
      solutionVideoUploadedbyUser: false,
      userName: '',
      userEmail: '',
      userBlocked: false,
      userReported: false,
      userIdeaVisibility: false,
      hashTags: [],
      postTime: '',
      upVoteCount: 0,
      downVoteCount: 0,
      isUpVoted: false,
      isDownVoted: false,
      WIP: false,
      isIdeaUpdated: false,
      likedIdeas: [],
      dislikedIdeas: [],
      timeToCheckVoteCounts: false,
      isIdeaLiked: false,
      isShareBtnClicked: false,
      isSaveBtnClicked: false,
      isSharePost: false,
      saveIdeaCount: 0,
      savedIdeas: [],
      isViaSavedPost: false,
      shareIdeaCount: 0,
      totalFileError: false,
      arrowPosition: 1,
      URLcontext: '',
      URLproblem: '',
      URLsolution: '',
      imageContext: '',
      imageProblem: '',
      imageSolution: '',
      videoContext: '',
      videoProblem: '',
      videoSolution: '',
      postPrivacy: '',
      profileID: 0,
      ideaID: 0,
      toggleFriendText: 'Add Contact',
      requestSentArray: [],
      isPopular: false,
      feedFilterChange: true,
      isViaUserProfile: false,
      bannerFromBackend: '',
      sharedIdeas: [],
      WIPsave: false,
      addNewPostCheck: false,
      // isViaPopularPost: false
      savedFeedFilter: 'saved',
      libraryFeedFilter: 'library',
      imageCropPopup: false,
      hoverPostState: false,
      hoverNotify: false,
      hoverVisibility: false,
      hoverDelete: false,
      hoverReport: false,
      hoverEdit: false,
      showIterationList: false,
      showhideItrPopup: false,
      showReportPopup: false,
      showDeletePopup: false,
      openPrivacyDialog: false,
      hoverOnIteration: false,
      hoveredContextText: '',
      hoveredProblemText: '',
      hoveredSolutionText: '',
      editIterationClicked: false,
      openedListId: 0,
      removeContactAPIResponse: "",
      searchByHashtagResponse: "",
      reportIdeaResponse: "",
      saveIdeaResponse: "",
      actiivityFeedApiResponse: "",
      allContactApiResponse: {},
      hideIdeaAPiResponse: {},
      unhideApiResponse: "",
      unBlockuserApiResponse: "",
      unhideideaApiResponse: "",
      deleteIdeaApiResponse: "",
      upVotingApiResponse: "",
      downVotingApiResponse: "",
      undoUpvotingApiResponse: "",
      undoDownVotingApiResponse: "",
      upVoteCountApiResponse: "",
      downVoteCountApiResponse: "",
      saveIdeaCountApiResponse: "",
      shareIdeaCountApiResponse: "",
      unSaveIdeaApiresponse: "",
      saveIdeaApiResponse: "",
      likeIdeaApiResponse: "",
      sharedIdeaApiResponse: "",
      disLikeIdeaApiResponse: "",
      updateideaApiResponse: "",
      editIdeaApiResponse: "",
      undoBtnContextHover: false,
      undoBtnProblemHover: false,
      undoBtnSolutionHover: false,
      iterationListTitle: "recent",
      isAlertOpen: false,
      alertMessageText: "",
      description: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const _value = message.getData(
        getName(MessageEnum.NewPostIdeaMessage)
      );
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getAllIdeasCallID:
            this.handleGetAllIdeasResponse(apiRequestCallId, responseJson);
            break;
          case this.friendRequestSentCallID:
            this.handleSentFriendReqResponse(apiRequestCallId, responseJson);
            break;
          case this.searchByHashtagCallID:
            this.handleSearchByHashTagResponse(apiRequestCallId, responseJson);
            break;
          case this.removeFriendRequestCallID:
            this.handleRemoveFriendResponse(apiRequestCallId, responseJson);
            break;
          case this.sendFriendRequestCallID:
            this.handleSendFriendRequestResponse(apiRequestCallId, responseJson);
            break;
          case this.shareIdeaCallID:
            this.handleShareIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.hideIdeaCallID:
            this.handleHideIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.unhideIdeaCallID:
            this.handleUnhideIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.reportIdeaCallID:
            this.handleReportIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.UnBlockUserCallID:
            this.handleUnBlockUserResponse(apiRequestCallId, responseJson);
            break;
          case this.deleteIdeaCallID:
            this.handleDeleteIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.upVotingCallID:
            this.handleUpVotingResponse(apiRequestCallId, responseJson);
            break;
          case this.downVotingCallID:
            this.handleDownVotingResponse(apiRequestCallId, responseJson);
            break;
          case this.undoUpVotingCallID:
            this.handleUndoUpVotingResponse(apiRequestCallId, responseJson);
            break;
          case this.undoDownVotingCallID:
            this.handleUndoDownVotingResponse(apiRequestCallId, responseJson);
            break;
          case this.upVoteCountCallID:
            this.handleUpVoteCountResponse(apiRequestCallId, responseJson);
            break;
          case this.downVoteCountCallID:
            this.handleDownVoteCountResponse(apiRequestCallId, responseJson);
            break;

          case this.saveIdeaCountCallID:
            this.handleSaveIdeaCountResponse(apiRequestCallId, responseJson);
            break;
          case this.shareIdeaCountCallID:
            this.handleShareIdeaCountResponse(apiRequestCallId, responseJson);
            break;
          case this.unSaveIdeaCallID:
            this.handleUnSaveIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.saveIdeaCallID:
            this.handleSaveIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.savedIdeaCallID:
            this.handleSavedIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.likedIdeaCallID:
            this.handleLikedIdeaResponse(apiRequestCallId, responseJson);
            break;
          case this.sharedIdeasCallID:
            this.handleShareIdeasResponse(apiRequestCallId, responseJson);
            break;

          case this.dislikedIdeasCallID:
            this.handleDislikedIdeasResponse(apiRequestCallId, responseJson);
            break;

          case this.allContactsCallID:
            this.handleGetAllContactsResponse(apiRequestCallId, responseJson);
            break;

          case this.UpdateIdeaCallID:
            this.handleUpdateIdeaRepsonse(apiRequestCallId, responseJson);
            break;
          case this.EditIdeaCallID:
            this.handleEditIdeaRepsonse(apiRequestCallId, responseJson);
            break;
          case this.unHideUserCallID:
            this.handleUnhideUserResponse(apiRequestCallId, responseJson);
            break;
          default:
            this.handleGetAllIdeasResponse(apiRequestCallId, responseJson);
            break;
        }
      }
    }

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getFilterBasedOnPath = () => {
    let finalFilter = '';
    let activeURL = '';
    const homeFeedFilter = localStorage.getItem("homeFeedFilter");
    const savedFeedFilter = localStorage.getItem("savedFeedFilter");
    const libraryFeedFilter = localStorage.getItem("libraryFeedFilter");

    if (window.location.pathname.includes("ActivityFeed")) {
      finalFilter = homeFeedFilter ? homeFeedFilter : this.state.feedFilter;
      activeURL = "ActivityFeed"
    } else if (window.location.pathname.includes("SavedPosts")) {
      finalFilter = savedFeedFilter ? savedFeedFilter : this.state.savedFeedFilter;
      activeURL = "SavedPosts"
    } else if (window.location.pathname.includes("IdeaLibrary")) {
      finalFilter = libraryFeedFilter ? libraryFeedFilter : this.state.libraryFeedFilter;
      activeURL = "IdeaLibrary"
    } else if (window.location.pathname.includes("UserProfile")) {
      activeURL = "UserProfile"
    } else if (window.location.pathname.includes("SharePost")) {
      activeURL = "SharePost"
    } else {
      finalFilter = 'default';
      activeURL = "ActivityFeed"
    }
    return [activeURL, finalFilter];
  }

  idIdentifierFromURL = () => {
    let arr = window.location.pathname.split("/");
    return arr[arr.length - 1]
  };

  async componentDidMount() {

    profileId = +this.idIdentifierFromURL();

    const [activeURL, finalFilter] = this.getFilterBasedOnPath();
    console.log("san did mount getFilterBasedOnPath", activeURL, finalFilter);
    this.getAllIdeas(this.state.currentPage, finalFilter, activeURL)

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  handleGetAllIdeasErrors = (responseJson: any) => {
    if (responseJson.status == 500) {
      this.setState({ actiivityFeedApiResponse: responseJson });
    } else if (responseJson.errors == "Record not found") {
      this.setState({ actiivityFeedApiResponse: responseJson });
    } else if (responseJson.errors[0].token) {
      this.setState({
        actiivityFeedApiResponse: responseJson,
        isAlertOpen: true,
        alertMessageText: "Please login again. Redirecting.."
      })
      setTimeout(() => {
        this.props.navigation.navigate('EmailAccountLogin');
      }, 2000);
    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Something went wrong. Try again."
      })
    }
    this.handleLoadingFalse();
  }


  handleGetAllIdeasSuccess = (responseJson: any) => {
    if (this.state.isSharePost) {
      this.setState({
        postsArray: [responseJson.data],
        totalCount: 1,
        isLoading: false,
      }, () => {
        this.upVoteCount(responseJson?.data[0]?.id)
      })
    } else if (this.state.isViaSavedPost) {
      if (responseJson?.data?.length > 0) {
        if (responseJson?.data[0]?.attributes.saved_ideas === null) {
          this.setState({
            actiivityFeedApiResponse: responseJson,
            postsArray: [],
            totalCount: responseJson?.meta.total_count,
            isLoading: false,
          })
        } else {
          this.setState({
            actiivityFeedApiResponse: responseJson,
            postsArray: [...this.state.postsArray, ...responseJson?.data],
            totalCount: responseJson?.meta.total_count,
            isLoading: false,
          }, () => {
            this.upVoteCount(responseJson?.data[0]?.id)
          })
        }
      }
    } else if (this.state.isViaUserProfile) {
      this.friendRequestSent()
      this.setState({
        actiivityFeedApiResponse: responseJson,
        isLoading: false,
        firstName: responseJson?.data?.attributes?.first_name,
        lastName: responseJson?.data?.attributes?.last_name,
        userName: responseJson?.data?.attributes?.user_name,
        userEmail: responseJson?.data?.attributes?.email,
        profilePicFromBackend: responseJson?.data?.attributes?.image,
        bannerFromBackend: responseJson?.data?.attributes?.banner,
        userBlocked: responseJson?.data?.attributes?.is_hidden_account,
        userReported: responseJson?.data?.attributes?.is_reported_account,
        userIdeaVisibility: responseJson?.data?.attributes?.idea_visibility,
        description: responseJson?.data?.attributes?.background,
      })

      if (responseJson?.data?.attributes?.idea_details?.data.length > 0) {
        let temp = [...this.state.postsArray, ...responseJson?.data?.attributes?.idea_details?.data]
        this.setState({
          postsArray: temp,
          totalCount: responseJson?.meta?.total_records,
        }, () => {
          this.upVoteCount(responseJson?.data?.attributes?.idea_details?.data[0]?.id)
        })
      }
    } else {
      this.setState({
        actiivityFeedApiResponse: responseJson,
        postsArray: [...this.state.postsArray, ...responseJson?.data],
        totalCount: responseJson?.meta?.total_count ? responseJson.meta.total_count : 100,
        isLoading: false,
        // timeToCheckVoteCounts:true
      }, () => {
        this.upVoteCount(responseJson?.data[0]?.id)
      })
    }
  }

  handleGetAllIdeasResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getAllIdeasCallID) {
      if (!responseJson.errors && responseJson.status !== 500 && responseJson.message !== "idea not found") {
        this.getAllContacts();
        this.handleGetAllIdeasSuccess(responseJson);
        this.handleLoadingFalse();
      } else {
        this.handleGetAllIdeasErrors(responseJson)
      }
    }
  }

  handleSentFriendReqResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.friendRequestSentCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        if (responseJson.total_requests_count > 0) {
          let temp: number[] = []
          responseJson.data.forEach((each: any) => {
            temp.push(each.attributes.account_id.toString())
          })
          this.setState({
            //@ts-ignore
            requestSentArray: [...this.state.requestSentArray, ...temp],
            removeContactAPIResponse: responseJson
          })
        }
      } else {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "Something went wrong. Try again."
        })
      }
    }
  }

  handleSearchByHashTagResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.searchByHashtagCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        if (responseJson.accounts) {
          this.setState({ searchByHashtagResponse: responseJson })
          this.props.history?.push('/AdvancedSearch', { data: responseJson.accounts })
        } else {
          this.props.history?.push('/AdvancedSearch', { respData: responseJson.data })
        }
      } else {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "Something went wrong. Try again."
        })
      }
    }
  }

  handleRemoveFriendResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.removeFriendRequestCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({ removeContactAPIResponse: responseJson })
      } else {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "Something went wrong. Try again."
        })
      }
    }
  }

  handleSendFriendRequestResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.sendFriendRequestCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({ removeContactAPIResponse: responseJson })
      } else {
        this.setState({
          removeContactAPIResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Something went wrong. Try again."
        })
      }
    }
  }

  handleShareIdeaResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.shareIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          timeToCheckVoteCounts: true,
        })
        setTimeout(() => {
          this.setState({
            timeToCheckVoteCounts: false,
          })
        }, 100)
      } else {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "Failed to update ShareCount. Please try again."
        })
      }
    }
  }

  handleHideIdeaResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.hideIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({ hideIdeaAPiResponse: responseJson, showhideItrPopup: false });
        window.location.reload();
        // this.getAllIdeas(1, 'abc')
      } else {
        this.setState({
          hideIdeaAPiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to hide. Please try again."
        });
      }
    }
  }

  handleUnhideIdeaResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.unhideIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        console.log("Idea unhided");
        this.setState({ unhideideaApiResponse: responseJson })
        window.location.reload();
      } else {
        this.setState({
          unhideideaApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to unhide. Please try again."
        })

      }
    }
  }

  handleReportIdeaResponse = (apiRequestCallId: any, responseJson: any) => {

    if (apiRequestCallId === this.reportIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        if (responseJson.message.report_status || responseJson.message == "reported successfully") {
          this.setState({ reportIdeaResponse: responseJson, isItrReported: true })
        }
        if (responseJson.message === "unreport successfully") {
          this.setState({ reportIdeaResponse: responseJson })
          window.location.reload();
        }
      } else {
        this.setState({
          reportIdeaResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to report. Please try again."
        })
      }
    }
  }

  handleUnBlockUserResponse = (apiRequestCallId: any, responseJson: any) => {

    if (apiRequestCallId === this.UnBlockUserCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        if (responseJson.message == "Account report successfully remove") {
          this.setState({ unBlockuserApiResponse: responseJson, isReportedUser: false });
          window.location.reload();
        }
      } else {
        this.setState({
          unBlockuserApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to unblock. Please try again."
        })
      }
    }
  }

  handleUnhideUserResponse = (apiRequestCallId: any, responseJson: any) => {

    if (apiRequestCallId === this.unHideUserCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        if (responseJson.message == "Hiden account  successfully remove") {
          this.setState({
            unhideApiResponse: responseJson,
            isHiddenUser: false,
            isAlertOpen: true,
            alertMessageText: "User Ideas has been unhided successfully."
          });
          window.location.reload();
        }
      } else {
        this.setState({
          unhideApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to unhide.  Please try again."
        })
      }
    }
  }

  handleDeleteIdeaResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({ deleteIdeaApiResponse: responseJson, isLoading: true });
        window.location.reload();
      } else {
        this.setState({
          deleteIdeaApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Delete.  Please try again."
        })

      }
    }
  }

  handleUpVotingResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.upVotingCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          upVotingApiResponse: responseJson,
          isUpVoted: true,
          WIP: false,
          timeToCheckVoteCounts: true,
        })

        setTimeout(() => {
          this.setState({
            timeToCheckVoteCounts: false,
          })
        }, 100)
      } else {
        this.setState({
          upVotingApiResponse: responseJson,
          isUpVoted: true,
          WIP: false
        })
      }
    }

  }
  handleDownVotingResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.downVotingCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          downVotingApiResponse: responseJson,
          isDownVoted: true,
          WIP: false,
          timeToCheckVoteCounts: true,
        })

        setTimeout(() => {
          this.setState({
            timeToCheckVoteCounts: false,
          })
        }, 100)
      } else {
        this.setState({
          downVotingApiResponse: responseJson,
          isDownVoted: true,
          WIP: false,
          isAlertOpen: true,
          alertMessageText: "Failed to Update Downvote Counts. Please try again."
        })
      }
    }
  }
  handleUndoUpVotingResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.undoUpVotingCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          undoUpvotingApiResponse: responseJson,
          WIP: false,
          timeToCheckVoteCounts: true, //uncomment this when upvote destroy api is working
        })

        setTimeout(() => {
          this.setState({
            timeToCheckVoteCounts: false, //uncomment this when upvote destroy api is working
          })
        }, 100)
      } else {
        this.setState({
          undoUpvotingApiResponse: responseJson,
          WIP: false,
          isAlertOpen: true,
          alertMessageText: "Failed to Remove Upvote.  Please try again."
        })
      }
    }
  }
  handleUndoDownVotingResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.undoDownVotingCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          undoDownVotingApiResponse: responseJson,
          WIP: false,
          timeToCheckVoteCounts: true, //uncomment this when downvote destroy api is working
        })

        setTimeout(() => {
          this.setState({
            timeToCheckVoteCounts: false, //uncomment this when downvote destroy api is working
          })
        }, 100)
      } else {
        this.setState({
          undoDownVotingApiResponse: responseJson,
          WIP: false,
          isAlertOpen: true,
          alertMessageText: "Failed to Remove downvote.  Please try again."
        })
      }
    }
  }

  handleUpVoteCountResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.upVoteCountCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          upVoteCountApiResponse: responseJson,
          upVoteCount: responseJson?.total_upvotes,
        })
      } else {
        this.setState({
          upVoteCountApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Update Upvote Counts.  Please try again."
        })

      }
    }
  }

  handleDownVoteCountResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.downVoteCountCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          downVoteCountApiResponse: responseJson,
          downVoteCount: responseJson?.total_downvotes,
        })
      } else {
        this.setState({
          downVoteCountApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Update downvote Counts.  Please try again."
        })
      }
    }
  }

  handleSaveIdeaCountResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.saveIdeaCountCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          saveIdeaCountApiResponse: responseJson,
          saveIdeaCount: responseJson?.favourite_count,
        })
      } else {
        this.setState({
          saveIdeaCountApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Update Save Idea Counts.  Please try again."
        })
      }
    }
  }

  handleShareIdeaCountResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.shareIdeaCountCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          shareIdeaCountApiResponse: responseJson,
          shareIdeaCount: responseJson?.idea_share_count,
        })
      } else {
        this.setState({
          shareIdeaCountApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Update Share Counts.  Please try again."
        })
      }
    }
  }

  handleUnSaveIdeaResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.unSaveIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          unSaveIdeaApiresponse: responseJson,
          timeToCheckVoteCounts: true, //uncomment this when save destroy api is working
          WIPsave: false
        })

        setTimeout(() => {
          this.setState({
            unSaveIdeaApiresponse: responseJson,
            timeToCheckVoteCounts: false, // uncomment this when save destroy api is working
          })
        }, 400)
      } else {
        this.setState({
          unSaveIdeaApiresponse: responseJson,
          WIPsave: false,
          isAlertOpen: true,
          alertMessageText: "Failed to unsave idea. Please try again."
        })
      }
    }
  }

  handleSaveIdeaResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.saveIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          saveIdeaApiResponse: responseJson,
          timeToCheckVoteCounts: true,
          WIPsave: false
        })

        setTimeout(() => {
          this.setState({
            timeToCheckVoteCounts: false,
          })
        }, 100)
      } else {
        this.setState({
          saveIdeaApiResponse: responseJson,
          WIPsave: false,
          isAlertOpen: true,
          alertMessageText: "Failed to save idea. Please try again."
        })
      }
    }

  }

  handleSavedIdeaResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.savedIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        let temp: number[] = []
        if (responseJson?.data) {
          this.setState({ saveIdeaResponse: responseJson })
          responseJson?.data.map((each: any) => {
            temp.push(each.attributes.favouriteable_id)
          })
        }
        this.setState({ savedIdeas: temp })
      } else {
        this.setState({
          saveIdeaResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Update saved idea. Please try again."
        })
      }
    }
  }

  handleLikedIdeaResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.likedIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          likeIdeaApiResponse: responseJson,
          likedIdeas: [...responseJson?.all_idea_idea[0]]
        })
      } else {
        this.setState({
          likeIdeaApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Update liked idea. Please try again."
        })
      }
    }
  }


  handleShareIdeasResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.sharedIdeasCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          sharedIdeaApiResponse: responseJson,
          sharedIdeas: [...responseJson?.user_share_ideas]
        })
      } else {
        this.setState({
          sharedIdeaApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Update Shared Counts. Please try again."
        })
      }
    }
  }

  handleDislikedIdeasResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.dislikedIdeasCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({
          disLikeIdeaApiResponse: responseJson,
          dislikedIdeas: [...responseJson?.all_downvote_idea[0]]
        })
      } else {
        this.setState({
          disLikeIdeaApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to Update dislike idea. Please try again."
        })
      }
    }
  }

  handleGetAllContactsResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.allContactsCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        this.setState({ allContactApiResponse: responseJson })
        responseJson?.data?.length > 0 && responseJson.data.map((each: any) => {
          this.setState({
            //@ts-ignore
            requestSentArray: [...this.state.requestSentArray, each.attributes.sender_id + '', each.attributes.account_id + '']
          })
        })
      } else {
        this.setState({
          allContactApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to get all contacts. Please try again."
        })
      }
    }
  }

  handleUpdateIdeaRepsonse = (apiRequestCallId: any, responseJson: { errors: any; status: number; data: object; }) => {
    if (apiRequestCallId === this.UpdateIdeaCallID) {
      if (!responseJson.errors && responseJson.status !== 500) {
        if (this.state.isViaSavedPost || this.state.isSharePost) {
          this.setState({
            updateideaApiResponse: responseJson,
            isLoading: false,
            isIdeaUpdated: true,
          })
        } else {
          if (this.index !== -1) {
            let temp = [...this.state.postsArray]
            temp[this.index] = responseJson?.data
            this.setState({
              postsArray: temp,
              isLoading: false,
              isIdeaUpdated: true,
            })
          }
        }
        setTimeout(() => {
          this.setState({
            isIdeaUpdated: false
          })
          window.location.reload();
        }, [1000])

      } else {
        this.setState({
          updateideaApiResponse: responseJson,
          isLoading: false,
          isIdeaUpdated: false,
          isAlertOpen: true,
          alertMessageText: "Failed to Update the idea. Please try again."
        })
      }
    }
  }

  handleEditIdeaRepsonse = (apiRequestCallId: any, responseJson: { error: any; status: number; data: object; }) => {

    if (apiRequestCallId === this.EditIdeaCallID) {
      if (!responseJson.error && responseJson.status !== 500) {
        if (this.state.isViaSavedPost || this.state.isSharePost) {
          this.setState({
            editIdeaApiResponse: responseJson,
            isLoading: false,
            isIdeaUpdated: true,
            editIterationClicked: false,
          })
        } else {
          if (this.index !== -1) {
            let temp = [...this.state.postsArray]
            temp[this.index] = responseJson?.data
            this.setState({
              postsArray: temp,
              isLoading: false,
              isIdeaUpdated: true,
              editIterationClicked: false
            })
          }
        }
        setTimeout(() => {
          this.setState({
            isIdeaUpdated: false
          })
          window.location.reload();
        }, [1000])

      } else {
        this.setState({
          isLoading: true,
          isIdeaUpdated: false,
          editIdeaApiResponse: responseJson,
          isAlertOpen: true,
          alertMessageText: "Failed to edit idea. Please try again."
        })
      }
    }
  }



  handleLoadingFalse = () => {
    this.setState({ isLoading: false })
  }

  handlePlusProblem = (event: any) => {
    event.preventDefault();
    this.setState({ openPlusDialogProblem: true }, () => { });
  };

  handleTooltipCloseProblem = () => {
    if (this.state.openPlusDialogProblem)
      this.setState({ openPlusDialogProblem: false });
  };

  handlePlusSolution = (event: any) => {
    event.preventDefault();
    this.setState({ openPlusDialogSolution: true }, () => { });
  };

  handleTooltipCloseSolution = () => {
    this.setState({ openPlusDialogSolution: false }, () => { });
  };

  handlePlusContent = (event: any) => {
    event.preventDefault();
    this.setState({ openPlusDialogContent: true }, () => { });
  };

  handlePlusFilter = (event: any) => {
    event.preventDefault();
    if (!this.state.openPlusDialogFilter)
      this.setState({ openPlusDialogFilter: true }, () => { });
  };

  handleTooltipCloseContent = () => {
    if (this.state.openPlusDialogContent)
      this.setState({ openPlusDialogContent: false });
  };

  handleTooltipCloseFilter = () => {
    if (this.state.openPlusDialogFilter)
      this.setState({ openPlusDialogFilter: false });
  };

  handleTooltipClosePrivacy = () => {
    if (this.state.openPlusDialogPrivacy)
      this.setState({ openPlusDialogPrivacy: false });
  };

  handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ TitleText: e.target.value })
  }

  // NotAcceptableCharacters = ['{','}','[',']','|']
  NotAcceptableCharacters: [string, string, string, string, string] = ['{', '}', '[', ']', '|'];

  ErrorCheckInSolution = () => {
    let Text: errorType = this.state.SolutionText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      // console.log('workingggggg')
      this.setState({
        ErrorInSolution: true,
      }, () => this.checkErrorsInAllFeild())

    } else {
      this.setState({
        ErrorInSolution: false,
      }, () => this.checkErrorsInAllFeild())

    }
  }

  ErrorCheckInProblem = () => {
    let Text: errorType = this.state.ProblemText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      this.setState({
        ErrorInProblem: true,
      }, () => this.checkErrorsInAllFeild())

    } else {
      this.setState({
        ErrorInProblem: false,
      }, () => this.checkErrorsInAllFeild())
    }
  }
  ErrorCheckInContent = () => {
    let Text: errorType = this.state.ContentText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      this.setState({
        ErrorInContent: true
      }, () => this.checkErrorsInAllFeild())
    } else {
      this.setState({
        ErrorInContent: false,
      }, () => this.checkErrorsInAllFeild())
    }
  }
  checkErrorsInAllFeild = () => {
    if (this.state.ErrorInContent || this.state.ErrorInProblem || this.state.ErrorInSolution) {
      this.setState({ openSnackBarError: true })
    } else {
      this.setState({ openSnackBarError: false })
    }
  }

  contextImageUpload = (e: any, title: string) => {

    //Index = 0 for Image, 1 for Video
    if (title === "context") {

      let vid = e.target.files[0];
      if (vid.name.match(/\.(mp4)$/)) {
        this.setState({ contextVideoUpload: e.target.files[0] });
      } else {
        this.setState({
          ExtentionError: true,
          ErrorInContentMedia: true,
          openPlusDialogContent: false,
        }, () => {
          setTimeout(() => {
            this.setState({
              ErrorInContentMedia: false,
              ExtentionError: false,
            })
          }, 3000)
        })
      }

    } else if (title === "problem") {

      let vid = e.target.files[0];
      if (vid.name.match(/\.(mp4)$/)) {
        this.setState({ problemVideoUpload: e.target.files[0] });
      } else {
        this.setState({
          ExtentionError: true,
          ErrorInProblemMedia: true,
          openPlusDialogProblem: false,
        }, () => {
          setTimeout(() => {
            this.setState({
              ErrorInProblemMedia: false,
              ExtentionError: false,
            })
          }, 3000)
        })
      }
    } else if (title === "solution") {

      let vid = e.target.files[0];
      if (vid.name.match(/\.(mp4)$/)) {
        this.setState({ solutionVideoUpload: e.target.files[0] })
      } else {
        this.setState({
          ExtentionError: true,
          ErrorInSolutionMedia: true,
          openPlusDialogSolution: false,
        }, () => {
          setTimeout(() => {
            this.setState({
              ErrorInSolutionMedia: false,
              ExtentionError: false,
            })
          }, 3000)
        })
      }

    }
  }


  removeImage = (title: 'context' | "problem" | "solution" | undefined) => {
    if (title === 'context') this.setState({
      contextImagePreview: null,
      contextImageUpload: null,
      contextVideoUpload: null,
      ContentTextWithHyperLink: null,
      contextImageUploadedbyUser: false,
      contextVideoUploadedbyUser: false
    })
    else if (title === 'problem') this.setState({
      problemImagePreview: null,
      problemImageUpload: null,
      problemVideoUpload: null,
      ProblemTextWithHyperLink: null,
      problemImageUploadedbyUser: false,
      problemVideoUploadedbyUser: false
    })
    else if (title === 'solution') this.setState({
      solutionImagePreview: null,
      solutionImageUpload: null,
      solutionVideoUpload: null,
      SolutionTextWithHyperLink: null,
      solutionImageUploadedbyUser: false,
      solutionVideoUploadedbyUser: false
    })
  }

  handleClickOpen = (title: "context" | "problem" | "solution") => {
    this.setState({
      HyperLinkModalTitle: title,
      openHyperLinkDialoge: true,
      openPlusDialogSolution: false,
      openPlusDialogContent: false,
      openPlusDialogProblem: false,

    }, () => { })
  };
  handleClose = () => {
    this.setState({
      openHyperLinkDialoge: false
    })
  };

  submitHyperLink = (hyperLink: string | null, hyperLinkText: string | null, title: string) => {

    this.setState({
      hyperLinkError: false,
    })

    if (hyperLinkText?.indexOf("youtu") === -1) {
      this.setState({
        hyperLinkError: true,
      })
      return
    }

    if (start = hyperLinkText!.indexOf("v="))

      if (title === "context") {
        var start: number = -1;
        var end: number = hyperLinkText!.length;

        if (start === -1) {
          start = hyperLinkText!.indexOf("v=")
          if (start !== -1) {
            start += 2
            end = hyperLinkText!.indexOf("&t=")
          }
        }

        if (start === -1) {
          start = hyperLinkText!.indexOf("embeded/")
          if (start !== -1) {
            start += 8
            end = hyperLinkText!.indexOf("&t=")
          }
        }
        if (start === -1) {
          start = hyperLinkText!.indexOf("youtu.be/")
          if (start !== -1) {
            start += 9
            end = hyperLinkText!.indexOf("&t=")
          }
        }


        //Below logic checks if "&t=" exists or not
        if (end !== -1) {
          this.setState({
            ContentTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start, end) as string
          })
        } else {
          this.setState({
            ContentTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start) as string
          })
        }
      } else if (title === "problem") {
        var start: number = -1;
        var end: number = hyperLinkText!.length;

        if (start === -1) {
          start = hyperLinkText!.indexOf("v=")
          if (start !== -1) {
            start += 2
            end = hyperLinkText!.indexOf("&t=")
          }
        }

        if (start === -1) {
          start = hyperLinkText!.indexOf("embeded/")
          if (start !== -1) {
            start += 8
            end = hyperLinkText!.indexOf("&t=")
          }
        }
        if (start === -1) {
          start = hyperLinkText!.indexOf("youtu.be/")
          if (start !== -1) {
            start += 9
            end = hyperLinkText!.indexOf("&t=")
          }
        }


        //Below logic checks if "&t=" exists or not
        if (end !== -1) {
          this.setState({
            ProblemTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start, end) as string
          }, () => {
            // console.log("this.state,ProblemTextWithHyperLink", this.state.ProblemTextWithHyperLink)
          })
        } else {
          this.setState({
            ProblemTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start) as string
          }, () => {
            // console.log("this.state,ProblemTextWithHyperLink", this.state.ProblemTextWithHyperLink)
          })
        }
      } else if (title === "solution") {
        var start: number = -1;
        var end: number = hyperLinkText!.length;

        if (start === -1) {
          start = hyperLinkText!.indexOf("v=")
          if (start !== -1) {
            start += 2
            end = hyperLinkText!.indexOf("&t=")
          }
        }

        if (start === -1) {
          start = hyperLinkText!.indexOf("embeded/")
          if (start !== -1) {
            start += 8
            end = hyperLinkText!.indexOf("&t=")
          }
        }
        if (start === -1) {
          start = hyperLinkText!.indexOf("youtu.be/")
          if (start !== -1) {
            start += 9
            end = hyperLinkText!.indexOf("&t=")
          }
        }


        //Below logic checks if "&t=" exists or not
        if (end !== -1) {
          this.setState({
            SolutionTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start, end) as string
          })
        } else {
          this.setState({
            SolutionTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start) as string
          })
        }
      }

    //closes the dialog box
    this.setState({
      openHyperLinkDialoge: false,
    })
  }

  crossButtonHandle = () => {
    if (confirm("Are you sure you want to clear all the content?") === true) {
      this.setState({
        TitleText: '',
        SolutionText: '',
        ProblemText: '',
        ContentText: '',
        solutionImagePreview: null,
        problemImagePreview: null,
        problemImageUpload: null,
        problemVideoUpload: null,
        solutionImageUpload: null,
        solutionVideoUpload: null,
        SolutionTextWithHyperLink: null,
        contextImagePreview: null,
        contextImageUpload: null,
        contextVideoUpload: null,
        ContentTextWithHyperLink: null,
        ProblemTextWithHyperLink: null,
        openHyperLinkDialoge: false,
        openPlusDialogContent: false,
        openPlusDialogProblem: false,
        openPlusDialogSolution: false,
        openPlusDialogFilter: false,

      })
    }

  }

  onHyperlinkErrorClose = () => {
    this.setState({
      hyperLinkError: false
    })
  }

  getAllIdeasEndPoint = (str: string) => {
    if (str == "popular") {
      return configJSON.popularIdeasEndPoint
    } else if (str == "promoted") {
      return configJSON.promotedIdeasEndPoint
    } else if (str == "contested") {
      return configJSON.contestedIdeasEndPoint
    } else if (str == "recent") {
      return configJSON.recentEndPoint
    } else if (str == "default") {
      return configJSON.defaultEndPoint
    } else {
      return configJSON.GetAllIdeasPostEndPoint
    }
  }

  getSavedIdeasEndPoint = (str: string) => {
    if (str == "popular") {
      return configJSON.savedPopularIdeaEndPoint
    } else if (str == "promoted") {
      return configJSON.savedPromotedIdeaEndPoint
    } else if (str == "contested") {
      return configJSON.savedContestedIdeaEndPoint
    } else if (str == "recent") {
      return configJSON.savedRecentIdeaEndPoint
    } else if (str == "default") {
      return configJSON.savedDefaultIdeaEndPoint
    }
  }

  getLibraryIdeasEndPoint = (str: string) => {
    if (str == "promoted") {
      return configJSON.libraryPromotedIdeaEndPoint
    } else if (str == "popular") {
      return configJSON.libraryPopularIdeaEndPoint
    } else if (str == "contested") {
      return configJSON.libraryContestedIdeaEndPoint
    } else if (str == "library") {
      return configJSON.libraryEndPoint
    }
  }

  getAllIdeas = (page: number, filterType: string, urlPath: string) => {

    this.setState({ isLoading: true });
    let getAllIdeasEndPointValue = this.getAllIdeasEndPoint(filterType);

    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllIdeasCallID = requestMessage.messageId;

    if (urlPath === "SharePost") {
      this.setState({ isSharePost: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sharePostEndPoint + this.idIdentifierFromURL()
      );
    }
    else if (urlPath === "SavedPosts" || filterType == "saved") {
      console.log('san saved posts', filterType);
      this.setState({ isLoading: true });
      if (filterType == "saved") {
        this.setState({ isViaSavedPost: true })

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.savedIdeaEndPoint + `?page=${page}&per_page=5`
        );
      } else {
        this.setState({ isViaSavedPost: false });

        let getSavedIdeasEndPointValue = this.getSavedIdeasEndPoint(filterType);
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          getSavedIdeasEndPointValue + `?page=${page}&per_page=5`
        );
      }
    }
    else if (urlPath === "IdeaLibrary" || filterType == "library") {

      this.setState({ isLoading: true });

      let getLibraryIdeasEndPointValue = this.getLibraryIdeasEndPoint(filterType);
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getLibraryIdeasEndPointValue + `?page=${page}&per_page=5`
      );

    }
    else if (urlPath === "UserProfile") {
      this.setState({
        isLoading: true,
        isViaUserProfile: true
      })
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getIdeaByIDEndPoint + `${this.idIdentifierFromURL()}?page=${page}&per_page=5`
      );
    }
    else {
      this.setState({ isLoading: true });
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getAllIdeasEndPointValue + `?page=${page}&per_page=5`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  notifyOnIdea = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notifyOnIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsIdeaEndPoint + `?is_notify=true&idea_id=${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  notifyOffIdea = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notifyOffIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsIdeaEndPoint + `?is_notify=false&idea_id=${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  hideIdea = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.hideIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hideIdeaEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unhideIdea = (ideaID: number) => {
    this.setState({ showhideItrPopup: false });
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unhideIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unhideIdeaEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  reportIdea = (ideaID: number, reportStatus: boolean) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };
    const endpoint = reportStatus ? configJSON.reportIdeaEndPoint : configJSON.unReportIdeaEndPoint;
    const methodType = reportStatus ? configJSON.MethodTypePOST : configJSON.MethodTypePUT;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  unBlockUser = (userId: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UnBlockUserCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportUserEndPoint + `/${userId}?status=false`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unHideUser = (userId: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unHideUserCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unHideUserEndPoint + `/${userId}?status=false`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  upVoteCount = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upVoteCountCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upVotingCountEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downVoteCount = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downVoteCountCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downVotingCountEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveIdeaCount = (ideaID: number) => {
    // console.log(" this is runninggggg")
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveIdeaCountCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveIdeaCountEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  shareIdeaCount = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shareIdeaCountCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shareIdeaCountEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteIdea = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteIdeaEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypeDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  upVoting = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const formData = new FormData();
    formData.append("data[likeable_id]", `${ideaID}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upVotingCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upVotingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleUserProfileFriendRequest = (profileID: number, AddContact: boolean) => {
    let temp = [...this.state.requestSentArray]
    if (temp.includes(profileID)) {
      const index = temp.indexOf(profileID);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else temp.push(profileID)

    this.setState({
      toggleFriendText: this.state.toggleFriendText == "Add Contact" ? 'Remove Contact' : 'Add Contact',
      requestSentArray: temp
    })
    if (!AddContact) this.removeFriendRequest(profileID)
    else this.sendFriendRequest(profileID)
  }

  handleSendFriendRequest = (profileID: number, AddContact: boolean) => {
    let temp = [...this.state.requestSentArray]
    if (temp.includes(profileID)) {
      const index = temp.indexOf(profileID);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else temp.push(profileID)

    this.setState({
      toggleFriendText: this.state.toggleFriendText == "Add Contact" ? 'Remove Contact' : 'Add Contact',
      requestSentArray: temp
    })
    if (!AddContact) this.sendFriendRequest(profileID)
    else this.removeFriendRequest(profileID)
  }

  sendFriendRequest = (profileID: number) => {

    const header = {
      "token": localStorage.getItem('authToken'),
      "Content-Type": "application/json"
    };

    const httpBody = {
      "data": {
        "account_id": profileID
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendFriendRequestCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendFriendRequestEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    // console.log(header, httpBody)
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleUpdateIdea = (IdeaID: number, index: number | undefined, formData: any) => {
    this.index = index || 0;

    this.setState({
      isLoading: true
    })

    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UpdateIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateIdeaEndPoint + IdeaID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePUT
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  handleEditIdea = (IdeaID: number, index: number | undefined, formData: any) => {
    this.index = index || 0;

    this.setState({ isLoading: true })

    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.EditIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editIdeaEndPoint + IdeaID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePUT
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  downVoting = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const formData = new FormData();
    formData.append("data[downvoteable_id]", `${ideaID}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downVotingCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downVotingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  undoDownVoting = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.undoDownVotingCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoDownVotingCountEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypeDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  undoUpVoting = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.undoUpVotingCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoUpVotingCountEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypeDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unSaveIdea = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unSaveIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unsaveIdeatEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypeDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveIdea = (ideaID: number) => {

    const header = {
      'token': localStorage.getItem('authToken')
    };

    const formData = new FormData();
    formData.append("data[favouriteable_id]", `${ideaID}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveIdeaEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  shareIdea = (ideaID: number) => {

    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shareIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shareIdeaEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  savedIdeas = () => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.savedIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.savedIdeaEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  likedIdeas = () => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likedIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likedIdeasEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  dislikedIdeas = () => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dislikedIdeasCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dislikedIdeasEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  sharedIdeas = () => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sharedIdeasCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sharedIdeasEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  handleActivityFeedFilter = (item: string) => {
    setTimeout(() => {
      this.setState({
        feedFilter: item,
        isViaSavedPost: false,
        postsArray: [],
        isLoading: true,
        currentPage: 1,
        feedFilterChange: item == ('recent' || 'default') ? false : true,
      }, () => {
        localStorage.setItem("homeFeedFilter", this.state.feedFilter);
        this.getAllIdeas(this.state.currentPage, this.state.feedFilter, 'ActivityFeed')
      })
    }, 200);
  }

  handleSavedPostsFilter = (item: any) => {
    console.log("santhu handleSavedPostsFilter", item);
    setTimeout(() => {
      this.setState({
        savedFeedFilter: item,
        postsArray: [],
        isLoading: true,
        currentPage: 1,
      }, () => {
        // console.log(this.state.feedFilter, "this.state.feedFilter")
        localStorage.setItem("savedFeedFilter", this.state.savedFeedFilter);
        this.getAllIdeas(this.state.currentPage, this.state.savedFeedFilter, "SavedPosts")
      })
    }, 200);
  }

  handleLibraryPostsFilter = (item: any) => {
    console.log("santhu handleLibraryPostsFilter", item);
    setTimeout(() => {
      this.setState({
        libraryFeedFilter: item,
        postsArray: [],
        isLoading: true,
        currentPage: 1,
      }, () => {
        localStorage.setItem("libraryFeedFilter", this.state.libraryFeedFilter);
        this.getAllIdeas(this.state.currentPage, this.state.libraryFeedFilter, 'IdeaLibrary')
      })
    }, 200);
  }


  onMouseEnter = (item: string) => {
    if (item === "context") {
      this.setState({
        onMouseEnterContext: false
      })
    } else if (item === "problem") {
      this.setState({
        onMouseEnterProblem: false
      })
    } else if (item === "solution") {
      this.setState({
        onMouseEnterSolution: false
      })
    }
  }

  onRemoveHoverEffect = (item: string, id: string) => {
    let cell = document.getElementById(id) as HTMLInputElement;
    cell?.focus()
    if (item === "context") {
      this.setState({ firstClickContext: true })
    } else if (item === "problem") {
      this.setState({ firstClickProblem: true })
    } else if (item === "solution") {
      this.setState({ firstClickSolution: true })
    }

  }

  onMouseLeave = (item: string) => {
    if (item === "context") {
      this.setState({
        onMouseEnterContext: true,
      })
    } else if (item === "problem") {
      this.setState({
        onMouseEnterProblem: true
      })
    } else if (item === "solution") {
      this.setState({
        onMouseEnterSolution: true,
      })
    }
  }

  handleFetchMoreData = () => {
    const [activeURL, finalFilter] = this.getFilterBasedOnPath();

    if (this.state.currentPage <= this.state.totalCount / 5)
      this.setState({
        currentPage: this.state.currentPage + 1,
        feedFilterChange: finalFilter == ('recent' || 'default') ? false : true,
      }, () => {
        this.getAllIdeas(this.state.currentPage, finalFilter, activeURL)
      })
  }

  feedFilterChangeFalse = () => {
    this.setState({ feedFilterChange: false })
  }

  handlePrivacyDropDown = (title: 'Private' | 'Platform' | 'Contacts') => {
    switch (title) {
      case "Private":
        return this.setState({
          PrivacySelectAddNewIdea: 'private',
          openPlusDialogPrivacy: false,
          openPrivacyDialog: false
        });
      case "Platform":
        return this.setState({
          PrivacySelectAddNewIdea: 'public',
          openPlusDialogPrivacy: false,
          openPrivacyDialog: false
        });
      case "Contacts":
        return this.setState({
          PrivacySelectAddNewIdea: 'contacts',
          openPlusDialogPrivacy: false,
          openPrivacyDialog: false
        })
    }
  }

  handleUpVote = (IdeaID: number, likedIdeas: number[], dislikedIdeas: number[]) => {
    if (!this.state.WIP) {
      if (likedIdeas.includes(Number(IdeaID))) {
        let index = likedIdeas.indexOf(Number(IdeaID));
        likedIdeas.splice(index, 1)
        this.setState(prev => ({

          isUpVoted: false,
          WIP: true,
          likedIdeas: likedIdeas
        }), () => {
          this.undoUpVoting(IdeaID)
        })
      } else {
        let temp = [...likedIdeas, Number(IdeaID)]
        this.setState(prev => ({

          isUpVoted: false,
          WIP: true,
          likedIdeas: temp
        }), () => {
          this.upVoting(IdeaID)
        })
      }
    }
  }

  handleDownVote = (IdeaID: number, likedIdeas: number[], dislikedIdeas: number[]) => {


    if (!this.state.WIP) {
      if (dislikedIdeas.includes(Number(IdeaID))) {
        let index = dislikedIdeas.indexOf(Number(IdeaID));
        dislikedIdeas.splice(index, 1)
        this.setState(prev => ({

          isDownVoted: false,
          WIP: true,
          dislikedIdeas: dislikedIdeas
        }), () => {
          this.undoDownVoting(IdeaID)
        })
      } else {
        let temp = [...dislikedIdeas, Number(IdeaID)]
        this.setState(prev => ({

          isDownVoted: false,
          WIP: true,
          dislikedIdeas: temp
        }), () => {
          this.downVoting(IdeaID)
        })
      }
    }
  }

  handleSaveClick = (IdeaID: number, savedIdeas: number[]) => {
    if (!this.state.WIPsave) {
      this.setState({
        isSaveBtnClicked: true,
        WIPsave: true
      }, () => {
        if (savedIdeas.includes(Number(IdeaID))) {
          let index = savedIdeas.indexOf(Number(IdeaID));
          savedIdeas.splice(index, 1)
          this.setState(prev => ({

            savedIdeas: savedIdeas
          }), () => {
            this.unSaveIdea(IdeaID)
          })
        } else {
          let temp = [...savedIdeas, Number(IdeaID)]
          this.setState(prev => ({

            savedIdeas: temp
          }), () => {
            this.saveIdea(IdeaID)
          })
        }

      }
      )
    }
    setTimeout(() => {
      this.setState({
        isSaveBtnClicked: false,
      })
    }, 2000)
  }

  handleSuccessCloseIcon = () => {
    this.setState({
      isIdeaUpdated: false,
    })
  }

  handleShareBtn = (IdeaID: number, sharedIdeasArray: number[]) => {
    let alreadyShared = sharedIdeasArray.includes(Number(IdeaID))
    if (!alreadyShared) {
      sharedIdeasArray.push(Number(IdeaID))
      this.setState({
        sharedIdeas: sharedIdeasArray
      })
    }
    this.setState(prev => ({
      isShareBtnClicked: true,

      WIP: true,
    }))
    !alreadyShared && this.shareIdea(IdeaID);
    let link = window.location.host + "/SharePost/" + IdeaID

    navigator.clipboard.writeText(link)

    setTimeout(() => {
      this.setState({
        isShareBtnClicked: false,
      })
    }, 2000)

    setTimeout(() => {
      this.setState({
        WIP: false,
      })
    }, 1000)

  }

  checkFileSize = () => {
    let files = [this.state.contextImageUpload?.size,
    this.state.problemImageUpload?.size,
    this.state.solutionImageUpload?.size,
    this.state.contextVideoUpload?.size,
    this.state.problemVideoUpload?.size,
    this.state.solutionVideoUpload?.size];
    let temp = 0
    files.forEach((e) => {
      if (e !== undefined)
        temp += e
    })
    temp = (temp / 1024) / 1024
    if (temp > 50) {
      this.setState({
        totalFileError: true
      })
      setTimeout(() => {
        this.setState({
          totalFileError: false
        })
      }, 3000);
      return false
    }
    if (temp == 0)
      return true
    else
      return temp
  }

  makeLoadingTrue = () => {
    this.setState({
      isLoading: true,
    })
  }

  friendRequestSent = () => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.friendRequestSentCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.friendRequestSentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSendRequest = (str: "Add Contact" | "Remove") => {

    if (str == ("Remove" || "Remove Contact")) {
      this.removeFriendRequest(Number(profileId))
    } else if (str == "Add Contact") {
      this.sendFriendRequest(Number(profileId))
    }

    this.setState({
      toggleFriendText: this.state.toggleFriendText == "Add Contact" ? 'Remove' : 'Add Contact'
    }, () => { })
  }

  removeFriendRequest = (profileID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFriendRequestCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeFriendRequestEndPoint + profileID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypeDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  searchByHashtag = (hashtag: string) => {

    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchByHashtagCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.advSearchResultEndPoint + `?tag_list[]=${hashtag}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllContacts = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allContactsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allContactsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  /*Activity feed post Logics start */

  getPostState = (stateValue: string) => {
    return stateValue === "private" ? lockIcon : worldIcon
  }

  handleNotifications = (prev: boolean) => {
    this.setState({
      isNotify: !this.state.isNotify
    }, () => {
      if (this.state.isNotify)
        this.notifyOnIdea(this.props.PostData.id)
      else
        this.notifyOffIdea(this.props.PostData.id)
    })
  };

  handleHide = (prev: boolean) => {
    this.setState({
      isHide: !prev
    }, () => {
      if (this.state.isHide)
        this.hideIdea(this.state.ideaID)
      else
        this.unhideIdea(this.state.ideaID)
    })
  };

  handleDeletePopup = () => {
    console.log('san handleDeletePopup');
    this.setState({
      isViaSavedPost: false,
      postsArray: [],
      isLoading: false,
      currentPage: 1,
      isDeleted: true,
      showDeletePopup: false
    })
    this.deleteIdea(this.state.ideaID);
  }

  handleReport = (prev: boolean) => {
    this.setState({
      showReportPopup: false,
      isReport: !prev
    }, () => {
      if (this.state.isReport) {
        this.reportIdea(this.state.ideaID, true);
      } else {
        this.setState({ isItrReported: false })
        // unreport idea function
        this.reportIdea(this.state.ideaID, false);
        if (this.state.isHide) {
          this.unhideIdea(this.state.ideaID)
        }
      }
    })
  };

  printHashTags = () => {
    let str = this.state.TitleText + " " + this.state.ContentText + " " + this.state.ProblemText + " " + this.state.SolutionText + " ";
    let hashTags: any = str.match(/#[A-Z][a-z0-9_-]+/gmi)
    this.setState({
      hashTags: hashTags
    })
  }

  profileImageCrop = (img: any, file: any, title: any) => {

    if (title === "context") {

      this.setState({ contextImageUpload: file }, () => {
        this.setState({
          contextImagePreview: img,
          contextImageUploadedbyUser: true,
        })
      })

    } else if (title === "problem") {
      this.setState({ problemImageUpload: file }, () => {

        this.setState({
          problemImagePreview: img,
          problemImageUploadedbyUser: true,
        })
      })
    } else if (title === "solution") {
      this.setState({ solutionImageUpload: file }, () => {
        this.setState({
          solutionImagePreview: img,
          solutionImageUploadedbyUser: true,
        })
      })
    }
  }

  AddCropImageDailog = () => {
    this.setState({
      imageCropPopup: false
    });
  }

  setImageData = async (event: any, title: string) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (img.name.match(/\.(jpg|jpeg|png)$/)) {
        this.setState({ ideaPicImageCrop: URL.createObjectURL(img) },
          () => { this.setState({ imageCropPopup: true }) }
        )
      } else if (img.name.match(/\.(gif)$/)) {
        switch (title) {
          case "context":
            return this.setState({ contextImageUpload: img }, () => {
              this.setState({
                contextImagePreview: URL.createObjectURL(img),
              })
            })
          case "problem":
            return this.setState({ problemImageUpload: img }, () => {
              this.setState({
                problemImagePreview: URL.createObjectURL(img),
              })
            });
          case "solution":
            return this.setState({ solutionImageUpload: img }, () => {
              this.setState({
                solutionImagePreview: URL.createObjectURL(img),
              })
            })
          default:
            return;
        }
      } else {
        this.setState({
          ideaPicImageCrop: null,
          ExtentionError: true,
          // ErrorInContent:true
        })
        switch (title) {
          case "context":
            return this.setState({
              ErrorInContentMedia: true,
              openPlusDialogContent: false
            }, () => {
              setTimeout(() => {
                this.setState({
                  ErrorInContentMedia: false,
                  ExtentionError: false
                })
              }, 3000)
            })
          case "problem":
            return this.setState({ ErrorInProblemMedia: true, openPlusDialogProblem: false, }, () => {
              setTimeout(() => {
                this.setState({
                  ErrorInProblemMedia: false,
                  ExtentionError: false
                })
              }, 3000)
            })
          case "solution":
            return this.setState({ ErrorInSolutionMedia: true, openPlusDialogSolution: false, }, () => {
              setTimeout(() => {
                this.setState({
                  ErrorInSolutionMedia: false,
                  ExtentionError: false
                })
              }, 3000)
            })
          default:
            return;
        }
      }
    }
  }

  isCheckBetween(state: any) {
    return state == ('' || null || undefined)
  }

  isCheckImageBetween(image: any) {
    return typeof (image) == "object" && image !== null;
  }

  isCheckStringNull(state: any) {
    return state == ("" || null)
  }



  isCheckSubmitBtnShow() {
    return this.state.isEdited &&
      !this.state.ErrorInContent &&
      !this.state.ErrorInProblem &&
      !this.state.ErrorInSolution || this.state.isProblemEdited || this.state.isSolutonEdited ||
      ((this.state.contextImagePreview !== this.state.imageContext) ||
        (this.state.solutionImagePreview !== this.state.imageSolution) ||
        (this.state.problemImagePreview !== this.state.imageProblem) ||
        (this.state.contextVideoUpload !== this.state.videoContext) ||
        (this.state.problemVideoUpload !== this.state.videoProblem) ||
        (this.state.solutionVideoUpload !== this.state.videoSolution) ||
        (this.state.ContentTextWithHyperLink !== this.state.URLcontext) ||
        (this.state.ProblemTextWithHyperLink !== this.state.URLproblem) ||
        (this.state.SolutionTextWithHyperLink !== this.state.URLsolution) ||
        (this.state.PrivacySelectAddNewIdea !== this.state.postPrivacy))
  }

  isCheckOrAndIfRender(value1: any, value2: any, value3: any, value4: any, value5: any, value6: any, value7: any) {
    return value1 ||
      (value2 !== value3) ||
      (value4 !== value5) ||
      (value6 !== value7) ? { backgroundColor: '#0D2738' } : undefined
  }

  conditionalRenderFun(stateValue: any, children: any, children1: any) {
    return stateValue ? children : children1;
  }

  isCheckStateValue(value1: any, value2: any, value3: any, value4: any, value5: any, value6: any) {
    return (value1 || value2 || value3) === null ||
      (value4 || value5 || value6) === "";
  }

  addingUpdateIdeaAttributes(ideaType: string, hashtagString: string, formData: FormData) {

    formData.append("idea_type", ideaType);
    formData.append("tag_list", hashtagString);

    formData.append("title", this.state.TitleText);
    formData.append("idea_context_attributes[description]", this.state.ContentText);
    formData.append("idea_problem_attributes[description]", this.state.ProblemText);
    formData.append("idea_solution_attributes[description]", this.state.SolutionText);

    if (this.isCheckBetween(this.state.contextImagePreview)) {
      formData.append("idea_context_attributes[image_remove]", 'true');
    }
    if (this.isCheckBetween(this.state.solutionImagePreview)) {
      formData.append("idea_solution_attributes[image_remove]", 'true');
    }
    if (this.isCheckBetween(this.state.problemImagePreview)) {
      formData.append("idea_problem_attributes[image_remove]", 'true');
    }

    if (this.isCheckBetween(this.state.contextVideoUpload)) {
      formData.append("idea_context_attributes[video_remove]", 'true');
    }
    if (this.isCheckBetween(this.state.problemVideoUpload)) {
      formData.append("idea_problem_attributes[video_remove]", 'true');
    }
    if (this.isCheckBetween(this.state.solutionVideoUpload)) {
      formData.append("idea_solution_attributes[video_remove]", 'true');
    }
    //check for images
    if (this.state.contextImageUpload) {
      formData.append("idea_context_attributes[image]", this.state.contextImageUpload);
    }

    if (this.state.problemImageUpload) {
      formData.append("idea_problem_attributes[image]", this.state.problemImageUpload);
    }

    if (this.state.solutionImageUpload)
      formData.append("idea_solution_attributes[image]", this.state.solutionImageUpload);

    if (this.isCheckImageBetween(this.state.contextVideoUpload))
      formData.append("idea_context_attributes[video]", this.state.contextVideoUpload);

    if (this.isCheckImageBetween(this.state.problemVideoUpload))
      formData.append("idea_problem_attributes[video]", this.state.problemVideoUpload);

    if (this.isCheckImageBetween(this.state.solutionVideoUpload))
      formData.append("idea_solution_attributes[video]", this.state.solutionVideoUpload);

    //check for HyperLinks
    if (this.state.ContentTextWithHyperLink)
      formData.append("idea_context_attributes[url]", this.state.ContentTextWithHyperLink);

    if (this.state.ProblemTextWithHyperLink)
      formData.append("idea_problem_attributes[url]", this.state.ProblemTextWithHyperLink);

    if (this.state.SolutionTextWithHyperLink)
      formData.append("idea_solution_attributes[url]", this.state.SolutionTextWithHyperLink);
  }

  addingEditIdeaAttributes(ideaType: string, hashtagString: string, formData: FormData) {

    formData.append("[add_idea][idea_type]", ideaType);
    formData.append("[add_idea][tag]", hashtagString);

    formData.append("[add_idea][title]", this.state.TitleText);
    formData.append("[add_idea][idea_context][description]", this.state.ContentText);
    formData.append("[add_idea][idea_problem][description]", this.state.ProblemText);
    formData.append("[add_idea][idea_solution][description]", this.state.SolutionText);

    if (this.isCheckBetween(this.state.contextImagePreview)) {
      formData.append("[add_idea][idea_context][image_remove]", 'true');
    }
    if (this.isCheckBetween(this.state.problemImagePreview)) {
      formData.append("[add_idea][idea_problem][image_remove]", 'true');
    }
    if (this.isCheckBetween(this.state.solutionImagePreview)) {
      formData.append("[add_idea][idea_solution][image_remove]", 'true');
    }

    if (this.isCheckBetween(this.state.contextVideoUpload)) {
      formData.append("[add_idea][idea_context][video_remove]", 'true');
    }
    if (this.isCheckBetween(this.state.problemVideoUpload)) {
      formData.append("[add_idea][idea_problem][video_remove]", 'true');
    }
    if (this.isCheckBetween(this.state.solutionVideoUpload)) {
      formData.append("[add_idea][idea_solution][video_remove]", 'true');
    }

    if (this.state.contextImageUpload) {
      formData.append("[add_idea][idea_context][image]", this.state.contextImageUpload);
    }
    if (this.state.problemImageUpload) {
      formData.append("[add_idea][idea_problem][image]", this.state.problemImageUpload);
    }
    if (this.state.solutionImageUpload) {
      formData.append("[add_idea][idea_solution][image]", this.state.solutionImageUpload);
    }

    if (this.isCheckImageBetween(this.state.contextVideoUpload))
      formData.append("[add_idea][idea_context][video]", this.state.contextVideoUpload);

    if (this.isCheckImageBetween(this.state.problemVideoUpload))
      formData.append("[add_idea][idea_problem][video]", this.state.problemVideoUpload);

    if (this.isCheckImageBetween(this.state.solutionVideoUpload))
      formData.append("[add_idea][idea_solution][video]", this.state.solutionVideoUpload);

    if (this.state.ContentTextWithHyperLink)
      formData.append("[add_idea][idea_context][url]", this.state.ContentTextWithHyperLink);

    if (this.state.ProblemTextWithHyperLink)
      formData.append("[add_idea][idea_problem][url]", this.state.ProblemTextWithHyperLink);

    if (this.state.SolutionTextWithHyperLink)
      formData.append("[add_idea][idea_solution][url]", this.state.SolutionTextWithHyperLink);
  }

  VoteCountCheck = () => {
    if (this.state.timeToCheckVoteCounts) {
      this.likedIdeas();
      this.sharedIdeas();
      this.dislikedIdeas();
      this.savedIdeas();
      this.upVoteCount(this.state.ideaID)
      this.downVoteCount(this.state.ideaID)
      this.saveIdeaCount(this.state.ideaID)
      this.shareIdeaCount(this.state.ideaID)
      // this.AssigningState()
    }
  }


  problemClickFun() {
    return this.state.onMouseEnterProblem || this.state.isEdited || this.state.firstClickProblem
      ||
      ((this.state.contextImagePreview !== this.state.imageContext) ||
        (this.state.solutionImagePreview !== this.state.imageSolution) ||
        (this.state.problemImagePreview !== this.state.imageProblem) ||
        (this.state.contextVideoUpload !== this.state.videoContext) ||
        (this.state.problemVideoUpload !== this.state.videoProblem) ||
        (this.state.solutionVideoUpload !== this.state.videoSolution) ||
        (this.state.ContentTextWithHyperLink !== this.state.URLcontext) ||
        (this.state.ProblemTextWithHyperLink !== this.state.URLproblem) ||
        (this.state.SolutionTextWithHyperLink !== this.state.URLsolution) ||
        (this.state.PrivacySelectAddNewIdea !== this.state.postPrivacy))
      ? { display: 'none' } : { display: 'block' }
  }

  solutionStyleFun() {
    return this.state.onMouseEnterSolution || this.state.isEdited || this.state.firstClickSolution
      ||
      ((this.state.contextImagePreview !== this.state.imageContext) ||
        (this.state.solutionImagePreview !== this.state.imageSolution) ||
        (this.state.problemImagePreview !== this.state.imageProblem) ||
        (this.state.contextVideoUpload !== this.state.videoContext) ||
        (this.state.problemVideoUpload !== this.state.videoProblem) ||
        (this.state.solutionVideoUpload !== this.state.videoSolution) ||
        (this.state.ContentTextWithHyperLink !== this.state.URLcontext) ||
        (this.state.ProblemTextWithHyperLink !== this.state.URLproblem) ||
        (this.state.SolutionTextWithHyperLink !== this.state.URLsolution) ||
        (this.state.PrivacySelectAddNewIdea !== this.state.postPrivacy))
      ? { display: 'none' } : { display: 'block' }
  }
  contextStyleFun() {
    return this.state.onMouseEnterContext || this.state.isEdited
      || this.state.firstClickContext ||
      ((this.state.contextImagePreview !== this.state.imageContext) ||
        (this.state.solutionImagePreview !== this.state.imageSolution) ||
        (this.state.problemImagePreview !== this.state.imageProblem) ||
        (this.state.contextVideoUpload !== this.state.videoContext) ||
        (this.state.problemVideoUpload !== this.state.videoProblem) ||
        (this.state.solutionVideoUpload !== this.state.videoSolution) ||
        (this.state.ContentTextWithHyperLink !== this.state.URLcontext) ||
        (this.state.ProblemTextWithHyperLink !== this.state.URLproblem) ||
        (this.state.SolutionTextWithHyperLink !== this.state.URLsolution) ||
        (this.state.PrivacySelectAddNewIdea !== this.state.postPrivacy))
      ? { display: 'none' } : { display: 'block' }
  }


  hashTagFun(index: any) {
    return index > 0 ? ' | ' : null
  }



  handleCloseSubmitPopup() {
    this.setState({ isIdeaUpdated: false });
    window.location.reload();
  }

  contextReset() {
    this.removeImage("context");
    this.setState({ openPlusDialogContent: false });
  }

  problemReset() {
    this.removeImage("problem");
    this.setState({ openPlusDialogProblem: false });
  }

  solutionReset() {
    this.removeImage("solution");
    this.setState({ openPlusDialogSolution: false });
  }

  checkOrgIdea() {
    return (this.state.ideaID == this.props.PostData?.attributes?.parent_id && this.state.openedListId === this.props.PostData?.attributes?.parent_id) ? "feedOrgIdeaBox" : '';
  }

  handleUndoChange = (title: string) => {

    switch (title) {
      case "context":
        return this.setState({ ContentText: this.state.ContextTextOriginalIdea })
      case "problem":
        return this.setState({ ProblemText: this.state.ProblemTextOriginalIdea })
      case "solution":
        return this.setState({ SolutionText: this.state.SolutionTextOriginalIdea })
    }

  }
  handlePrivacyOpen(email: string, ideaLength: number) {
    if (email === localStorage.getItem("email") && ideaLength === 0) {
      this.setState({ openPrivacyDialog: true });
    }
  }

  // ActivityFeedPost Function refactor for textCoverage
  handleMouseLeaveOverfeedPrivacy = () => this.setState({ mouseOverfeedPrivacy: false })
  handleMouseEnterOverfeedPrivacy = () => this.setState({ mouseOverfeedPrivacy: true })
  handleClickFeedPrivacy = () => { this.setState({ openPlusDialogPrivacy: true }) };
  handleSearchByHashTag = (each: any) => this.searchByHashtag(each.slice(1));
  handleMouseEnterContext = () => {
    this.onMouseEnter("context");
  }
  handleMouseLeaveContext = () => {
    this.onMouseLeave("context")
  }
  handleRemoveHoverEffectContext = () => {
    this.onRemoveHoverEffect(
      "context",
      `context-${this.props.index}`
    );
  }
  handleMouseEnterProblem = () => {
    this.onMouseEnter("problem");
  }
  handleMouseLeaveProblem = () => {
    this.onMouseLeave("problem")
  }
  handleRemoveHoverEffectProblem = () => {
    this.onRemoveHoverEffect(
      "problem",
      `problem-${this.props.index}`
    );
  }
  handleMouseEnterSolution = () => {
    this.onMouseEnter("solution");
  }
  handleMouseLeaveSolution = () => {
    this.onMouseLeave("solution")
  }
  handleRemoveHoverEffectSolution = () => {
    this.onRemoveHoverEffect(
      "solution",
      `solution-${this.props.index}`
    );
  }

  handleCancelDeletePopup = () => this.setState({ showDeletePopup: false });
  handleHideItration = () => { this.handleHide(this.state.isHide) }
  handleCancelReportPopup = () => this.setState({ showReportPopup: false });
  handleSubmitReportPopup = () => this.handleReport(this.state.isReport);
  handleCancelHideItrPopup = () => this.setState({ showhideItrPopup: false });
  handleSubmitHideItrPopup = () => this.handleHide(this.state.isHide);
  handleDiscardCancelPopup = () => this.setState({ DiscardChangeVal: false });
  handleDiscardChangePopup = () => { location.reload() }
  ideaSubmittedPopupCloseFun = () => this.handleCloseSubmitPopup();
  handleDeleteIterationPopupFn = () => this.setState({ showDeletePopup: true });
  onHoverDeleteBtn = () => this.setState({ hoverDelete: true });
  onMouseLeaveDeleteBtn = () => this.setState({ hoverDelete: false });
  handleEditIterationFn = () => this.setState({ editIterationClicked: true, onMouseEnterContext: false, onMouseEnterProblem: false, onMouseEnterSolution: false });
  onHoverEditBtn = () => this.setState({ hoverEdit: true });
  onMouseLeaveEditBtn = () => this.setState({ hoverEdit: false });

  handleShowHideItr = () => {
    if (this.state.isHide) {
      this.handleHide(this.state.isHide)
    }
    else {
      this.setState({ showhideItrPopup: true })
    }
  }

  handleShowReportItr = () => {
    if (this.state.isReport) {
      this.reportIdea(this.state.ideaID, false);
    }
    else {
      this.setState({ showReportPopup: true })
    }
  }

  onMouseEnterVisibility = () => this.setState({ hoverVisibility: true })
  onMounseLeaveVisibility = () => this.setState({ hoverVisibility: false });

  onMouseEnterReportIterationBtn = () => this.setState({ hoverReport: true })
  onMouseLeaveReportIterationBtn = () => this.setState({ hoverReport: false })

  onMouseEnterNotificationBtn = () => this.setState({ hoverNotify: true });
  onMouseLeaveNotificationBtn = () => this.setState({ hoverNotify: false });

  handlePrivacyPoupFun = () => this.setState({ openPrivacyDialog: false });

  handleHoveredText(contText: string, probText: string, solText: string, hoverValue: boolean) {
    this.setState({
      hoveredContextText: contText,
      hoveredProblemText: probText,
      hoveredSolutionText: solText,
      hoverOnIteration: hoverValue
    });
  }

  getValue = (state: any, value1: any, value2: any) => {
    return state ? value1 : value2
  }

  isItOtherUser = (isDeletable: boolean) => {
    return isDeletable && this.state.userName === localStorage.getItem("username")
  }

  isOrStyle = (state1: boolean, state2: boolean, style1: string, style2: string) => {
    return state1 || state2 ? style1 : style2
  }

  isAndStyle = (state1: any, state2: any, style1: any, style2: any) => {
    return state1 && state2 ? style1 : style2
  }
  isAndStateStyle = (state1: any, state2: any) => {
    return !state1 && !state2
  }
  is3AndStyle = (state1: any, state2: any, state3: any, value1: any, value2: any) => {
    return state1 && state2 && state3 ? value1 : value2
  }

  checkFeedType = (most_popular: any, most_promoted: any, most_contested: any) => {
    return most_popular === true ||
      most_promoted === true ||
      most_contested === true;
  }

  setIdeaID = (updatedIdeaLength: number, PostDataId: any) => {
    if (updatedIdeaLength == 0 || !updatedIdeaLength) {
      this.setState({ ideaID: PostDataId });
    }
  }

  handleUndoChangeContext = () => this.handleUndoChange("context");
  handleUndoChangeProblem = () => this.handleUndoChange("problem");
  handleUndoChangeSolution = () => this.handleUndoChange("solution");
  handleResetContext = () => this.contextReset();
  handleResetSolution = () => this.solutionReset();
  handleResetProblem = () => this.problemReset();
  disCardChangeFunction = () => this.setState({ DiscardChangeVal: true });

  handleSaveClickSubmit = () => this.handleSaveClick(this.state.ideaID, this.state.savedIdeas)
  handleDownVoteSubmit = () => this.handleDownVote(this.state.ideaID, this.state.likedIdeas, this.state.dislikedIdeas)
  handleUpVoteSubmit = () => this.handleUpVote(this.state.ideaID, this.state.likedIdeas, this.state.dislikedIdeas)
  handleShareBtnSubmit = () => this.handleShareBtn(this.state.ideaID, this.state.sharedIdeas)


  handleIdeaSubmit = () => {
    if (!this.checkFileSize()) return;
    if (this.state.ErrorInContent
      || this.state.ErrorInProblem
      || this.state.ErrorInSolution
      || this.state.ErrorInTitle
    ) return false
    let hashtagString = "";
    if (this.state.hashTags?.length > 0) {
      this.state.hashTags.forEach((each: any, index: number) => {
        if (index > 0) {
          hashtagString += ", ";
        }
        hashtagString += each.substring(1);
      });
    }

    const formData = new FormData();
    let ideaType = "";

    switch (this.state.PrivacySelectAddNewIdea) {
      case "private":
        ideaType = "i_private";
        break;
      case "platform":
        ideaType = "i_public";
        break;
      case "contacts":
        ideaType = "i_contact";
        break;
      default:
        ideaType = "i_public";
    }

    if (this.state.editIterationClicked) {
      this.addingEditIdeaAttributes(ideaType, hashtagString, formData);
      this.handleEditIdea(this.state.ideaID, this.props?.index, formData);
    } else {
      this.addingUpdateIdeaAttributes(ideaType, hashtagString, formData);
      this.handleUpdateIdea(this.props.PostData?.id, this.props?.index, formData);
    }
  };


  handleUnBlockUser = (userId: number) => { this.unBlockUser(+userId); }

  handleReportedUserScreen = (userId: number) => {
    return (
      <BlurPost
        reasonStyle="reportedMsg"
        reasonText="you have Reported this user"
        buttonText="unlock user"
        handleButtonClick={() => this.unBlockUser(+userId)}
        buttonIcon={reportIconWhite}
      />
    )
  }

  handleHiddenUserScreen = (userId: number) => {
    return (
      <BlurPost
        reasonText="you have Hidden this user"
        buttonText="show user"
        handleButtonClick={() => this.unHideUser(+userId)}
        buttonIcon={hideIconWhite}
      />
    )
  }

  handleReportItrScreen = () => {
    return (
      <BlurPost
        reasonStyle="reportedMsg"
        reasonText="you have Reported this iteration"
        buttonText="unlock iteration"
        handleButtonClick={() => this.handleReport(this.state.isReport)}
        buttonIcon={reportIconWhite}
      />
    )
  }

  handleHiddenItrScreen = () => {
    return (
      <BlurPost
        reasonText="you have hidden this iteration"
        buttonText="show iteration"
        handleButtonClick={this.handleHideItration}
        buttonIcon={revertIcon}
      />
    )
  }

  handleBlurScreenError = (userId: number) => {
    if (this.state.isReportedUser) {
      return this.handleReportedUserScreen(userId);
    } else if (this.state.isHiddenUser) {
      return this.handleHiddenUserScreen(userId)
    } else if (this.state.isReport) {
      return this.handleReportItrScreen()
    } else if (this.state.isHide) {
      return this.handleHiddenItrScreen()
    }
  }

  handleOrgIdeaText = () => {
    if (this.checkOrgIdea()) {
      return <p className="feedOrgIdeaText">Original idea</p>
    } else {
      return <></>
    }
  }

  handleOrgIdeaornot = (listOpenedId: any) => {
    this.setState({ openedListId: listOpenedId });
  }

  formatDate(dateStr: string): string {
    const originalDate = moment.tz(dateStr, 'DD.MM.YYYY, hh:mm A', 'Europe/London');
    const now = moment().tz('Europe/London');

    const diffDuration = moment.duration(now.diff(originalDate));
    const diffMinutes = diffDuration.asMinutes();
    const diffHours = diffDuration.asHours();
    const diffDays = diffDuration.asDays();
    const diffWeeks = diffDuration.asWeeks();

    if (diffMinutes < 1) {
      return 'just now';
    } else if (diffMinutes < 60) {
      return `${Math.floor(diffMinutes)}m`;
    } else if (diffHours < 24) {
      return `${Math.floor(diffHours)}h`;
    } else if (diffDays < 7) {
      return `${Math.floor(diffDays)}d`;
    } else if (diffWeeks < 4) {
      return `${Math.floor(diffWeeks)}w`;
    } else if (now.year() === originalDate.year()) {
      return originalDate.format('DD MMM, HH:mm');
    } else {
      return originalDate.format('DD MMM YYYY');
    }
  }

  handleIterationListFilterText = (item: string) => {
    this.setState({ iterationListTitle: item })
  }

  getIdeaVersionDetails = (ideas: any) => {
    if (ideas) {
      if (this.state.iterationListTitle === "recent") {
        return ideas.data?.sort((a: any, b: any) => { return +b.id - +a.id });
      }
      if (this.state.iterationListTitle === "popular") {
        // sort on highest to lowest output so descending order
        return ideas.data?.sort((a: any, b: any) => { return +b.attributes.iteration_popular - +a.attributes.iteration_popular });
      }
      if (this.state.iterationListTitle === "contested") {
        // pass if =<0.33 and fail if >0.33 so sort on ascending order
        let array2 = [...ideas.data]?.sort((a: any, b: any) => { return +a.attributes.iteration_contested - +b.attributes.iteration_contested });
        let array20 = [...ideas.data].filter((x: any) => x.attributes.iteration_contested === 0);
        let array3 = [...ideas.data].filter((x: any) => x.attributes.iteration_contested <= 0.33 && x.attributes.iteration_contested != 0);
        let array4 = array2.slice(array3.length + array20.length);
        return [...array3, ...array20, ...array4];
      }
      if (this.state.iterationListTitle === "promoted") {
        //  sort on highest number of shares so descending order
        return ideas.data?.sort((a: any, b: any) => { return +b.attributes.share_count - +a.attributes.share_count });
      }
      if (this.state.iterationListTitle === "default") {
        // sort on highest number of likes so descending order
        return ideas.data?.sort((a: any, b: any) => { return b.attributes.like_count - a.attributes.like_count });
      }
      return ideas?.data;
    }
    return []
  }



  /*Activity feed post Logics End */


  // Customizable Area End
}

//@ts-ignore
export default ActivityFeedController;