// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { withRouter } from "react-router-dom";
import CropEasy from "./userProfileComponent/CropEasy";
import ProfileImageUpload from "./userProfileComponent/profileImageUpload";
import { Prompt } from "react-router";
import { FormGroup } from "reactstrap";
import CustomMenuSideBar from "../../../components/src/CustomMenuSideBar";
import ProtectedRouteWeb from "../../email-account-login/src/ProtectedRoute.web";
import DeleteUser from "./DeleteUser";
import UserDateOfBirth from "./UserDateOfBirth";
import "./UserProfileBasicBlock.web.css";
import UserProfileBasicControllerWeb, {
  Props,
} from "./UserProfileBasicControllerWeb";
import CustomInput from "../../../components/src/CustomInput";
import ActivityPopup from "../../ActivityFeed/src/ActivityPopup.web";

const configJSON = require("./config");
// Customizable Area End
export class UserProfileBasicBlock extends UserProfileBasicControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  handleDate = (value: string, type: "day" | "month" | "year") => {
    if (type === "day") {
      this.setState({ day: value }, () => {
        this.checkDateYearValidation();
      });
    } else if (type === "month") {
      this.setState({ month: value }, () => {
        this.checkDateYearValidation();
      });
    } else {
      this.setState({ year: value });
    }
  };
  profileImageCrop = (img: any, file: any) => {
    this.setState({ profilePic: img });
    this.setState({ imageData: file });
  };
  BackGroundImageCrop = (img: any, file: any) => {
    this.setState({ profileBannerimg: img });
    this.setState({ profileBanner: file });
  };
  AddCropImageDailog = () => {
    this.setState({
      isImageDailog: false,
      isImageCropDailog: false,
      isEditImageDailog: false,
      isBackgroundImageDailog: false,
      isBackImageDailog: false,
    });
  };
  // Customizable Area End
  componentDidUpdate = () => {
    if (this.state.profileBannerimg == null || this.state.profilePic == null) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  };

  render() {
    // Customizable Area Start

    return (
      <>
        <Prompt
          when={
            this.state.profileBannerimg !== null ||
            this.state.profilePic !== null
          }
          message={(params) => this.checkPath(params)}
        />
        <ProtectedRouteWeb>
          <section className="sideBarContainer profileMainContainer">
            <section className="sideBar">
              <CustomMenuSideBar />
            </section>
            <section className="EditprofileInnerContainer">
              <section className="profileContainer">
                <section className="backgroungImgContainer">
                  <img
                    id="profileBanner"
                    src={this.getBannerImg()}
                    className={`backAddImg ${this.isOrStyle(this.state.profileBannerimg,
                      this.state.bannerPicFromBackEnd, "", "noBackImg")}`}
                    onClick={(event) => this.setProfileBanner(event)}
                  />
                  <input
                    accept=".png, .jpg, .jpeg, .svg"
                    id="inputinuserblock"
                    onChange={(event) => {
                      this.setProfileBanner(event);
                    }}
                    type="file"
                  />

                  {this.state.isBackgroundImageDailog && (
                    <CropEasy
                      modelTitle={"Background Photo"}
                      imgUrl={this.getProfileCropImg()}
                      handleImage={this.BackGroundImageCrop}
                      handleCloseDailog={this.AddCropImageDailog}
                      navigation={undefined}
                      id={""}
                      handleDate={this.handleDate}
                      day={undefined}
                      month={undefined}
                      year={undefined}
                      dayErr={false}
                      monthErr={false}
                      yearErr={false}
                      errorMsg={undefined}
                      img={undefined}
                    />
                  )}
                  <section className="profilePicContainer profilePicBasicContainer">
                    <IconButton aria-label="upload picture" component="span">
                      <div className={`noProfilePic ${this.isOrStyle(this.state.profilePic, this.context.profile_pic, "", "noProfilePicOrImg")}`}>
                        <img
                          id="profileImg"
                          src={this.getProfileImg()}
                          alt="profile"
                          className={this.isOrStyle(this.state.profilePicImageCrop, this.context.profile_pic, "", "profilePicSmall")}
                          onClick={() => this.setState({ isImageDailog: true })}
                        />
                      </div>
                    </IconButton>
                  </section>
                  <Dialog
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"dailogImageUpload"}
                    open={this.state.isImageDailog}
                    onClose={() => this.setState({ isImageDailog: false })}
                    closeAfterTransition
                    BackdropProps={{
                      timeout: 500,
                    }}
                    classes={{}}
                    maxWidth="lg"
                  >
                    <DialogTitle>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          style={{
                            textAlign: "center",
                            color: "#fff",
                          }}
                        >
                          {"  Profile Picture"}
                        </Typography>
                        <IconButton
                          id="imgDialog"
                          onClick={() =>
                            this.setState({ isImageDailog: false })
                          }
                        >
                          <Close style={{ color: "#fff" }} />
                        </IconButton>
                      </Box>
                    </DialogTitle>
                    <Divider />
                    <section
                      style={{ display: "flex", justifyContent: "center" }}
                      className="profilePicContainerDailog"
                    >
                      <IconButton aria-label="upload picture" component="span">
                        <div
                          className={`${this.state.isProfileImageTypeError ? "inValideProfileImg" : "noProfilePicDailog"}`}
                          style={
                            this.state.profilePic || this.context.profile_pic
                              ? undefined
                              : { padding: "51.5px" }
                          }
                        >
                          <img
                            src={this.getProfileImg()}
                            alt="profile"
                            className={this.isOrStyle(this.state.profilePic, this.context.profile_pic, "", "profilePicSmall")}
                          />
                        </div>

                      </IconButton>
                    </section>
                    {this.state.isProfileImageTypeError ? (
                      <p style={{ color: "red", textAlign: "center" }}>Selected images are not valid type!</p>
                    ) : null}
                    <Divider />
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        id="editImgDialog"
                        onClick={() =>
                          this.setState({ isEditImageDailog: true })
                        }
                      >
                        <IconButton style={{ color: "#fff" }}>
                          <EditIcon />
                          Edit
                        </IconButton>
                      </Button>
                      <Button
                        id="cropImgDialog"
                        onClick={() =>
                          this.setState({ isImageCropDailog: true })
                        }
                      >
                        <IconButton style={{ color: "#fff" }}>
                          <PhotoCameraIcon /> Add Photo
                        </IconButton>

                        <input
                          accept='.png, .jpg, .jpeg'
                          data-test-id="inputinuserblock"
                          id="inputinuserblock"
                          onChange={(event) => {
                            this.setImageData(event);
                          }}
                          type="file"
                        />
                      </Button>

                    </DialogActions>
                  </Dialog>
                  {this.state.isEditImageDailog && (
                    <ProfileImageUpload
                      modelTitle={"Edit Photo"}
                      imgUrl={this.getProfileImg()}
                      handleImage={this.profileImageCrop}
                      handleCloseDailog={this.AddCropImageDailog}
                      navigation={undefined}
                      id={""}
                      handleDate={this.handleDate}
                      day={undefined}
                      month={undefined}
                      year={undefined}
                      dayErr={false}
                      monthErr={false}
                      yearErr={false}
                      errorMsg={undefined}
                      img={undefined}
                    />
                  )}
                  {this.state.isImageCropDailog &&
                    this.state.profilePicImageCrop && (
                      <ProfileImageUpload
                        modelTitle={"Add Photo"}
                        imgUrl={this.getProfileImgCrop()}
                        handleImage={this.profileImageCrop}
                        handleCloseDailog={this.AddCropImageDailog}
                        navigation={undefined}
                        id={""}
                        handleDate={this.handleDate}
                        day={undefined}
                        month={undefined}
                        year={undefined}
                        dayErr={false}
                        monthErr={false}
                        yearErr={false}
                        errorMsg={undefined}
                        img={undefined}
                      />
                    )}

                </section>

                <section className="detailContainer">
                  <div className="threeLabelContainer">
                    <div className="nameTitle">
                      <label className="labelFullName">{`${this.state.firstName} ${this.state.lastName}`}</label>
                      <label className="labelEmail">{this.state.firstName} | {this.state.email}</label>
                    </div>
                  </div>
                  <label className="labelName">{configJSON.labelName}</label>
                  <div className="threeLabelContainer">
                    <FormGroup
                      className={`form-group width33 ${this.state.isFirstNameError && "inputError"}`}>
                      <CustomInput
                        id="txtInputFirstname"
                        placeholder={configJSON.labelFirstName}
                        className="f15"
                        type="text"
                        handleInputChange={(e: any) =>
                          this.setState({ firstName: e.target.value })
                        }
                        value={this.state.firstName}
                        isError={this.state.isFirstNameError}
                        handleError={() => {
                          this.setState({ isFirstNameError: false });
                        }}
                        errorMsg={this.state.errorMsgText}
                      />
                    </FormGroup>

                    <FormGroup
                      className={`form-group width33 ${this.state.isMiddleNameError && "inputError"}`}
                    >
                      <CustomInput
                        id="txtInputMiddleName"
                        placeholder={configJSON.labelMiddleName}
                        type="text"
                        className="middleName f15"
                        value={this.state.middleName}
                        handleInputChange={(e: any) =>
                          this.setState({ middleName: e.target.value })
                        }
                        isError={this.state.isMiddleNameError}
                        handleError={() => {
                          this.setState({ isMiddleNameError: false });
                        }}
                        errorMsg={this.state.errorMsgText}
                      />
                    </FormGroup>

                    <FormGroup
                      className={`form-group width33 ${this.state.isLastNameError && "inputError"}`}
                    >
                      <CustomInput
                        id="txtInputLastName"
                        placeholder={configJSON.labelLastName}
                        type="text"
                        className="lastName f15"
                        value={this.state.lastName}
                        handleInputChange={(e: any) =>
                          this.setState({ lastName: e.target.value })
                        }
                        isError={this.state.isLastNameError}
                        handleError={() => {
                          this.setState({ isLastNameError: false });
                        }}
                        errorMsg={this.state.errorMsgText}
                      />
                    </FormGroup>
                  </div>

                  <label className="labelName">
                    {configJSON.labelDateOfBirth}
                  </label>
                  <UserDateOfBirth
                    imgUrl={undefined}
                    navigation={undefined}
                    id={""}
                    handleDate={this.handleDate}
                    day={this.state.day}
                    month={this.state.month}
                    year={this.state.year}
                    dayErr={this.state.isDayError}
                    monthErr={this.state.isMonthError}
                    yearErr={this.state.isYearError}
                    errorMsg={this.state.errorMsgText}
                    handleImage={() => { }}
                    handleCloseDailog={() => { }}
                    modelTitle={""}
                    img={undefined}
                  />


                  <label className="labelName">
                    {configJSON.labelLocation}
                  </label>
                  <div className="threeLabelContainer pb-20">
                    <FormGroup
                      className={`form-group width50 ${this.state.isCityError && "inputError"}`}
                    >
                      <CustomInput
                        id="txtInputCity"
                        placeholder={configJSON.labelCity}
                        type="text"
                        className="city f15"
                        value={this.state.city}
                        handleInputChange={(e: any) =>
                          this.setState({ city: e.target.value })
                        }
                        isError={this.state.isCityError}
                        handleError={() => {
                          this.setState({ isCityError: false });
                        }}
                        errorMsg={this.state.errorMsgText}
                      />
                    </FormGroup>

                    <FormGroup
                      className={`form-group width50 ${this.state.isCountryError && "inputError"}`}
                    >

                      <CustomInput
                        id="txtInputCountry"
                        placeholder={configJSON.labelCountry}
                        type="text"
                        className="country f15"
                        value={this.state.country}
                        handleInputChange={(e: any) =>
                          this.setState({ country: e.target.value })
                        }
                        isError={this.state.isCountryError}
                        handleError={() => {
                          this.setState({ isCountryError: false });
                        }}
                        errorMsg={this.state.errorMsgText}
                      />
                    </FormGroup>
                  </div>
                  <label className="labelName">
                    {configJSON.labelBackground}
                  </label>

                  <FormGroup className="form-group pb-2">
                    <CustomInput
                      id="txtInputBackground"
                      placeholder={configJSON.labelBackgroundContent}
                      type="textarea"
                      className="backgroundDescription f15"
                      value={this.state.background}
                      handleInputChange={(e: any) =>
                        this.setState({ background: e.target.value })
                      }
                    />
                  </FormGroup>

                  <div className="submitButton deleteButton pb-20 pt-20">
                    <Button
                      id="submit"
                      onClick={() => {
                        this.checkAllValidations();
                      }}
                    >
                      {configJSON.btnTextSaveChanges}
                    </Button>
                  </div>
                </section>
              </section>
              <DeleteUser
                navigation={undefined}
                id={""}
                history={this.props.history}
                dayErr={false}
                monthErr={false}
                yearErr={false}
                imgUrl={""}
                handleImage={() => { }}
                handleCloseDailog={() => { }}
                modelTitle={""}
                img={undefined}
                handleDate={() => { }}
              />
              <ActivityPopup
                testId="submitpopup"
                open={this.state.isAlertOpen}
                title={this.state.alertMessageText}
                leftBtnTxt="ok"
                handleLeftBtn={this.handleCloseAlertMessagePopup}
              />
            </section>
          </section>
        </ProtectedRouteWeb>
      </>
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getUserProfile();
    // this.getValidations();
    // this.requestSessionData();
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
//@ts-ignore
export default withRouter(UserProfileBasicBlock);
// Customizable Area Start