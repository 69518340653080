import React from "react";

// Customizable Area Start
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FormGroup } from "reactstrap";

import ForgotPasswordControllerWeb, {
  Props,
} from "./ForgotPasswordControllerWeb";
import "./NewPassword.web.css";
import CustomPassword from "../../../components/src/CustomPassword";
import ProtectedRouteWeb from "../../email-account-login/src/ProtectedRoute.web";
import CustomMenuSideBar from "../../../components/src/CustomMenuSideBar";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";

// Customizable Area End
const configJSON = require("./config");

export default class NewPassword extends ForgotPasswordControllerWeb {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
 
  componentDidMount(): any {}
  render() {
    return (
      <ProtectedRouteWeb>
        <section className="passwordContainer sideBarContainer">
          <section className="sideBar">
            <CustomMenuSideBar />
          </section>
          <section className="sideBarInnerContainer profileInnerContainer">
            <h2 className="changePasswordHeader">
              {configJSON.labelChangePassword}
            </h2>
            <div className="changeEmailPwdContainer">
              <div className="LoginErrorBox">
                <CustomErrorAlert
                  isError={this.state.isFieldEmpty}
                  handleError={this.handleIsFieldEmpty}
                  errorMsg={configJSON.errorAllFieldsAreMandatory}
                />
              </div>
              <div
                className={`form-group ${
                  this.state.isOldPwdCorrect ? `inputError` : ``
                }`}
              >
                <CustomPassword
                  id="txtInputPassword1"
                  placeholder={configJSON.labelOldpassword}
                  handlePasswordChange={this.handleOldPasswordChange}
                  isError={this.state.isOldPwdCorrect}
                  errorMsg={this.state.errorMsgText}
                  handleError={this.handleOldPasswordError}
                />
              </div>
              <div
                className={`form-group ${
                  this.state.isNewPwdValid ? `pwdError inputError` : ``
                }`}
              >
                <CustomPassword
                  id="txtInputPassword2"
                  placeholder={configJSON.labelNewPassword}
                  handlePasswordChange={this.handleNewPasswordChange}
                  isError={
                    this.state.isNewPwdValid ||
                    this.state.areOldnew_password_same
                  }
                  errorMsg={this.state.errorMsgText}
                  handleError={this.handleNewPasswordError}
                />
              </div>
              <ul className="pwdRule">
                <li>{configJSON.PwdRule1}</li>
                <li>{configJSON.PwdRule2}</li>
                <li>{configJSON.PwdRule3}</li>
                <li>{configJSON.PwdRule4}</li>
              </ul>
              <div
                className={`form-group pwdError ${
                  this.state.areBothPwdscorrect ? `inputError` : ``
                }`}
              >
                <CustomPassword
                  id="txtInputPassword3"
                  placeholder={configJSON.labelConfirmnewPassword}
                  handlePasswordChange={this.handleConfirmNewPasswordChange}
                  isError={this.state.areBothPwdscorrect}
                  errorMsg={this.state.errorMsgText}
                  handleError={this.handleBothPasswordsCorrectError}
                  pressEnter={this.handleChangePassword}
                />
              </div>
            </div>
            <div
              className={`alertContainer ${
                this.state.isOldPwdChanged ? "" : "hideAlert"
              } `}
            >
              <Alert severity="success">
                {configJSON.labelPasswordChangeSuccessAlert}
              </Alert>
            </div>
            <div className="submitButton">
              <Button onClick={this.handleChangePassword} id="submitBtnId">
                {configJSON.btnChange}
              </Button>
            </div>
          </section>
        </section>
      </ProtectedRouteWeb>
    );
  }
   // Customizable Area End
}
