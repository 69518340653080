import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  Context,
  ProblemIcon,
  SolutionIcon
} from "../../postcreation/src/assets";
export const Colors = {
  inputLabel: "rgba(31, 31, 34, 0.6)",
  inputTextColor: "rgb(31, 31, 34)",
  borderGrey: "rgba(28, 28, 30, 0.3)",
  borderYellow: "rgb(205, 149, 12)",
  white: "#FFFFFF",
  modalBg: "rgb(243, 243, 243)"
};
type errorType = boolean | string | string[];
let URL = require("url").URL;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  title?: "context" | "problem" | "solution";
  handleTextChange?: any;
  valueText?: string;
  whatIsIt?: string;
  contextImageUpload?: any;
  ProblemImageUpload?: any;
  SolutionImageUpload?: any;
  ErrorIn?: any;
  ImagePreview?:any;
  VideoUpload?:any;
  removeImage?:any; 
  imageCropPopup?:any;
  profileImageCrop?:any; 
   handleDate?:any; 
    AddCropImageDailog?:any; 
     setImageData?:any;
       ideaPicImageCrop?:any; 
       
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  TitleText: any;
  ProblemText: string;
  SolutionText: string;
  ContextText: string;
  openPlusDialogContent: any;
  contextImageUpload: any;
  contextVideoUpload: any;
  problemVideoUpload: any;
  solutionVideoUpload: any;
  problemImageUpload: any;
  solutionImageUpload: any;
  contextImagePreview: any;
  problemImagePreview: any;
  solutionImagePreview: any;
  isLoading: boolean;
  ErrorInTitle: boolean;
  ErrorInContent: boolean;
  ErrorInProblem: boolean;
  ErrorInContentMedia: boolean;
  ErrorInProblemMedia: boolean;
  ErrorInSolutionMedia: boolean;
  openSnackBarError: boolean;
  totalFileError: boolean;
  titleValidation: boolean;
  ExtentionError: boolean;
  ErrorInSolution: boolean;
  contentValidation: boolean;
  problemValidation: boolean;
  openPlusDialogProblem?: any;
  openPlusDialogSolution?: any;
  ideaPicImageCrop: any;
  imageCropPopup: boolean;
  isAlertOpen: boolean;
  alertMessageText: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FeedBackformController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  feedBackApiID: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.state = {
      imageCropPopup: false,
      ideaPicImageCrop: "",
      TitleText: "",
      ProblemText: "",
      SolutionText: "",
      ContextText: "",
      openPlusDialogContent: false,
      contextImageUpload: null,
      problemImageUpload: null,
      solutionImageUpload: null,
      contextImagePreview: null,
      problemImagePreview: null,
      solutionImagePreview: null,
      contextVideoUpload: null,
      problemVideoUpload: null,
      solutionVideoUpload: null,
      isLoading: false,
      ErrorInTitle: false,
      ErrorInContent: false,
      ErrorInProblem: false,
      ErrorInContentMedia: false,
      ErrorInProblemMedia: false,
      ErrorInSolutionMedia: false,
      openSnackBarError: false,
      totalFileError: false,
      titleValidation: false,
      ExtentionError: false,
      contentValidation: false,
      ErrorInSolution: false,
      problemValidation: false,
      openPlusDialogProblem: false,
      openPlusDialogSolution: false,
      isAlertOpen: false,
      alertMessageText: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.feedBackApiID) {
          // console.log(responseJson)
          if (!responseJson.errors) {
            this.setState({
              isLoading: false,
              isAlertOpen: true,
              alertMessageText: "Feedback Submitted."
            });
          } else if (responseJson.errors[0].token) {
            this.setState({
              isLoading: false,
              isAlertOpen: true,
              alertMessageText:
                "Session Expired. Please login again. Redirecting..."
            });
            setTimeout(() => {
              this.props.navigation.navigate("EmailAccountLogin");
            }, 2000);
          } else {
            this.setState({
              isLoading: false,
              isAlertOpen: true,
              alertMessageText: "Something went wrong. Try again."
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // async componentDidMount() {
  //   super.componentDidMount();
  //   this.getToken();
  //   if (this.isPlatformWeb() === false) {
  //     this.props.navigation.addListener("willFocus", () => {
  //       this.getToken();
  //     });
  //   }
  // }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  inputLabelProps = {
    fontSize: 14,
    color: Colors.inputLabel,
    lineHeight: 16
  };

  handleTooltipCloseContent = () => {
    this.setState({ openPlusDialogContent: false });
    // alert('closed')
  };

  handlePlusContent = (event: any) => {
    event.preventDefault();
    this.setState({ openPlusDialogContent: true }, () => {});
  };

  checkFileSize = () => {
    let files = [];
    this.state.contextImageUpload.size &&
      files.push(this.state.contextImageUpload.size);
    this.state.solutionImageUpload.size &&
      files.push(this.state.solutionImageUpload.size);
    this.state.problemImageUpload.size &&
      files.push(this.state.problemImageUpload.size);
    this.state.contextVideoUpload.size &&
      files.push(this.state.contextVideoUpload.size);
    this.state.problemVideoUpload.size &&
      files.push(this.state.problemVideoUpload.size);
    this.state.solutionVideoUpload.size &&
      files.push(this.state.solutionVideoUpload.size);

    let temp = 0;
    files.forEach(e => {
      if (e !== undefined) {
        temp += e;
      }
    });
    temp = temp / 1024 / 1024;
    // console.log("temp", temp)
    if (temp > 50) {
      this.setState({
        totalFileError: true
      });
      setTimeout(() => {
        this.setState({
          totalFileError: false
        });
      }, 3000);
      // console.log("return false")
      return false;
    }
    // console.log("return something", temp)
    if (temp == 0) {
      return true;
    } else {
      return temp;
    }
  };

  submitFeedbackAPI = () => {
    const header = {
      token: localStorage.getItem("authToken")
    };

    // console.log(this.state)
    const formData = new FormData();
    formData.append("description", this.state.TitleText);
    formData.append("context_attributes[description]", this.state.ContextText);
    formData.append("problem_attributes[description]", this.state.ProblemText);
    formData.append(
      "solution_attributes[description]",
      this.state.SolutionText
    );

    //check for images
    if (this.state.contextImageUpload) {
      formData.append(
        "context_attributes[image]",
        this.state.contextImageUpload
      );
    }

    if (this.state.problemImageUpload) {
      formData.append(
        "problem_attributes[image]",
        this.state.problemImageUpload
      );
    }

    if (this.state.solutionImageUpload) {
      formData.append(
        "solution_attributes[image]",
        this.state.solutionImageUpload
      );
    }

    //check for videos
    if (this.state.contextVideoUpload) {
      formData.append(
        "context_attributes[video]",
        this.state.contextVideoUpload
      );
    }

    if (this.state.problemVideoUpload) {
      formData.append(
        "problem_attributes[video]",
        this.state.problemVideoUpload
      );
    }

    if (this.state.solutionVideoUpload) {
      formData.append(
        "solution_attributes[video]",
        this.state.solutionVideoUpload
      );
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.feedBackApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.feedbackAPiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPOST
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitFeedback = () => {
    if (!this.checkFileSize()) {
      return;
    }
    if (
      this.state.ErrorInContent ||
      this.state.ErrorInProblem ||
      this.state.ErrorInSolution ||
      this.state.ErrorInTitle
    ) {
      return false;
    }

    if (this.state.TitleText.length <= 0) {
      this.setState({
        titleValidation: true
      });
      setTimeout(() => {
        this.setState({
          titleValidation: false
        });
      }, 6000);
      return false;
    }
    if (
      this.state.ProblemText.length <= 0 &&
      this.state.SolutionText.length <= 0
    ) {
      this.setState({
        problemValidation: true
      });
      setTimeout(() => {
        this.setState({
          problemValidation: false
        });
      }, 6000);
      return false;
    }

    this.setState({
      isLoading: true
    });

    this.submitFeedbackAPI();
  };
  onCustomAlertCloseButtonClick = () => {
    this.setState({
      openSnackBarError: false,
      totalFileError: false,
      titleValidation: false,
      contentValidation: false,
      problemValidation: false,
      ExtentionError: false,
      ErrorInContent: false,
      ErrorInSolution: false,
      ErrorInProblem: false
      // openPlusDialogProblem:false,
      // openPlusDialogSolution:false,
      // openPlusDialogContent:false
    });
  };

  errorCheckInSolutionFeedback = () => {
    let Text: errorType = this.state.SolutionText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      // console.log('workingggggg')
      this.setState(
        {
          ErrorInSolution: true
        },
        () => this.checkErrorsInAllFeildFeedback()
      );
    } else {
      this.setState(
        {
          ErrorInSolution: false
        },
        () => this.checkErrorsInAllFeildFeedback()
      );
    }
  };

  errorCheckInProblemFeedback = () => {
    let Text: errorType = this.state.ProblemText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      this.setState(
        {
          ErrorInProblem: true
        },
        () => this.checkErrorsInAllFeildFeedback()
      );
    } else {
      this.setState(
        {
          ErrorInProblem: false
        },
        () => this.checkErrorsInAllFeildFeedback()
      );
    }
  };
  errorCheckInContentFeedback = () => {
    let Text: errorType = this.state.ContextText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      this.setState(
        {
          ErrorInContent: true
        },
        () => this.checkErrorsInAllFeildFeedback()
      );
    } else {
      this.setState(
        {
          ErrorInContent: false
          // openSnackBarError: false,
        },
        () => this.checkErrorsInAllFeildFeedback()
      );
    }
  };
  checkErrorsInAllFeildFeedback = () => {
    if (
      this.state.ErrorInContent ||
      this.state.ErrorInProblem ||
      this.state.ErrorInSolution
    ) {
      this.setState({ openSnackBarError: true });
    } else {
      this.setState({ openSnackBarError: false });
    }
  };
  handleTitleTextInput = (e: any) => {
    this.setState({ TitleText: e.target.value });
  };

  handleContextTextInput = (e: any) => {
    this.setState({ ContextText: e.target.value }, () => {
      this.errorCheckInContentFeedback();
    });
  };

  handleProblemTextInput = (e: any) => {
    this.setState({ ProblemText: e.target.value }, () => {
      this.errorCheckInProblemFeedback();
    });
  };

  handleSolutionTextInput = (e: any) => {
    this.setState({ SolutionText: e.target.value }, () => {
      this.errorCheckInSolutionFeedback();
    });
  };

  /*TitleBox logic starts */
  handleReloadIcon = () => {
    this.props.removeImage(this.props.title);
    this.setState({ openPlusDialogContent: false });
  };

  getImage = (title: string) => {
    const checkProbOrSol = title === "problem" ? ProblemIcon : SolutionIcon;
    return title === "context" ? Context : checkProbOrSol;
  };
  /*TitleBox logic End */
  contextImageUpload = (e: any, title: string) => {
    //Index = 0 for Image, 1 for Video
    if (title === "context") {
      let vid = e.target.files[0];
      if (vid.name.match(/\.(mp4)$/)) {
        this.setState({ contextVideoUpload: e.target.files[0] });
      } else {
        this.setState(
          {
            ExtentionError: true,
            ErrorInContentMedia: true,
            openPlusDialogContent: false
          },
          () => {
            setTimeout(() => {
              this.setState({
                ErrorInContentMedia: false,
                ExtentionError: false
              });
            }, 3000);
          }
        );
      }
    } else if (title === "problem") {
      let vid = e.target.files[0];
      if (vid.name.match(/\.(mp4)$/)) {
        this.setState({ problemVideoUpload: e.target.files[0] });
      } else {
        this.setState(
          {
            ExtentionError: true,
            ErrorInProblemMedia: true,
            openPlusDialogProblem: false
          },
          () => {
            setTimeout(() => {
              this.setState({
                ErrorInProblemMedia: false,
                ExtentionError: false
              });
            }, 3000);
          }
        );
      }
    } else if (title === "solution") {
      let vid = e.target.files[0];
      if (vid.name.match(/\.(mp4)$/)) {
        this.setState({ solutionVideoUpload: e.target.files[0] });
      } else {
        this.setState(
          {
            ExtentionError: true,
            ErrorInSolutionMedia: true,
            openPlusDialogSolution: false
          },
          () => {
            setTimeout(() => {
              this.setState({
                ErrorInSolutionMedia: false,
                ExtentionError: false
              });
            }, 3000);
          }
        );
      }
    }
  };

  profileImageCrop = (img: any, file: any, title: any) => {
    if (title === "context") {
      this.setState({ contextImageUpload: file }, () => {
        this.setState({
          contextImagePreview: img
        });
      });
    } else if (title === "problem") {
      this.setState({ problemImageUpload: file }, () => {
        this.setState({
          problemImagePreview: img
        });
      });
    } else if (title === "solution") {
      this.setState({ solutionImageUpload: file }, () => {
        this.setState({
          solutionImagePreview: img
        });
      });
    }
  };

  AddCropImageDailog = () => {
    this.setState({
      imageCropPopup: false
    });
  };

  setImageDataFeedback = async (event: any, title: string) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (img.name.match(/\.(jpg|jpeg|png)$/)) {
        this.setState({ ideaPicImageCrop: URL.createObjectURL(img) }, () => {
          this.setState({ imageCropPopup: true });
        });
      } else if (img.name.match(/\.(gif)$/)) {
        switch (title) {
          case "context":
            return this.setState({ contextImageUpload: img }, () => {
              this.setState({
                contextImagePreview: URL.createObjectURL(img)
              });
            });
          case "problem":
            return this.setState({ problemImageUpload: img }, () => {
              this.setState({
                problemImagePreview: URL.createObjectURL(img)
              });
            });
          case "solution":
            return this.setState({ solutionImageUpload: img }, () => {
              this.setState({
                solutionImagePreview: URL.createObjectURL(img)
              });
            });
          default:
            return;
        }
      } else {
        this.setState({
          ideaPicImageCrop: null,
          ExtentionError: true
          // ErrorInContent:true
        });
        switch (title) {
          case "context":
            return this.setState(
              {
                ErrorInContentMedia: true,
                openPlusDialogContent: false
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    ErrorInContentMedia: false,
                    ExtentionError: false
                  });
                }, 3000);
              }
            );
          case "problem":
            return this.setState(
              { ErrorInProblemMedia: true, openPlusDialogProblem: false },
              () => {
                setTimeout(() => {
                  this.setState({
                    ErrorInProblemMedia: false,
                    ExtentionError: false
                  });
                }, 3000);
              }
            );
          case "solution":
            return this.setState(
              { ErrorInSolutionMedia: true, openPlusDialogSolution: false },
              () => {
                setTimeout(() => {
                  this.setState({
                    ErrorInSolutionMedia: false,
                    ExtentionError: false
                  });
                }, 3000);
              }
            );
          default:
            return;
        }
      }
    }
  };

  handleDate = (value: string, type: "day" | "month" | "year") => {
    console.log("print", value, type);
  };

  handleCloseAlertMessagePopup = () => {
    this.setState({
      isAlertOpen: false,
      alertMessageText: ""
    });
  };

  removeImage = (title: string | undefined) => {
    if (title === "context") {
      this.setState({
        contextImagePreview: null,
        contextImageUpload: null,
        contextVideoUpload: null
      });
    } else if (title === "problem") {
      this.setState({
        problemImagePreview: null,
        problemImageUpload: null,
        problemVideoUpload: null
      });
    } else if (title === "solution") {
      this.setState({
        solutionImagePreview: null,
        solutionImageUpload: null,
        solutionVideoUpload: null
      });
    }

    // Customizable Area End
  }
}