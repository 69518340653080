// Customizable Area Start
import React from "react";
import { TextField } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import FeedBackController, {Props} from "../FeedBackController";


const useStyles = (theme: Theme) => ({
    LabelBox: {
        marginTop: '2rem',
        width: '100%',
        color: "#f1f2f2",
        backgroundColor: "0c2738",
        borderRadius: "4px",
        height: "auto",
        // boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25) inset", 
        border: "none",
        padding: "11px 18px",
        fontFamily: "Jost-Regular",
        fontWeight: 400,
        marginBottom: "0.2rem",
        display: "flex",
        justifyContent: "space-between",
        textTransform: 'uppercase',
    },
    input: {
        fontFamily: "Jost-Regular",
        fontWeight: "400",
        fontSize: "15px",
        lineHeight: "22px",
        color: '#f1f2f2',
        '&::placeholder': {
            opacity: 1,
        },
    },
});


// Customizable Area End


class FeedBack extends FeedBackController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {        
        return (
            <>
                <TextField
                    minRows={2}
                    className="form-control feedbackTxtInput"
                    classes={{
                        root: 'height-auto'
                    }}
                    onChange={this.props.handleTextChange}
                    value={this.props.valueText}
                    multiline
                    placeholder={`Type ${this.props.whatIsIt} here...`}
                    InputProps={{
                        classes: { input: this.props.classes.input }
                    }}
                />
            </>
        );
    }

    // Customizable Area End
}

// Customizable Area Start

//@ts-ignore
export default withStyles(useStyles)(FeedBack)
// Customizable Area End
