import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  referralCode: string;
  registrationParams: Object;
  termsAndConditonsContent: any;
  isCheckBoxChecked: boolean;
  passwordHelperText: string;
  data: any[];
  isFieldEmpty: boolean;
  isprofilePicError: boolean;
  isFirstNameError: boolean;
  isLastNameError: boolean;
  isUserNameError: boolean;
  isTokenError: boolean;
  isEmailError: boolean;
  isPasswordError: boolean;
  isCheckBoxError: boolean;
  errorMsgText: string;
  oldEmail: string;
  newEmail: string;
  newEmailRepeat: string;
  isOldEmailCorrect: boolean;
  areBothMailIdsEqual: boolean;
  isMailChanged: boolean;
  add_cookieValue: boolean;
  isUserLogged: boolean;
  isURLReferralEmail: boolean;
  isURLReferralCode: boolean;
  profilePicImg: any;
  profilePicImgBinary: any;
  regSuccess: boolean;
  isImageDailog: boolean;
  isEditImageDailog: boolean;
  isImageCropDailog: boolean;
  profilePicImageCrop: any;
  imageData: any;
  isAddProfileDailog: boolean;
  isLoading: boolean;
  open: boolean;
  scroll: any["scroll"]
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  nameReg: RegExp;
  userNameReg: RegExp;
  createAccountApiCallId: any = "";
  validationApiCallId: string = "";
  changeEmailCallId: string = "";
  termsAndConditionCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      referralCode: "",
      registrationParams: {},
      isCheckBoxChecked: false,
      isFieldEmpty: false,
      isprofilePicError: false,
      isFirstNameError: false,
      isLastNameError: false,
      isUserNameError: false,
      isEmailError: false,
      isPasswordError: false,
      isCheckBoxError: false,
      isTokenError: false,
      errorMsgText: "",
      data: [],
      passwordHelperText: "",
      oldEmail: "",
      newEmail: "",
      newEmailRepeat: "",
      isOldEmailCorrect: false,
      areBothMailIdsEqual: false,
      isMailChanged: false,
      add_cookieValue: false,
      isUserLogged: true,
      isURLReferralEmail: false,
      isURLReferralCode: false,
      termsAndConditonsContent: "",
      profilePicImg: null,
      profilePicImageCrop: null,
      profilePicImgBinary: null,
      regSuccess: false,
      isImageDailog: false,
      isEditImageDailog: false,
      isImageCropDailog: false,
      imageData: null,
      isAddProfileDailog: false,
      isLoading: false,
      open: false,
      scroll: "paper"
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];

    this.nameReg = /[^A-Za-z\p{L}]/u;
    this.userNameReg = new RegExp("^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+$");
    this.emailReg = new RegExp("[^@]+[@][\\S]+[.][\\S]+");
    this.passwordReg = new RegExp(
      "^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
    );
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (!apiRequestCallId && !responseJson) {
      return;
    }

    if (this.isMessageIDisEqual(apiRequestCallId, this.validationApiCallId)) {
      this.arrayholder = responseJson.data;
      this.isValidationRegex(this.arrayholder)

    }
    if (this.isMessageIDisEqual(apiRequestCallId, this.createAccountApiCallId)) {
      if (!responseJson.errors) {
        this.setState({ regSuccess: true, isLoading: false });
        localStorage.setItem("authToken", responseJson.meta.token);
        localStorage.setItem("username", responseJson.data.attributes.user_name);
        localStorage.setItem("email", responseJson.data.attributes.email);
        localStorage.setItem("profilepic", responseJson.data.attributes.image);
        setTimeout(() => {
          this.props.navigation.navigate("ActivityFeed");
        }, 5000)

      } else {
        this.setState({ isLoading: false });
        this.createAccountErrorhandle(responseJson)
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    if (this.isMessageIDisEqual(apiRequestCallId, this.changeEmailCallId)) {
      if (!responseJson.errors) {
        const msg: Message = new Message(
          getName(MessageEnum.AccoutResgistrationSuccess)
        );
        this.setState({ isMailChanged: true });
        localStorage.setItem("email", this.state.newEmail)
        setTimeout(() => {
          this.props.navigation.navigate("AddNewIdea");
        }, 3000);
      } else {
        this.sendFailMessage();
        this.changeEmailErrorhandle(responseJson)
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    if (this.isMessageIDisEqual(apiRequestCallId, this.termsAndConditionCallId)) {
      this.handleTermsAndCondition(responseJson)
    }


    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const isUserLogin = localStorage.getItem("checkedRememberMe");
    const isAcceptedCookie = localStorage.getItem("is_accepted_cookies");

    this.setState({ add_cookieValue: isAcceptedCookie ? JSON.parse(isAcceptedCookie) : false });
    this.setState({ isUserLogged: isUserLogin ? JSON.parse(isUserLogin) : true });
    this.getValidations();
    this.getTermsAndConditions();

    type URLparams = {
      email: string;
      token: string
    };

    let regUrlParams: URLparams = {
      email: "",
      token: ""
    };

    let query = window.location.search.substring(1);
    let variables = query.split("&");
    for (let value of variables) {
      let pair = value.split("=");
      //@ts-ignore
      regUrlParams[pair[0]] = pair[1];
    }
    this.setState(
      { registrationParams: regUrlParams, email: regUrlParams.email, referralCode: regUrlParams.token },
      () => {
        if (this.state.email && this.state.email !== "") {
          this.setState({ isURLReferralEmail: true });
        }
        if (this.state.email === "" || this.state.email === undefined) {
          this.setState({ isURLReferralEmail: false });
        }
        if (this.state.referralCode && this.state.referralCode !== "") {
          this.setState({ isURLReferralCode: true });
        }
        if (this.state.referralCode === "" || this.state.referralCode === undefined
        ) {
          this.setState({ isURLReferralCode: false });
        }
      }
    );
  }

  setImageData = async (event: any) => {
    this.setState({ isprofilePicError: false })
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (img.name.match(/\.(jpg|jpeg|png|svg)$/)) {
        this.setState({ profilePicImageCrop: URL.createObjectURL(img) },
          () => { this.setState({ isImageDailog: true }) }
        )
      } else {
        alert("Selected images are not valid type!")
        this.setState({
          profilePicImg: null,
        })
      }

    }
  }

  isMessageIDisEqual(apimethodId: any, callResponseId: any) {
    return apimethodId === callResponseId
  }

  isValidationRegex(arr: any) {
    if (arr && arr.length !== 0) {
      let regexData = arr[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  changeEmailErrorhandle(responseJson: any) {

    //The Following error handler when there is error in new Email
    if (responseJson.errors[0]?.new_email) {
      this.setState({
        isEmailError: true,
      });
      this.handleError(responseJson.errors[0]?.new_email);
    }

    //The Following error handler when current mail is same as old mail id
    if (responseJson.errors[0]?.same_email != undefined) {
      this.setState({
        isEmailError: true,
      });
      this.handleError("New e-mail address can't be same as Old e-mail address");
    }

    //The Following error handler when Email already exists
    if (responseJson.errors[0]?.old_email != undefined) {
      this.setState({
        isOldEmailCorrect: true,
      });
      this.handleError(responseJson.errors[0].old_email);
    }
  }
  createAccountErrorhandle(response: any) {
    //The Following error handler is when Email already exists
    if (response.errors[0].account != undefined) {
      this.setState({
        isEmailError: true,
      });
      this.handleError(response.errors[0].account);
    }
    //The Following error handler is when Email not valid
    if (response.errors[0].email != undefined) {
      this.setState({
        isEmailError: true,
      });
      this.handleError(configJSON.errorEmailNotValid);
    }
    //The Following error handler is when username already exists
    if (response.errors[0].user_name != undefined && response.errors[0].user_name === "has already been taken") {
      this.setState({
        isUserNameError: true,
        errorMsgText: configJSON.errorUserNameExists,
      });
    }
    //The Following error handler is when username has spaces
    if (response.errors[0].user_name != undefined && response.errors[0].user_name === "must contain no spaces") {
      this.setState({
        isUserNameError: true,
        errorMsgText: "User Name should not contain spaces.",
      });
    }
    //The Following error handler is when token is not valid
    if (response.errors[0].token != undefined) {
      this.setState({
        isTokenError: true,
        errorMsgText: response.errors[0].token,
      });
    }
  }

  handleTermsAndCondition(responseJson: any) {
    if (!responseJson.errors) {
      this.setState({ termsAndConditonsContent: responseJson.data.attributes.description })
    }
  }

  sendFailMessage() {
    const msg: Message = new Message(
      getName(MessageEnum.AlertButtonNegativeMessage)
    );
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  isValidName(name: string) {
    return this.nameReg.test(name);
  }

  handleError = (errorMsg: string) => {
    this.setState({
      errorMsgText: errorMsg,
    });
  };

  handleSubmit = () => {
    this.setState({
      isLoading: true,
      isFieldEmpty: false,
      isprofilePicError: false,
      isFirstNameError: false,
      isLastNameError: false,
      isEmailError: false,
      isUserNameError: false,
      isPasswordError: false,
      isCheckBoxError: false,
      isTokenError: false
    });
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.userName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password)
    ) {
      this.setState({ isFieldEmpty: true, isLoading: false });
      return false;
    }

    if (this.isValidName(this.state.firstName)) {
      this.setState({
        isFirstNameError: true,
        isLoading: false
      });
      return false;
    }

    if (this.isValidName(this.state.lastName)) {
      this.setState({
        isLastNameError: true,
        isLoading: false
      });
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.setState({
        isEmailError: true,
        isLoading: false
      });
      this.handleError(configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password.length !== this.state.password.trim().length) {
      this.setState({
        isPasswordError: true,
        isLoading: false
      });
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.setState({
        isPasswordError: true,
        isLoading: false
      });
      return false;
    }

    if (this.isStringNullOrBlank(this.state.profilePicImg)) {
      this.setState({
        isprofilePicError: true,
        isLoading: false
      });
      return false;
    }

    if (!this.state.isCheckBoxChecked) {
      this.setState({
        isCheckBoxError: true,
        isLoading: false
      });
      return false;
    }
    this.createAccount();
  };

  createAccount(): boolean {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const formData = new FormData();
    formData.append("data[type]", "email_account");
    formData.append("data[attributes][first_name]", this.state.firstName);
    formData.append("data[attributes][last_name]", this.state.lastName);
    formData.append("data[attributes][user_name]", this.state.userName);
    formData.append("data[attributes][email]", this.state.email);
    formData.append("data[attributes][password]", this.state.password);
    formData.append("data[attributes][token]", this.state.referralCode);
    formData.append("data[attributes][image]", this.state.profilePicImgBinary);


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getTermsAndConditions() {
    const headers = {
      "Accept": "*/*"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsAndConditionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetTermsAndConditions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.termsAndConditionMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFirstname = (e: any) => {
    this.setState({ firstName: e.target.value })
  }
  handleFirstNameError = () => {
    this.setState({ isFirstNameError: false });
  }
  handleLastNameError = () => {
    this.setState({ isLastNameError: false });
  }

  handleUserNameError = () => {
    this.setState({ isUserNameError: false });
  }
  handleEmailError = () => {
    this.setState({ isEmailError: false });
  }

  handlePasswordError = () => {
    this.setState({ isPasswordError: false });
  }
  handleTokenError = () => {
    this.setState({ isTokenError: false });
  }

  handleLastname = (e: any) => {
    this.setState({ lastName: e.target.value })
  }
  handleUserName = (e: any) => {
    this.setState({ userName: e.target.value })
  }
  handleRegistrationToken = (e: any) => {
    if (this.state.isURLReferralCode) {
      this.setState({ referralCode: this.state.referralCode });
    } else {
      this.setState({ referralCode: e.target.value });
    }
  }
  handleEmailChange = (e: any) => {
    if (this.state.isURLReferralEmail) {
      this.setState({ email: this.state.email });
    } else {
      this.setState({ email: e.target.value });
    }
  }
  handlePasswordChange = (e: any) => {
    this.setState({ password: e.target.value })
  }
  handleTermsAndConditionCheckBox = (e: any) => {
    this.setState({
      isCheckBoxChecked: !this.state.isCheckBoxChecked,
      isCheckBoxError: false,
    })
  }

  handleRegistrationError = () => {
    this.setState({ isFieldEmpty: false });
  }
  handleProfileError = () => {
    this.setState({ isprofilePicError: false });

  }
  handleCheckboxError = () => {
    this.setState({ isCheckBoxError: false });

  }

  handleCheckBoxEnterKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.setState({
        isCheckBoxChecked: !this.state.isCheckBoxChecked,
        isCheckBoxError: false,
      });
    }
  }

  handleSubmitEmail = () => {
    this.setState({
      isFieldEmpty: false,
      isOldEmailCorrect: false,
      isEmailError: false,
      areBothMailIdsEqual: false,
    });
    if (
      this.state.newEmail === "" ||
      this.state.newEmailRepeat === "" ||
      this.state.oldEmail === ""
    ) {
      this.setState({ isFieldEmpty: true });
      return false;
    }

    if (!this.isValidEmail(this.state.newEmail)) {
      this.setState({
        isEmailError: true,
      });
      this.handleError(configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.newEmail !== this.state.newEmailRepeat) {
      this.setState({
        areBothMailIdsEqual: true,
      });
      this.handleError(configJSON.errorEmailsNotMatched);
      return false;
    }
    this.handleChangeEmail();
  }

  handleChangeEmail = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const data = {
      token: localStorage.getItem("authToken"),
      old_email: this.state.oldEmail,
      new_email: this.state.newEmail,
      confirm_email: this.state.newEmailRepeat,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeEmailAPiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  addCookies = (value: any) => {
    this.setState({
      add_cookieValue: true
    })
    localStorage.setItem("is_accepted_cookies", "true");
  }

  setProfilePic = async (event: any) => {
    let file = event?.target?.files[0];
    this.setState({ profilePicImgBinary: file });

    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      if (img.name.match(/\.(jpg|jpeg|png|svg)$/)) {
        this.setState({
          profilePicImg: URL.createObjectURL(img)
        });
      } else {

        alert("Selected images are not valid type!")
      }
    }
  };

  /*Change Email logic starts */
  handleEmptyFieldError = () => {
    this.setState({ isFieldEmpty: false });
  }

  handleOldEmailInput = (e: any) => {
    this.setState({ oldEmail: e.target.value });
  }

  handleNewEmailInput = (e: any) => {
    this.setState({ newEmail: e.target.value });
  }

  handleNewEmailConfirmInput = (e: any) => {
    this.setState({ newEmailRepeat: e.target.value });
  }

  handleOldMailError = () => {
    this.setState({ isOldEmailCorrect: false });
  }

  handleNewMailError = () => {
    this.setState({ isEmailError: false });
  }

  handleBothMailsCorrectError = () => {
    this.setState({ areBothMailIdsEqual: false });
  }

  /*Change Email logic End */

  profileImageCrop = (img: any, file: any) => {
    this.setState({ profilePicImg: img });
    this.setState({ profilePicImgBinary: file });
  };

  AddCropImageDailog = () => {
    this.setState({
      isImageDailog: false,
      isImageCropDailog: false,
      isEditImageDailog: false,
    });
  };

  handleDate = (value: string, type: "day" | "month" | "year") => {

  }

  getStyle = (stateValue: any, style1: string, style2: string) => {
    return stateValue ? style1 : style2;
  };

  // Customizable Area End
}
