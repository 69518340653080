// Customizable Area Start
import React from "react";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import UserProfileBasicControllerWeb from "./UserProfileBasicControllerWeb";

export const configJSON = require("./config");
class UserDateOfBirth extends UserProfileBasicControllerWeb {
  constructor(props: any) {
    super(props);
  }
  
  render() {
    return (
      <div className="threeLabelContainer">
        <div className="form-group mb-3 ">
          <DayPicker
            defaultValue={configJSON.labelDay}
            year={this.state.year}
            month={this.state.month}
            endYearGiven
            required={true}
            value={this.props.day as number}
            onChange={(day: any) => {
              this.props.handleDate(day, "day");
            }}
            id={"day"}
            name={"day"}
            classes={`selectControl ${this.props.dayErr && 'selectControlError' }`}
            optionClasses={"optionClasses"}
          />
          {this.props.dayErr ? (
          <p className="date_input_error">{this.props.errorMsg}</p>
          ): null}
        </div>

        <div className="form-group mb-3">
          <MonthPicker
            defaultValue={configJSON.labelMonth}
            endYearGiven
            year={this.state.year}
            required={true}
            value={this.props.month as number}
            onChange={(month: any) => {
              this.props.handleDate(month, "month");
            }}
            id={"month"}
            name={"month"}
            classes={`selectControl ${this.props.monthErr && 'selectControlError' }`}

            optionClasses={"option classes"}
          />
           {this.props.monthErr ? (
          <p  className="date_input_error">{this.props.errorMsg}</p>
          ): null}
        </div>
        <div className="form-group mb-3">
          <YearPicker
            defaultValue={configJSON.labelYear}
            start={1900}
            end ={new Date().getFullYear() -10}
            reverse
            required={true}
            value={this.props.year as number}
            onChange={(year: any) => {
              this.props.handleDate(year, "year");
            }}
            id={"year"}
            name={"year"}
            classes={`selectControl ${this.props.yearErr && 'selectControlError' }`}
            optionClasses={"option-classes"}
          />
          {this.props.yearErr ? (
          <p  className="date_input_error">{this.props.errorMsg}</p>
          ): null}
        </div>
      </div>
    );
  }
}

export default UserDateOfBirth;

 // Customizable Area End
