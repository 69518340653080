import React from "react";
// Customizable Area Start
import {
    Typography,
    Tooltip,
    ClickAwayListener,
} from "@material-ui/core";
import "./ActivityFeed.css";
import { DropDownIcon } from "./assets";
import ActivityFeedController, {
    Props,
} from "./ActivityFeedController";
import ActivityFeedPost from "./ActivityFeedPost.web";
import FeedDropDown from "./FeedDropDown.web";
import InfiniteScroll from "react-infinite-scroll-component";
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import CustomNoResult from "../../../components/src/CustomNoResult";
// Customizable Area End

export default class SavedPosts extends ActivityFeedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start    

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                {/* saved posts {this.state.feedFilter} {this.state.totalCount} {this.state.totalCount / 5} {this.state.currentPage} */}
                {this.state.isLoading && <FullScreenSnippingLoader />}
                <div id="scrollableDiv">
                    <div className="feedFilterDiv w-15" style={{ paddingTop: 35 }}>
                        <ClickAwayListener onClickAway={this.handleTooltipCloseContent}>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                    popperOptions: {
                                        modifiers: {
                                            offset: {
                                                enabled: true,
                                                offset: '40px, 0px',
                                            },
                                        },
                                        tooltipPlacementRight: {
                                            right: "1px",
                                            top: "80px",
                                        },
                                    },
                                }}
                                onClose={this.handleTooltipCloseContent}
                                open={this.state.openPlusDialogContent}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                classes={{
                                    tooltip: "menu-pop-up",
                                    arrow: "menu-pop-up-arrow"
                                }}
                                interactive={true}
                                title={
                                    <React.Fragment>
                                        <FeedDropDown
                                            id=""
                                            navigation={undefined}
                                            feedFilter={localStorage.getItem("savedFeedFilter") || this.state.savedFeedFilter}
                                            handleFeedFilter={this.handleSavedPostsFilter}
                                            feedOptions="savedOptions"
                                        />
                                    </React.Fragment>
                                }
                                arrow={true}
                                placement="right-start"

                            >
                                <div className="parentofFeedFilter" onClick={this.handlePlusContent}>
                                    <p className={`feedFilterText ${this.state.openPlusDialogContent ? 'text-white' : null}`}
                                    >
                                        {/* Below is the Feed Filter Default */}
                                        {localStorage.getItem("savedFeedFilter") || this.state.savedFeedFilter}
                                    </p>
                                    <span className={`${this.state.openPlusDialogContent ? 'feedDropDownIconTransitionOpen' : 'feedDropDownIconTransitionClose'}`}  ><DropDownIcon /></span>
                                </div>
                            </Tooltip>
                        </ClickAwayListener>
                    </div>
                    {(this.state.postsArray.length === 0 && !this.state.isLoading) ?
                        <CustomNoResult text="no saved ideas/iterations yet" />
                        :
                        <InfiniteScroll
                            dataLength={this.state.postsArray.length}
                            next={this.handleFetchMoreData}
                            hasMore={this.state.totalCount / 5 > this.state.currentPage}
                            scrollableTarget="scrollableDiv"
                            endMessage={<Typography></Typography>}
                        >
                            {this.state.postsArray && this.state.postsArray.map((post: any, index) => {
                                return (
                                    <>
                                        {post.attributes?.saved_ideas != null &&
                                            <div style={{ marginTop: "2rem" }} key={post.favouriteable_id}>
                                                <ActivityFeedPost
                                                    id=""
                                                    index={index}
                                                    navigation={null}
                                                    openPlusDialogContent={this.state.openPlusDialogContent}
                                                    handleTooltipCloseContent={this.handleTooltipCloseContent}
                                                    contextImageUpload={this.contextImageUpload}
                                                    handleClickOpen={this.handleClickOpen}
                                                    handlePlusContent={this.handlePlusContent}
                                                    // PostData={post.attributes?.saved_ideas != null ? post.attributes?.saved_ideas?.data : { attributes: { add_ideas: { data: [] } } }}
                                                    PostData={post.attributes?.saved_ideas?.data}
                                                    handleUpdateIdea={this.handleUpdateIdea}
                                                    displayConfirmMsg={this.state.isIdeaUpdated}
                                                    handleSuccessCloseIcon={this.handleSuccessCloseIcon}
                                                    handleUpVote={this.handleUpVote}
                                                    deleteIdea={this.deleteIdea}
                                                    requestSentArray={this.state.requestSentArray}
                                                    handleSendFriendRequest={this.handleSendFriendRequest}
                                                    friendRequestSent={this.friendRequestSent}
                                                    feedFilterChange={this.state.feedFilterChange}
                                                    feedFilter={localStorage.getItem("savedFeedFilter") || this.state.savedFeedFilter}
                                                    feedFilterChangeFalse={this.feedFilterChangeFalse}
                                                    history={this.props.history}
                                                />
                                            </div>


                                        }
                                    </>
                                )
                            })}
                        </InfiniteScroll>
                    }
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
