import React from "react";
// Customizable Area Start
import "./ActivityFeed.css";
//@ts-ignore
import "./../../postcreation/src/AddNewIdea.web.css";
import { ClickAwayListener, Button } from "@material-ui/core";
import { FormGroup, Form, Label } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import {
  Context,
  infoIcon,
  SolutionIcon,
  ProblemIcon,
  crossIcon,
  logoIcon,
  PlusIcon,
  Disapprove,
  Approve,
  Save,
  Share,
  revertIconBlue,
  revertActive,
  ImageLogo,
  VideoLogo,
  HyperlinkLogo,
  notifyOnIcon,
  notifyOffIcon,
  reportIconOFF,
  reportIconON,
  hideIcon,
  viewIcon,
  deleteIcon,
  editIcon
} from "./assets";
import PopUpMenu from "../../postcreation/src/PopUpMenu.web";
import ProtectedRoute from "../../email-account-login/src/ProtectedRoute.web";
import HyperLinkModal from "../../postcreation/src/HyperLinkModal.web";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";
//@ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import moment from 'moment';
let timeToCheck: any;

import ActivityFeedController, { Props } from "./ActivityFeedController";
import OnHoverProfilebox from "./OnHoverProfile.web";
import Alert from "@material-ui/lab/Alert";
import CustomIconButton from "../../../components/src/CustomIconButton";
import { VideoWrapper, ImageWrapper, InputTypeCheckComponent, ResetButton, tooltiptext, ClickToEdit, HyperLinkWrapper, FeedTextBox } from "./ActivityHelpers.web";
import ActivityPopup from "./ActivityPopup.web";
import CustomNoResult from "../../../components/src/CustomNoResult";
import ActivityFeedPrivacy from "./ActivityFeedPrivacy";

let dataPost: any;
let isSharedPost: boolean;
let arr: any;
let sharedRunOnce = 0;
let BreakException = {};
// Customizable Area End
class ActivityFeedPost extends ActivityFeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  factor1 = (updatedIdeas: any, Cid: any) => {
    if (this.isAndStyle(updatedIdeas?.length > 0, !Cid, true, false)) {
      dataPost = updatedIdeas[0].attributes;
      this.setState({ ideaID: updatedIdeas[0].id });
    }
  }

  factor2 = (isParent: boolean) => {
    if (isParent) {
      dataPost = this.props.PostData?.attributes;
      this.setState({ ideaID: this.props.PostData?.id });
    }
  }

  factor3 = (updatedIdeas: any) => {
    if (
      this.isAndStyle(this.props.PostData?.id !== arr[arr.length - 1],
        sharedRunOnce == 0, true, false)
    ) {
      updatedIdeas.forEach((each: any, insideIndex: number) => {
        if (each.id == arr[arr.length - 1]) {
          dataPost = updatedIdeas[insideIndex]?.attributes;
          this.setState({ ideaID: each.id });
        }
      });
    } else if (
      this.isAndStyle(this.props.PostData?.id == arr[arr.length - 1], isSharedPost, true, false)
    ) {
      dataPost = this.props.PostData.attributes;
      this.setState({ ideaID: this.props.PostData?.id });
    }
  }

  AssigningState = (
    Cid: number | null = null,
    isParent: boolean = false,
  ) => {

    this.setState({ isEdited: false });
    const updatedIdeas = this.props.PostData?.attributes?.add_ideas?.data;
    dataPost = this.props.PostData.attributes;

    this.setIdeaID(updatedIdeas?.length, this.props.PostData?.id);

    this.factor1(updatedIdeas, Cid);

    if (this.is3AndStyle(isSharedPost, updatedIdeas?.length > 0, sharedRunOnce == 0, true, false)) {
      this.factor3(updatedIdeas)
    }

    if (this.isAndStyle(Cid, !isParent, true, false)) {
      dataPost = this.props.PostData?.attributes?.add_ideas?.data.find(
        (each: any) => each.id === Cid
      ).attributes;
      this.setState({
        ideaID: this.props.PostData?.attributes?.add_ideas?.data.find(
          (each: any) => each.id === Cid
        ).id,
      });
    }


    if (this.isAndStyle(this.props.feedFilterChange, !["default", "recent", "library", "saved"].includes(this.props.feedFilter || "default"), true, false)) {
      try {
        this.props.PostData?.attributes?.add_ideas?.data?.forEach(
          (each: any, insideIndex: number) => {
            isParent = false;
            if (this.checkFeedType(each.attributes.most_popular, each.attributes.most_promoted, each.attributes.most_contested)) {

              dataPost = updatedIdeas[insideIndex]?.attributes;
              isParent = false;
              this.setState({ ideaID: each.id });

              throw BreakException;
            } else {
              isParent = true;
            }
          }
        );
      } catch {
        console.log('in catch block');
      }
    }

    this.factor2(isParent);

    this.setState(
      {
        ContentText: dataPost?.idea_context?.data?.attributes?.description,
        ProblemText: dataPost?.idea_problem?.data?.attributes?.description,
        SolutionText: dataPost?.idea_solution?.data?.attributes?.description,
        SolutionTextOriginalIdea: dataPost?.idea_solution?.data?.attributes?.description,
        ContextTextOriginalIdea: dataPost?.idea_context?.data?.attributes?.description,
        ProblemTextOriginalIdea: dataPost?.idea_problem?.data?.attributes?.description,
        TitleText: dataPost?.title,
        PrivacySelectAddNewIdea: dataPost?.idea_type,
        postPrivacy: dataPost?.idea_type,
        contextImagePreview: dataPost?.idea_context?.data?.attributes?.image,
        problemImagePreview: dataPost?.idea_problem?.data?.attributes?.image,
        solutionImagePreview: dataPost?.idea_solution?.data?.attributes?.image,
        imageContext: dataPost?.idea_context?.data?.attributes?.image,
        imageProblem: dataPost?.idea_problem?.data?.attributes?.image,
        imageSolution: dataPost?.idea_solution?.data?.attributes?.image,
        ContentTextWithHyperLink: dataPost?.idea_context?.data?.attributes?.url,
        ProblemTextWithHyperLink: dataPost?.idea_problem?.data?.attributes?.url,
        SolutionTextWithHyperLink:
          dataPost?.idea_solution?.data?.attributes?.url,
        URLcontext: dataPost?.idea_context?.data?.attributes?.url,
        URLproblem: dataPost?.idea_problem?.data?.attributes?.url,
        URLsolution: dataPost?.idea_solution?.data?.attributes?.url,
        contextVideoUpload: dataPost?.idea_context?.data?.attributes?.video,
        problemVideoUpload: dataPost?.idea_problem?.data?.attributes?.video,
        solutionVideoUpload: dataPost?.idea_solution?.data?.attributes?.video,
        videoContext: dataPost?.idea_context?.data?.attributes?.video,
        videoProblem: dataPost?.idea_problem?.data?.attributes?.video,
        videoSolution: dataPost?.idea_solution?.data?.attributes?.video,
        profilePicFromBackend:
          dataPost?.profile_details?.data?.attributes?.image,
        userName: dataPost?.profile_details?.data?.attributes?.user_name,
        postTime: dataPost?.created_at,
        firstName: dataPost?.profile_details?.data?.attributes?.first_name,
        lastName: dataPost?.profile_details?.data?.attributes?.last_name,
        isNotify: this.props.PostData?.attributes?.is_notify,
        isHide: dataPost?.is_hidden,
        isReport: dataPost?.is_reported,
        isReportedUser: dataPost?.is_reported_account,
        isHiddenUser: dataPost?.is_hidden_account,
        arrowPosition: 1,
        profileID: dataPost?.profile_details?.data?.id as number,
      },
      () => {
        this.printHashTags();
        this.savedIdeas();
        this.sharedIdeas();
        this.likedIdeas();
        this.dislikedIdeas();
        this.saveIdeaCount(this.state.ideaID);
        this.shareIdeaCount(this.state.ideaID);
        this.upVoteCount(this.state.ideaID);
        this.downVoteCount(this.state.ideaID);
        this.props.feedFilterChangeFalse();
      }
    );
  };

  pathIdentifier = () => {
    arr = window.location.pathname.split("/");
    if (arr.includes("SharePost")) {
      isSharedPost = true;
    } else isSharedPost = false;
  };

  isEdited = (title: "context" | "problem" | "solution") => {
    timeToCheck = setTimeout(() => {
      this.printHashTags();
      if (title === "context") {
        if (
          this.state.ContentText?.length !==
          dataPost?.idea_context?.data?.attributes?.description?.length ||
          this.state.ContentText !==
          dataPost?.idea_context?.data?.attributes?.description
        ) {
          this.setState({
            isEdited: true,
          });
        } else {
          this.setState({
            isEdited: false,
          });
        }
      } else if (title === "problem") {
        if (
          this.state.ProblemText?.length !==
          dataPost.attributes?.idea_problem?.data?.attributes?.description
            ?.length ||
          this.state.ProblemText !==
          dataPost.attributes?.idea_problem?.data?.attributes?.description
        ) {
          this.setState({
            isProblemEdited: true,
          });
        } else {
          this.setState({
            isProblemEdited: false,
          });
        }
      } else if (title === "solution") {
        if (
          this.state.SolutionText?.length !==
          dataPost?.idea_solution?.data?.attributes?.description?.length ||
          this.state.SolutionText !==
          dataPost?.idea_solution?.data?.attributes?.description
        ) {
          this.setState({
            isSolutonEdited: true,
          });
        } else {
          this.setState({
            isSolutonEdited: false,
          });
        }
      }
    }, 1000);
  };

  checkisEdited = () => {
    if (!dataPost) return;

    const {
      image: Simage,
      video: Svideo,
      url: Surl,
    } = dataPost?.idea_solution?.data?.attributes || {};
    const {
      image: Cimage,
      video: Cvideo,
      url: Curl,
    } = dataPost?.idea_context?.data?.attributes || {};
    const {
      image: Pimage,
      video: Pvideo,
      url: Purl,
    } = dataPost?.idea_problem?.data?.attributes || {};
    const {
      solutionImagePreview,
      problemImagePreview,
      contextImagePreview,
      contextVideoUpload,
      problemVideoUpload,
      solutionVideoUpload,
      ContentTextWithHyperLink,
      ProblemTextWithHyperLink,
      SolutionTextWithHyperLink,
    } = this.state;

    contextImagePreview !== Cimage && this.setState({ isEdited: true });
    solutionImagePreview !== Simage && this.setState({ isSolutonEdited: true });
    problemImagePreview !== Pimage && this.setState({ isProblemEdited: true });
    contextVideoUpload !== Cvideo && this.setState({ isEdited: true });
    problemVideoUpload !== Pvideo && this.setState({ isProblemEdited: true });
    solutionVideoUpload !== Svideo && this.setState({ isSolutonEdited: true });
    ContentTextWithHyperLink !== Curl && this.setState({ isEdited: true });
    ProblemTextWithHyperLink !== Purl &&
      this.setState({ isProblemEdited: true });
    SolutionTextWithHyperLink !== Surl &&
      this.setState({ isSolutonEdited: true });
    // PrivacySelectAddNewIdea !== dataPost?.idea_type && this.setState({ isEdited: true })
  };


  problemResetSection() {
    const checkProbvideoOrHyper = this.state.problemVideoUpload ? (
      <ResetButton handleOnClick={this.handleResetProblem} img={VideoLogo} />
    ) : (
      <ResetButton
        handleOnClick={this.handleResetProblem}
        img={HyperlinkLogo}
      />
    );

    const checkProbImgOrOther = this.state.problemImagePreview ? (
      <ResetButton handleOnClick={this.handleResetProblem} img={ImageLogo} />
    ) : (
      checkProbvideoOrHyper
    );

    return this.isCheckStateValue(
      this.state.problemImagePreview,
      this.state.problemVideoUpload,
      this.state.ProblemTextWithHyperLink,
      this.state.problemImagePreview,
      this.state.problemVideoUpload,
      this.state.ProblemTextWithHyperLink
    ) ? (
      <ClickAwayListener data-testid="tooltipProblem" onClickAway={this.handleTooltipCloseProblem}>
        <div className="w-15 plusTooltipoption">
          <Tooltip
            data-testid="tooltipProblemTooltip"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={this.handleTooltipCloseProblem}
            open={this.state.openPlusDialogProblem}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{
              tooltip: "menu-pop-up",
              arrow: "menu-pop-up-arrow",
            }}
            interactive={true}
            title={
              <PopUpMenu
                navigation={undefined}
                id={""}
                contextImageUpload={this.contextImageUpload}
                title={"problem"}
                handleHyperLink={this.handleClickOpen}
                imageCropPopup={this.state.imageCropPopup}
                profileImageCrop={this.profileImageCrop}
                AddCropImageDailog={this.AddCropImageDailog}
                setImageData={this.setImageData}
                ideaPicImageCrop={this.state.ideaPicImageCrop}
              />
            }
            arrow={true}
            placement="right-start"
          >
            <Button
              data-testid="problemPlusBtn"
              style={{ minWidth: 0, padding: 0 }}
              data-title="Change media"
              className={`cursorPointer feedHoverApprove 
                feedVoted`}
              onClick={this.handlePlusProblem}
            >
              <img
                src={PlusIcon}
                className="plus-icon"
                alt="plus-icon"
                onClick={this.handlePlusProblem}
                onKeyPress={this.handlePlusProblem}
              />
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    ) : (
      checkProbImgOrOther
    );
  }

  solutionResetSection() {
    const checkSolvideoOrHyper = this.state.solutionVideoUpload ? (
      <ResetButton handleOnClick={this.handleResetSolution} img={VideoLogo} />
    ) : (
      <ResetButton
        handleOnClick={this.handleResetSolution}
        img={HyperlinkLogo}
      />
    );

    const checkSolImgOrOther = this.state.solutionImagePreview ? (
      <ResetButton handleOnClick={this.handleResetSolution} img={ImageLogo} />
    ) : (
      checkSolvideoOrHyper
    );

    return this.isCheckStateValue(
      this.state.solutionImagePreview,
      this.state.solutionVideoUpload,
      this.state.SolutionTextWithHyperLink,
      this.state.solutionImagePreview,
      this.state.solutionVideoUpload,
      this.state.SolutionTextWithHyperLink
    ) ? (
      <ClickAwayListener data-testid="tooltipSolution" onClickAway={this.handleTooltipCloseSolution}>
        <div className="w-15 plusTooltipoption">
          <Tooltip
            data-testid="tooltipSolutionTooltip"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={this.handleTooltipCloseSolution}
            open={this.state.openPlusDialogSolution}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{
              tooltip: "menu-pop-up",
              arrow: "menu-pop-up-arrow",
            }}
            interactive={true}
            title={
              <PopUpMenu
                navigation={null}
                id={""}
                title="solution"
                contextImageUpload={this.contextImageUpload}
                handleHyperLink={this.handleClickOpen}
                imageCropPopup={this.state.imageCropPopup}
                profileImageCrop={this.profileImageCrop}
                AddCropImageDailog={this.AddCropImageDailog}
                setImageData={this.setImageData}
                ideaPicImageCrop={this.state.ideaPicImageCrop}
              />
            }
            arrow={true}
            placement="right-start"
          >
            <Button
              data-testid="solutionPlusBtn"
              style={{ minWidth: 0, padding: 0 }}
              data-title="Change media"
              onClick={this.handlePlusSolution}
            >
              <img
                src={PlusIcon}
                className="plus-icon"
                alt=""
                onClick={this.handlePlusSolution}
                onKeyPress={this.handlePlusSolution}
              />
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    ) : (
      checkSolImgOrOther
    );
  }

  contextResetSection() {
    const checkContextvideoOrHyper = this.state.contextVideoUpload ? (
      <ResetButton handleOnClick={this.handleResetContext} img={VideoLogo} />
    ) : (
      <ResetButton
        handleOnClick={this.handleResetContext}
        img={HyperlinkLogo}
      />
    );

    const checkContextImgOrOther = this.state.solutionImagePreview ? (
      <ResetButton handleOnClick={this.handleResetContext} img={ImageLogo} />
    ) : (
      checkContextvideoOrHyper
    );

    return this.isCheckStateValue(
      this.state.contextImagePreview,
      this.state.contextVideoUpload,
      this.state.ContentTextWithHyperLink,
      this.state.contextImagePreview,
      this.state.contextVideoUpload,
      this.state.ContentTextWithHyperLink
    ) ? (
      <ClickAwayListener data-testid="tooltipContext" onClickAway={this.handleTooltipCloseContent}>
        <div className="w-15 plusTooltipoption" >
          <Tooltip
            data-testid="tooltipContextTooltip"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={this.handleTooltipCloseContent}
            open={this.state.openPlusDialogContent}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{
              tooltip: "menu-pop-up",
              arrow: "menu-pop-up-arrow",
            }}
            interactive={true}
            title={
              <PopUpMenu
                testId="popupContext"
                navigation={undefined}
                id={""}
                contextImageUpload={this.contextImageUpload}
                title={"context"}
                handleHyperLink={this.handleClickOpen}
                imageCropPopup={this.state.imageCropPopup}
                profileImageCrop={this.profileImageCrop}
                AddCropImageDailog={this.AddCropImageDailog}
                setImageData={this.setImageData}
                ideaPicImageCrop={this.state.ideaPicImageCrop}
              />
            }
            arrow={true}
            placement="right-start"
          >
            <Button
              data-testid="contextPlusBtn"
              onClick={this.handlePlusContent}
              className="cursorPointer feedHoverUndo"
              style={{ minWidth: 0, padding: 0 }}
              data-title="Change media"
            >
              <img
                src={PlusIcon}
                className="plus-icon"
                alt=""
                onClick={this.handlePlusContent}
                onKeyPress={this.handlePlusContent}
              />
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    ) : (
      checkContextImgOrOther
    );
  }

  async componentDidMount() {
    this.pathIdentifier();
    this.AssigningState();
    this.props.friendRequestSent();
    sharedRunOnce++;
  }

  handleUpdateIdeaSubmit = () => {
    this.setState({ isEdited: false });
    this.checkisEdited();
    this.handleIdeaSubmit();
  }


  handleChangeContextTextValue = (e: any) => {
    this.setState(
      { ContentText: e.target.value.substring(0, 440) },
      () => {
        this.ErrorCheckInContent();
        this.isEdited("context");
      }
    );
  }
  handleChangeProblemTextValue = (e: any) => {
    this.setState(
      { ProblemText: e.target.value.substring(0, 440) },
      () => {
        this.ErrorCheckInProblem();
        this.isEdited("problem");
        console.log(
          JSON.stringify(this.state.ProblemText)
        );
      }
    );
  }
  handleChangeSolutionTextValue = (e: any) => {
    this.setState(
      {
        SolutionText: e.target.value.substring(0, 440),
      },
      () => {
        this.ErrorCheckInSolution();
        this.isEdited("solution");
      }
    );
  }
  renderInputTypeCheckComponent(condition: any, text: any) {
    if (condition) {
      return <InputTypeCheckComponent arr={text} />;
    }
    return null;
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    this.VoteCountCheck();
    
    if (this.state.isItrReported) {
      return <CustomNoResult text="you have reported this iteration" img={revertIconBlue} handleOnClick={this.handleReport} />;
    }

    const ideaLength = this.props.PostData?.attributes?.add_ideas?.data?.length;
    const userMail = this.props.PostData?.attributes?.profile_details?.data?.attributes?.email;
    const isDeletableLenGreaterThanZero = this.getValue(ideaLength === 0, true, false);
    const isDeletable = this.getValue(this.state.ideaID == this.props.PostData?.attributes?.add_ideas?.data[0]?.id, true, isDeletableLenGreaterThanZero);
    const postDataAttr = this.props.PostData?.attributes;

    return (
      <ProtectedRoute>
        {!this.state.isLoading && <FullScreenSnippingLoader />}
        <div className="feedPostWrapper">
          <div className={`parentoffeedPrivacyDiv ${this.checkOrgIdea()}`}>

            <Form className="FeedIdeaBox">
              <div className="feedarrow-left">
                <div className="feedprofileImage">
                  <OnHoverProfilebox
                    testId={"removeContactBtn"}
                    formatDate={this.formatDate}
                    profilePicFromBackend={postDataAttr?.profile_details?.data?.attributes?.image}
                    FirstName={postDataAttr?.profile_details?.data?.attributes?.first_name}
                    LastName={postDataAttr?.profile_details?.data?.attributes?.last_name}
                    UserName={postDataAttr?.profile_details?.data?.attributes?.user_name}
                    UserId={postDataAttr?.profile_details?.data?.id}
                    isProfileHover={true}
                    parentID={postDataAttr?.id}
                    id={postDataAttr?.profile_details?.data?.id}
                    IdeaVersionDetails={this.getIdeaVersionDetails(postDataAttr?.add_ideas)}
                    AssigningState={this.AssigningState}
                    sendFriendRequest={this.props.handleSendFriendRequest}
                    toggleFriendText={this.state.toggleFriendText}
                    requestSentArray={this.props.requestSentArray}
                    friendRequestSent={this.friendRequestSent}
                    fulldata={this.props.PostData}
                    handleHoveredText={(contText: string, probText: string, solText: string, hoverValue: boolean) => this.handleHoveredText(contText, probText, solText, hoverValue)}
                    handleShareBtnSubmit={this.handleShareBtnSubmit}
                    handleUpVoteSubmit={this.handleUpVoteSubmit}
                    handleDownVoteSubmit={this.handleDownVoteSubmit}
                    handleSaveClickSubmit={this.handleSaveClickSubmit}
                    shareCount={this.state.shareIdeaCount}
                    saveCount={this.state.saveIdeaCount}
                    upCount={this.state.upVoteCount}
                    downCount={this.state.downVoteCount}
                    handleOrgIdea={this.handleOrgIdeaornot}
                    orgId={this.props.PostData?.attributes?.parent_id}
                    handleTooltipCloseContent={this.handleTooltipCloseContent}
                    openPlusDialogContent={this.state.openPlusDialogContent}
                    handlePlusContent={this.handlePlusContent}
                    handleIterationListFilterText={this.handleIterationListFilterText}
                    iterationListTitle={this.state.iterationListTitle}
                  />
                </div>
              </div>

              {this.handleOrgIdeaText()}

              <FormGroup style={{ paddingLeft: 2 }}>
                <div
                  className="flex-box"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="feedTitle">{this.state.TitleText}</p>
                </div>
                <div className="flex-box" style={{ marginTop: "6px", gridGap: "4px" }}>
                  <OnHoverProfilebox
                    formatDate={this.formatDate}
                    profilePicFromBackend={this.state.profilePicFromBackend}
                    FirstName={this.state.firstName}
                    LastName={this.state.lastName}
                    UserName={this.state.userName}
                    isProfileHover={false}
                    parentID={this.props.PostData?.attributes?.id}
                    id={this.state.profileID}
                    IdeaVersionDetails={this.getValue(this.props.PostData?.attributes?.add_ideas?.data, this.props.PostData?.attributes?.add_ideas?.data, [])}
                    AssigningState={this.AssigningState}
                    sendFriendRequest={this.props.handleSendFriendRequest}
                    toggleFriendText={this.state.toggleFriendText}
                    requestSentArray={this.props.requestSentArray}
                    friendRequestSent={this.friendRequestSent}
                    fulldata={this.props.PostData}
                    handleShareBtnSubmit={this.handleShareBtnSubmit}
                    handleUpVoteSubmit={this.handleUpVoteSubmit}
                    handleDownVoteSubmit={this.handleDownVoteSubmit}
                    handleSaveClickSubmit={this.handleSaveClickSubmit}
                    shareCount={this.state.shareIdeaCount}
                    saveCount={this.state.saveIdeaCount}
                    upCount={this.state.upVoteCount}
                    downCount={this.state.downVoteCount}
                    handleOrgIdea={this.handleOrgIdeaornot}
                    orgId={this.props.PostData?.attributes?.parent_id}
                    isReportedUser={this.state.isReportedUser}
                    isHiddenUser={this.state.isHiddenUser}
                  />
                  <p className="feedDate">
                    | {moment([this.state.postTime], "DD.MM.YYYY, hh:mm Z").format("DD MMM YYYY, hh:mm a")}
                  </p>
                </div>
              </FormGroup>

              {this.handleBlurScreenError(this.state.profileID)}

              {this.state.hashTags?.length > 0 && (
                <div className="flex-box" style={{ marginTop: "4px" }}>
                  <p className="feedHashtag">
                    {this.state.hashTags.map((each: any, index: number) => {
                      return (
                        <span key={each}>
                          {this.hashTagFun(index)}{" "}
                          <span
                            id={`searchByHashtag-${index}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.searchByHashtag(each.slice(1))}
                            onKeyPress={() => this.searchByHashtag(each.slice(1))}
                          >
                            {each}
                          </span>
                        </span>
                      );
                    })}
                  </p>
                </div>
              )}
              {/* Code for Context feed box */}
              <FormGroup>
                <div
                  data-testid="contextinput"
                  style={{ marginTop: "24px", marginBottom: "16px" }}
                  onMouseEnter={this.handleMouseEnterContext}
                  onMouseLeave={this.handleMouseLeaveContext}
                  onClick={this.handleRemoveHoverEffectContext}
                  onKeyPress={this.handleRemoveHoverEffectContext}
                >
                  <div
                    className={`${this.conditionalRenderFun(
                      this.state.ErrorInContent,
                      " postLabelBox Error",
                      "postLabelBox"
                    )} ${this.conditionalRenderFun(
                      this.state.ErrorInContentMedia,
                      " postLabelBox Error",
                      "postLabelBox"
                    )} ${this.is3AndStyle(
                      this.state.isEdited, !this.state.onMouseEnterContext, this.isAndStateStyle(this.state.ErrorInContent, this.state.ErrorInContentMedia),
                      "activeContextBox",
                      ""
                    )} ${this.isAndStyle(
                      !this.state.onMouseEnterContext, this.isAndStateStyle(this.state.ErrorInContent, this.state.ErrorInContentMedia),
                      "activeContextBoxBackcolor",
                      ""
                    )}`}
                    style={this.isCheckOrAndIfRender(
                      this.state.isEdited,
                      this.state.contextImagePreview,
                      this.state.imageContext,
                      this.state.contextVideoUpload,
                      this.state.videoContext,
                      this.state.ContentTextWithHyperLink,
                      this.state.URLcontext
                    )}
                  >
                    <Label className="flex-box">
                      <div
                        className={"img-center"}
                      >
                        <Context />
                      </div>

                      <p className="no-margin postLabelTitle">CONTEXT</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          arrow={true}
                          classes={{ tooltip: "customToolTip" }}
                          title={tooltiptext.content.map((e) => {
                            return (
                              <ul className="toolTipUL" key={e.id}>
                                <li>{e.text}</li>
                              </ul>
                            );
                          })}
                          placement="right"
                        >
                          <img src={infoIcon} alt="" className="info-icon" />
                        </Tooltip>
                      </div>
                    </Label>

                    <ClickToEdit mouseEnter={this.state.onMouseEnterContext} isEdit={this.state.firstClickContext} />

                    {this.isAndStyle(this.state.firstClickContext, !this.state.onMouseEnterContext, true, false) ? (
                      <div style={{ display: "flex" }}>
                        <Button
                          data-testid="undoBtnContext"
                          className="cursorPointer feedHoverUndo"
                          data-title="Undo changes"
                          style={{
                            minWidth: 0,
                            padding: 0,
                            marginRight: "14px",
                            color: "Gray"
                          }}
                          onClick={this.handleUndoChangeContext}
                          onMouseEnter={() => this.setState({ undoBtnContextHover: true })}
                          onMouseLeave={() => this.setState({ undoBtnContextHover: false })}
                        >
                          <img
                            src={this.getValue(this.state.undoBtnContextHover, revertActive, revertIconBlue)}
                            alt=""
                            color="gray"

                          />
                        </Button>
                        <div> {this.contextResetSection()}</div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`${this.isAndStyle(
                      this.state.isEdited, !this.state.onMouseEnterContext,
                      "activeContainer",
                      ""
                    )} ${this.isOrStyle(
                      this.state.isEdited, !this.state.onMouseEnterContext,
                      "activeContextBoxBackcolor",
                      ""
                    )}`}
                    style={this.isCheckOrAndIfRender(
                      this.state.isEdited,
                      this.state.contextImagePreview,
                      this.state.imageContext,
                      this.state.contextVideoUpload,
                      this.state.videoContext,
                      this.state.ContentTextWithHyperLink,
                      this.state.URLcontext
                    )}
                  >
                    <ImageWrapper
                      mainState={this.state.contextImageUploadedbyUser}
                      mainState2={this.state.contextImagePreview}
                      title="context"
                    />
                    <VideoWrapper
                      mainState={this.state.contextVideoUploadedbyUser}
                      mainState2={this.state.contextVideoUpload}
                      title="context"
                    />
                    <HyperLinkWrapper hyperLink={this.state.ContentTextWithHyperLink} type="context" />

                    <FeedTextBox
                      feedType="context"
                      hoverOnIteration={this.state.hoverOnIteration}
                      feedText={this.state.ContentText}
                      hoveredFeedText={this.state.hoveredContextText}
                      dataTestId="contextInputFeild"
                      index={this.props.index}
                      isFeedEdited={this.state.isEdited}
                      onMouseEnterFeed={this.state.onMouseEnterContext}
                      handleChangeFeedTextValue={this.handleChangeContextTextValue}
                    />

                  </div>
                </div>
              </FormGroup>

              {/* Code for problem and solution feed box */}
              <FormGroup className="flex-box justify-content smbottom">
                {/* Code for problem feed box */}
                <div
                  data-testid="problemContainer"
                  className="feedsmaller-input"
                  onMouseEnter={this.handleMouseEnterProblem}
                  onMouseLeave={this.handleMouseLeaveProblem}
                  onClick={this.handleRemoveHoverEffectProblem}
                  onKeyPress={this.handleRemoveHoverEffectProblem}
                >
                  <div
                    className={`${this.conditionalRenderFun(
                      this.state.ErrorInProblem,
                      "postLabelBox Error",
                      "postLabelBox"
                    )} ${this.conditionalRenderFun(
                      this.state.ErrorInProblemMedia,
                      "postLabelBox Error",
                      "postLabelBox"
                    )}  ${this.is3AndStyle(
                      this.state.isProblemEdited,
                      !this.state.onMouseEnterProblem, this.isAndStateStyle(this.state.ErrorInProblem, this.state.ErrorInProblemMedia),
                      "activeContextBox",
                      ""
                    )}  ${this.isAndStyle(
                      !this.state.onMouseEnterProblem, this.isAndStateStyle(this.state.ErrorInProblem, this.state.ErrorInProblemMedia),
                      "activeContextBoxBackcolor",
                      ""
                    )}`}
                    style={this.isCheckOrAndIfRender(
                      this.state.isProblemEdited,
                      this.state.problemImagePreview,
                      this.state.imageProblem,
                      this.state.problemVideoUpload,
                      this.state.videoProblem,
                      this.state.ProblemTextWithHyperLink,
                      this.state.URLproblem
                    )}
                  >
                    <Label className="flex-box">
                      <div className={"img-center"}>
                        <ProblemIcon />
                      </div>

                      <p className="no-margin postLabelTitle">PROBLEM</p>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          arrow={true}
                          classes={{ tooltip: "customToolTip" }}
                          title={tooltiptext.problem.map((e) => {
                            return (
                              <ul className="toolTipUL" key={e.id}>
                                <li>{e.text}</li>
                              </ul>
                            );
                          })}
                          placement="right-start"
                        >
                          <img src={infoIcon} alt="" className="info-icon" />
                        </Tooltip>
                      </Grid>
                    </Label>
                    <ClickToEdit mouseEnter={this.state.onMouseEnterProblem} isEdit={this.state.firstClickProblem} />
                    {this.isAndStyle(this.state.firstClickProblem, !this.state.onMouseEnterProblem, true, false) ? (
                      <div style={{ display: "flex" }}>
                        <Button
                          data-testid="undoBtnProblem"
                          className="cursorPointer feedHoverUndo"
                          style={{
                            minWidth: 0,
                            padding: 0,
                            marginRight: "14px",
                          }}
                          data-title="Undo changes"
                          onClick={this.handleUndoChangeProblem}
                          onMouseEnter={() => this.setState({ undoBtnProblemHover: true })}
                          onMouseLeave={() => this.setState({ undoBtnProblemHover: false })}
                        >
                          <img
                            src={this.getValue(this.state.undoBtnProblemHover, revertActive, revertIconBlue)}
                            className="plus-icon"
                            alt=""
                          />
                        </Button>
                        {this.problemResetSection()}
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`${this.isAndStyle(
                      this.state.isProblemEdited,
                      !this.state.onMouseEnterProblem,
                      "activeContainer",
                      ""
                    )} `}
                    style={this.isCheckOrAndIfRender(
                      this.state.isProblemEdited,
                      this.state.problemImagePreview,
                      this.state.imageProblem,
                      this.state.problemVideoUpload,
                      this.state.videoProblem,
                      this.state.ProblemTextWithHyperLink,
                      this.state.URLproblem
                    )}
                  >
                    <ImageWrapper
                      mainState={this.state.problemImageUploadedbyUser}
                      mainState2={this.state.problemImagePreview}
                      title="problem"
                    />
                    <VideoWrapper
                      mainState={this.state.problemVideoUploadedbyUser}
                      mainState2={this.state.problemVideoUpload}
                      title="problem"
                    />

                    <HyperLinkWrapper hyperLink={this.state.ProblemTextWithHyperLink} type="problem" />

                    <FeedTextBox
                      feedType="problem"
                      hoverOnIteration={this.state.hoverOnIteration}
                      feedText={this.state.ProblemText}
                      hoveredFeedText={this.state.hoveredProblemText}
                      dataTestId="problemInputFeild"
                      index={this.props.index}
                      isFeedEdited={this.state.isProblemEdited}
                      onMouseEnterFeed={this.state.onMouseEnterProblem}
                      handleChangeFeedTextValue={this.handleChangeProblemTextValue}
                    />

                  </div>
                </div>

                {/* Code for solution feed box */}
                <div
                  data-testid="solutionContainer"
                  className="feedsmaller-input"
                  onMouseEnter={this.handleMouseEnterSolution}
                  onMouseLeave={this.handleMouseLeaveSolution}
                  onClick={this.handleRemoveHoverEffectSolution}
                  onKeyDown={this.handleRemoveHoverEffectSolution}
                >
                  <div
                    className={`${this.conditionalRenderFun(
                      this.state.ErrorInSolution,
                      " postLabelBox Error",
                      "postLabelBox"
                    )}  ${this.conditionalRenderFun(
                      this.state.ErrorInSolutionMedia,
                      " postLabelBox Error",
                      "postLabelBox"
                    )} ${this.is3AndStyle(
                      this.state.isSolutonEdited,
                      !this.state.onMouseEnterSolution, this.isAndStateStyle(this.state.ErrorInSolution, this.state.ErrorInSolutionMedia),
                      "activeContextBox",
                      ""
                    )}  ${this.isAndStyle(
                      !this.state.onMouseEnterSolution, this.isAndStateStyle(this.state.ErrorInSolution, this.state.ErrorInSolutionMedia),
                      "activeContextBoxBackcolor",
                      ""
                    )}`}
                    style={this.isCheckOrAndIfRender(
                      this.state.isSolutonEdited,
                      this.state.solutionImagePreview,
                      this.state.imageSolution,
                      this.state.solutionVideoUpload,
                      this.state.videoSolution,
                      this.state.SolutionTextWithHyperLink,
                      this.state.URLsolution
                    )}
                  >
                    <Label className="flex-box">
                      <div className={"img-center"}>
                        <SolutionIcon />
                      </div>
                      <p className="no-margin postLabelTitle">SOLUTION</p>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          className="tooltip"
                          classes={{ tooltip: "customToolTip" }}
                          arrow={true}
                          title={tooltiptext.solution.map((e) => {
                            return (
                              <ul className="toolTipUL" key={e.id}>
                                <li>{e.text}</li>
                              </ul>
                            );
                          })}
                          placement="right-start"
                        >
                          <img src={infoIcon} alt="" className="info-icon" />
                        </Tooltip>
                      </Grid>

                      <div />
                    </Label>
                    <ClickToEdit mouseEnter={this.state.onMouseEnterSolution} isEdit={this.state.firstClickSolution} />
                    {this.isAndStyle(this.state.firstClickSolution, !this.state.onMouseEnterSolution, true, false) ? (

                      <div style={{ display: "flex" }}>
                        <Button
                          data-testid="undoBtnSolution"
                          className={`cursorPointer feedHoverUndo `}
                          style={{
                            minWidth: 0,
                            padding: 0,
                            marginRight: "14px"
                          }}
                          data-title="Undo changes"
                          onClick={this.handleUndoChangeSolution}
                          onMouseEnter={() => this.setState({ undoBtnSolutionHover: true })}
                          onMouseLeave={() => this.setState({ undoBtnSolutionHover: false })}
                        >
                          <img
                            src={this.getValue(this.state.undoBtnSolutionHover, revertActive, revertIconBlue)}
                            className="plus-icon"
                            alt=""
                          />
                        </Button>


                        {this.solutionResetSection()}
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`${this.isAndStyle(
                      this.state.isSolutonEdited,
                      !this.state.onMouseEnterSolution,
                      "activeContainer",
                      ""
                    )} `}
                  >
                    <ImageWrapper
                      mainState={this.state.solutionImageUploadedbyUser}
                      mainState2={this.state.solutionImagePreview}
                      title="solution"
                    />
                    <VideoWrapper
                      mainState={this.state.solutionVideoUploadedbyUser}
                      mainState2={this.state.solutionVideoUpload}
                      title="solution"
                    />

                    <HyperLinkWrapper hyperLink={this.state.SolutionTextWithHyperLink} type="solution" />

                    <FeedTextBox
                      feedType="solution"
                      hoverOnIteration={this.state.hoverOnIteration}
                      feedText={this.state.SolutionText}
                      hoveredFeedText={this.state.hoveredSolutionText}
                      dataTestId="solutionInputFeild"
                      index={this.props.index}
                      isFeedEdited={this.state.isSolutonEdited}
                      onMouseEnterFeed={this.state.onMouseEnterSolution}
                      handleChangeFeedTextValue={this.handleChangeSolutionTextValue}
                    />
                  </div>
                </div>
              </FormGroup>

              <div className="CustomErrorOverwrite">
                <CustomErrorAlert
                  isError={this.state.openSnackBarError}
                  errorMsg="Text cannot contain any of the following characters: { } [ ] | "
                  closeBtnDisabled={true}
                />
                <CustomErrorAlert
                  isError={this.state.totalFileError}
                  errorMsg={"Combined file size should be less than 50MB"}
                  closeBtnDisabled={true}
                />
                <CustomErrorAlert
                  isError={this.state.ExtentionError}
                  errorMsg={
                    "Only files with the following extensions are allowed: .png, .gif, .jpg, .jpeg, .mp4"
                  }
                  closeBtnDisabled={true}
                />
              </div>
              <div className="flex-box">
                {!this.isCheckSubmitBtnShow() ? (
                  <div className="feedApproveDisapproveGroup">
                    <span
                      style={{
                        fontSize: '13px',
                        width:
                          ((Math.log10(
                            (Number(this.state.upVoteCount) ^
                              (Number(this.state.upVoteCount) >> 31)) -
                            (Number(this.state.upVoteCount) >> 31)
                          ) |
                            0) +
                            1) *
                          9,
                      }}
                    >
                      {this.state.upVoteCount}
                    </span>
                    <Button
                      style={{ minWidth: 0, padding: 0 }}
                      data-title="Approve"
                      className={`cursorPointer feedHoverApprove ${this.conditionalRenderFun(
                        this.state.likedIdeas.includes(
                          Number(this.state.ideaID)
                        ),
                        "feedVoted",
                        ""
                      )}`}
                      onClick={this.handleUpVoteSubmit}
                    >
                      <Approve />
                    </Button>
                    <div className="vertical-line" ></div>
                    <Button
                      style={{ minWidth: 0, padding: 0 }}
                      data-title="Disapprove"
                      className={`cursorPointer feedHoverDisapprove ${this.conditionalRenderFun(
                        this.state.dislikedIdeas.includes(
                          Number(this.state.ideaID)
                        ),
                        "feedVoted",
                        ""
                      )}`}
                      onClick={this.handleDownVoteSubmit}
                    >
                      <Disapprove />
                    </Button>
                    <span
                      style={{
                        fontSize: '13px',
                        width:
                          ((Math.log10(
                            (Number(this.state.downVoteCount) ^
                              (Number(this.state.downVoteCount) >> 31)) -
                            (Number(this.state.downVoteCount) >> 31)
                          ) |
                            0) +
                            1) *
                          9,
                      }}
                    >
                      {this.state.downVoteCount}
                    </span>
                  </div>
                ) : null}

                <div className="center">
                  {/* Use Avatar can be implemented for below */}

                  {this.isCheckSubmitBtnShow() ? (
                    <>
                      <Button onClick={this.disCardChangeFunction} style={{ width: "26.67px", height: "26.67px", marginRight: "18.17px" }}>
                        <img
                          src={crossIcon}
                          alt=""
                          className="Feed-footer-icon discard-footer-icon"
                          title="Discard changes"
                        />
                      </Button>
                      <Button
                        id="submitBtn"
                        onClick={this.handleUpdateIdeaSubmit}
                        style={{ width: "26.67px", height: "26.67px" }}
                      >
                        <img
                          src={logoIcon}
                          alt=""
                          className="Feed-footer-icon submit-footer-icon cursorPointer"
                          title="Submit"
                        />
                      </Button>
                    </>
                  ) : null}
                </div>
                {!this.isCheckSubmitBtnShow() ? (
                  <div className="feedShareSaveGroup">
                    <span
                      style={{
                        fontSize: '13px',
                        width:
                          ((Math.log10(
                            (Number(this.state.saveIdeaCount) ^
                              (Number(this.state.saveIdeaCount) >> 31)) -
                            (Number(this.state.saveIdeaCount) >> 31)
                          ) |
                            0) +
                            1) *
                          9,
                      }}
                    >
                      {this.state.saveIdeaCount}
                    </span>
                    <span
                      className="feedShare feedSaved"
                    >
                      <Tooltip
                        open={this.state.isSaveBtnClicked}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        classes={{
                          tooltip: "menu-pop-up",
                          arrow: "menu-pop-up-arrow",
                        }}
                        PopperProps={{
                          disablePortal: true,
                          popperOptions: {
                            modifiers: {
                              offset: {
                                enabled: true,
                                offset: "18px, 10px",
                              },
                            },
                          },
                        }}
                        interactive={true}
                        title={
                          <Alert severity="success">
                            <span>
                              {this.conditionalRenderFun(
                                this.state.savedIdeas.includes(
                                  Number(this.state.ideaID)
                                ),
                                "Saved to Ideas",
                                "UnSaved from Ideas"
                              )}</span>
                          </Alert>
                        }
                        placement="bottom"
                      >
                        <Button
                          test-dataid="saveBtn"
                          data-title={this.getValue(!this.state.isSaveBtnClicked, "Save to Ideas", null)}
                          style={{ minWidth: 0, padding: 0 }}
                          className={`cursorPointer feedHoverSave ${this.conditionalRenderFun(
                            this.state.savedIdeas.includes(
                              Number(this.state.ideaID)
                            ),
                            "feedSave",
                            ""
                          )}`}
                          onClick={this.handleSaveClickSubmit}
                        >
                          <Save />
                        </Button>
                      </Tooltip>
                    </span>

                    <div className="vertical-line" ></div>
                    <span className="feedShare">
                      <Tooltip
                        open={this.state.isShareBtnClicked}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        classes={{
                          tooltip: "menu-pop-up",
                          arrow: "menu-pop-up-arrow",
                        }}
                        PopperProps={{
                          disablePortal: true,
                          popperOptions: {
                            modifiers: {
                              offset: {
                                enabled: true,
                                offset: "13px, 12px",
                              },
                            },
                          },
                        }}
                        interactive={true}
                        title={
                          <Alert severity="success">
                            <span>Copied to Clipboard</span>
                          </Alert>
                        }
                        placement="bottom"
                      >
                        <Button
                          id="shareBtn"
                          data-title={this.getValue(!this.state.isShareBtnClicked, "Copy to Clipboard", null)}
                          style={{ minWidth: 0, padding: 0 }}
                          className={`cursorPointer ShareIcon ${this.conditionalRenderFun(
                            this.state.sharedIdeas.includes(
                              Number(this.state.ideaID)
                            ),
                            "AlreadyShareIcon",
                            null
                          )}`}
                          onClick={this.handleShareBtnSubmit}
                        >
                          <Share />
                        </Button>
                      </Tooltip>
                    </span>
                    <span
                      style={{
                        fontSize: '13px',
                        width:
                          ((Math.log10(
                            (Number(this.state.shareIdeaCount) ^
                              (Number(this.state.shareIdeaCount) >> 31)) -
                            (Number(this.state.shareIdeaCount) >> 31)
                          ) |
                            0) +
                            1) *
                          9,
                      }}
                    >
                      {this.state.shareIdeaCount}
                    </span>
                  </div>
                ) : null}
              </div>
            </Form>
          </div>
          <div className="feedSideIcons">
            {this.isItOtherUser(isDeletable) && (
              <div data-testid="privacyBtn" onMouseEnter={() => this.handlePrivacyOpen(userMail, ideaLength)}>
                <ActivityFeedPrivacy
                  open={this.state.openPrivacyDialog}
                  onClose={this.handlePrivacyPoupFun}
                  handlePrivacyDropDown={this.handlePrivacyDropDown}
                  PrivacySelectAddNewIdea={this.state.PrivacySelectAddNewIdea}
                  handlePrivacyOpen={() => this.handlePrivacyOpen(userMail, ideaLength)}
                  userMail={userMail}
                />
              </div>
            )}

            {!this.isItOtherUser(isDeletable) && (
              <>
                <CustomIconButton
                  testId="notification"
                  handleOnClick={this.handleNotifications}
                  handleOnMouseEnter={this.onMouseEnterNotificationBtn}
                  handleOnMouseLeave={this.onMouseLeaveNotificationBtn}
                  buttonIcon={this.getValue(this.state.isNotify, notifyOnIcon, notifyOffIcon)}
                  hoverEnabled={this.state.hoverNotify}
                  hoverText={this.getValue(this.state.isNotify, "Notifications ON", "Notifications OFF")}
                />

                <CustomIconButton
                  testId="showItrBtn"
                  handleOnClick={this.handleShowHideItr}
                  handleOnMouseEnter={this.onMouseEnterVisibility}
                  handleOnMouseLeave={this.onMounseLeaveVisibility}
                  buttonIcon={this.getValue(this.state.isHide, hideIcon, viewIcon)}
                  hoverEnabled={this.state.hoverVisibility}
                  hoverText={this.getValue(this.state.isHide, "Show iteration OFF", "Show iteration ON")}
                />

                <CustomIconButton
                  testId="reportItrBtn"
                  handleOnClick={this.handleShowReportItr}
                  handleOnMouseEnter={this.onMouseEnterReportIterationBtn}
                  handleOnMouseLeave={this.onMouseLeaveReportIterationBtn}
                  buttonIcon={this.getValue(this.state.isReport, reportIconON, reportIconOFF)}
                  hoverEnabled={this.state.hoverReport}
                  hoverText={this.getValue(this.state.isReport, "Report iteration ON", "Report iteration OFF")}
                />
              </>
            )}

            {this.isItOtherUser(isDeletable) && (
              <>
                <CustomIconButton
                  testId="editItrBtn"
                  classNameStyle="firstCustomIconBtn editItr"
                  handleOnClick={this.handleEditIterationFn}
                  handleOnMouseEnter={this.onHoverEditBtn}
                  handleOnMouseLeave={this.onMouseLeaveEditBtn}
                  buttonIcon={editIcon}
                  hoverEnabled={this.state.hoverEdit}
                  hoverText="Edit iteration"
                />
                <CustomIconButton
                  testId={"deleteItrBtn"}
                  handleOnClick={this.handleDeleteIterationPopupFn}
                  handleOnMouseEnter={this.onHoverDeleteBtn}
                  handleOnMouseLeave={this.onMouseLeaveDeleteBtn}
                  buttonIcon={deleteIcon}
                  hoverEnabled={this.state.hoverDelete}
                  hoverText="Delete iteration"
                />
              </>
            )}

          </div>
        </div>

        {/* Model for HyperLink */}

        {this.state.openHyperLinkDialoge && (
          <HyperLinkModal
            handleClose={this.handleClose}
            handleClickOpen={this.handleClickOpen}
            submitHyperLink={this.submitHyperLink}
            title={this.state.HyperLinkModalTitle}
            hyperLinkError={this.state.hyperLinkError}
            onHyperlinkErrorClose={this.onHyperlinkErrorClose}
          />
        )}

        <ActivityPopup
          testId="submitpopup"
          open={this.state.isIdeaUpdated}
          title={"IDEA ITERATION SUBMITTED"}
          popupDescription={null}
          leftBtnTxt={null}
          rightBtnTxt={null}
          handleLeftBtn={this.ideaSubmittedPopupCloseFun}
        />

        <ActivityPopup
          testId="discardpopup"
          open={this.state.DiscardChangeVal}
          title={"DISCARD RECENT CHANGES?"}
          popupDescription={
            "Please confirm that you wish to discard recent changes."
          }
          leftBtnTxt={"Cancel"}
          rightBtnTxt={"discard"}
          handleLeftBtn={this.handleDiscardCancelPopup}
          handleRightBtn={this.handleDiscardChangePopup}
        />

        <ActivityPopup
          testId="HidePopup"
          open={this.state.showhideItrPopup}
          title="HIDE ITERATION"
          popupDescription="Please confirm that you wish to hide this iteration."
          leftBtnTxt="Cancel"
          rightBtnTxt="ok"
          handleLeftBtn={this.handleCancelHideItrPopup}
          handleRightBtn={this.handleSubmitHideItrPopup}
        />

        <ActivityPopup
          testId="ReportPopup"
          open={this.state.showReportPopup}
          title="REPORT ITERATION"
          popupDescription="Please confirm that you wish to report this iteration."
          leftBtnTxt="Cancel"
          rightBtnTxt="ok"
          handleLeftBtn={this.handleCancelReportPopup}
          handleRightBtn={this.handleSubmitReportPopup}
        />

        <ActivityPopup
          testId="deletePopup"
          open={this.state.showDeletePopup}
          title="PERMANENTLY Delete iteration?"
          popupDescription="Please consider editing your last iteration instead."
          leftBtnTxt="Cancel"
          rightBtnTxt="delete"
          handleLeftBtn={this.handleCancelDeletePopup}
          handleRightBtn={this.handleDeletePopup}
        />

      </ProtectedRoute>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

//@ts-ignore
export default ActivityFeedPost;
export { ActivityFeedPost }
// Customizable Area End