// Customizable Area Start
import React, { useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { LockIcon, WorldIcon, swatIcons, EditOnHoverIcon, GroupIcon, swatDeactivateIcons } from "./assets"
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";
import { Box, Button, TextField } from "@material-ui/core";
import ActivityText from "./ActivityText";

export const tooltiptext = {
    content: [
        {
            id: 1,
            text:
                "Where and when did you encounter the problem you are trying to solve here? Explain the situation as clearly as you can.",
        },
        {
            id: 2,
            text:
                "It might be useful to attach a photo, map, graph, or video to provide useful insights or an article to provide background information.",
        },
    ],
    problem: [
        {
            id: 1,
            text:
                "What is the problem and why did you encounter it? Try to estimate the impact or frequency of the issue to be solved. (Also explain if it might be urgent or exceptionally important)",
        },
        {
            id: 2,
            text:
                "Alternatively, describe the value of a new opportunity you found and elucidate the specifics of the challenge you are facing.",
        },
    ],
    solution: [
        {
            id: 1,
            text:
                "Which solution would you implement and how would you approach it? Outline the best scenario or your first attempt at developing one.",
        },
        {
            id: 2,
            text:
                "Be specific and constructive in your framing of the solution. Try to identify the key stakeholders and partners your solution requires to work well.",
        },
    ],
};


export const InputTypeCheckComponent = (props: any) => {
    return (
        props.arr?.replaceAll("\r\n", "\n").length ?
            <InputAdornment position="end" className="text-field-Adornment">{props.arr?.replaceAll("\r\n", "\n").length}/440</InputAdornment>
            : <InputAdornment position="end" className="text-field-Adornment" style={{ opacity: 0 }}>{props.arr?.replaceAll("\r\n", "\n").length}/440</InputAdornment>
    )
}

export const ImageWrapper = (props: any) => {
    const ImgUrl = props.mainState2 ? <img src={baseURL + props.mainState2} className={props.title === "context" ? "previewImageContext" : "previewImage"} /> : null;
    return (
        props.mainState ? <img src={props.mainState2} className={props.title === "context" ? "previewImageContext" : "previewImage"} /> : ImgUrl
    )
}

export const VideoWrapper = (props: any) => {
    const baseURLTag = props.mainState2 ?
        <video width="100%" height={props.title === "context" ? "210px" : "118px"} controls>
            <source src={baseURL + props.mainState2} type="video/mp4" />
            Your browser does not support the video tag.
        </video> : null

    return (
        props.mainState ?
            <video width="100%" height={props.title === "context" ? "210px" : "118px"} controls>
                <source src={window.URL.createObjectURL(props.mainState2)} type="video/mp4" />
                Your browser does not support the video tag.
            </video> : baseURLTag

    )
};

export const HyperLinkWrapper = (props: any) => {
    return (
        props.hyperLink ?
            <iframe
                width="100%"
                height={props.type === "context" ? "210" : "120"}
                src={`${props.hyperLink}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Attached HyperLink"
            /> : <></>
    )
}

export const IsConditionalRender = (props: any) => {
    switch (props.stateValue) {
        case "private":
            return <LockIcon />
        case "public":
            return <WorldIcon />
        default:
            return <GroupIcon />
    }

};

export const ResetButton = (props: any) => {
    const [hover, setHover] = useState(false)
    return (
        <Button
            style={{ minWidth: 0, padding: 0 }}
            onClick={props.handleOnClick}
            data-title="Change media"
            test-dataid="changemedia"
            className="cursorPointer feedHoverUndo"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <img
                src={hover ? swatIcons : swatDeactivateIcons}
                className="plus-icon"
                alt="Reset"
                data-title="Change media"
                onClick={props.handleOnClick}
                onKeyDown={props.handleOnClick}
            />
        </Button>
    )
}
// Define helper functions

export const ClickToEdit = (props: any) => {
    return (
        <>
            {!props.mouseEnter && !props.isEdit ? <div>
                <div className="onHoverClicktoEditTestContainer">
                    <EditOnHoverIcon />
                    <p className="onHoverClicktoEditTest">
                        Click to Edit
                    </p>
                </div>
            </div> : <></>
            }
        </>
    )
}

export const BlurPost = (props: any) => {
    return (
        <>
            <div className="hideBlurLayer"></div>
            <div className="onBlurTextField">
                <span className={`blurMsg ${props.reasonStyle}`}>
                    {props.reasonText}
                </span>
                <Button
                    id="blurpost"
                    className={"feedUndoBtn"}
                    variant="outlined"
                    onClick={props.handleButtonClick}
                >
                    <img src={props.buttonIcon} alt="reportIcon" />
                    <p className="feedUndoText">{props.buttonText}</p>
                </Button>
            </div>
        </>
    )
}


export const FeedTextBox = (props: any) => {
    const isOrStyle = (state1: boolean, state2: boolean, style1: string, style2: string) => {
        return state1 || state2 ? style1 : style2
    }
    return (
        <Box className="mainFeedContent">
            {props.hoverOnIteration ?
                <div className={`colorText ${props.feedType != "context" ? "probSolWidth" : ""}`}>
                    <ActivityText inputA={props.feedText} inputB={props.hoveredFeedText} />
                </div>
                :
                <TextField
                    data-testid={props.dataTestId}
                    id={`${props.feedType}-${props.index}`}
                    minRows={1}
                    className={`form-control-feed ${isOrStyle(
                        props.isFeedEdited,
                        !props.onMouseEnterFeed,
                        "activeContextBoxBackcolor",
                        ""
                    )}`}
                    classes={{
                        root: "height-auto feedContent",
                    }}
                    onChange={props.handleChangeFeedTextValue}
                    value={props.feedText.replace(/(\r\n|\n|\r)/gm, "").replace(/ +(?= )/g, '')}
                    multiline
                    placeholder={`Type ${props.feedType} here...`}
                //For Input feed count
                // InputProps={{
                //   endAdornment: (
                //     <>
                //       {this.renderInputTypeCheckComponent(props.isFeedEdited, props.feedText)}
                //     </>
                //   ),
                // }}
                />
            }
        </Box>
    )
}

// Customizable Area End