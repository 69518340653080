// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  location: any;
}

interface S {
  email: any;
  password: string;
  checkedRememberMe: boolean;
  isLoginError: boolean;
  isEmailError: boolean;
  isPasswordError: boolean;
  LoginErrorMsg: string;
  isLoading: boolean;
  openModal: boolean;
  forgotPasswordEmail: string;
  forgotPasswordSubmited: boolean;
  forgotEmailError: boolean;
  forgetPasswordApiErrMsg: string;
}

interface SS {
  id: any;
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiEmailLoginCallId: string = "";
  labelTitle: string = "";
  forgotPasswordCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      checkedRememberMe: false,
      isLoginError: false,
      isEmailError: false,
      isPasswordError: false,
      LoginErrorMsg: "",
      isLoading: false,
      openModal: false,
      forgotPasswordEmail: "",
      forgotPasswordSubmited: false,
      forgotEmailError: false,
      forgetPasswordApiErrMsg: "",
    };
    this.labelTitle = configJSON.labelTitle;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const checkedRememberMe =
      localStorage.getItem("checkedRememberMe") === "true";
    const loginCredentials = localStorage.getItem('loginCredentials');
    if (loginCredentials) {
      const { email, password } = JSON.parse(loginCredentials);
    this.setState({ email: email,password:password, checkedRememberMe: checkedRememberMe });
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (!apiRequestCallId && !responseJson) {
      return;
    }

    if (this.isMessageIDisEqual(apiRequestCallId, this.apiEmailLoginCallId)) {
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.setState({
          isLoading: false,
        });
        this.saveLoggedInUserData(responseJson);
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        localStorage.setItem("email", responseJson.meta.email);
        localStorage.setItem("username", responseJson.meta.user_name);
        localStorage.setItem("profilepic", responseJson.meta.profile);
        localStorage.setItem("anonymousProfilepic", responseJson.meta.anonymous_image);
        localStorage.setItem("anonymousId", responseJson.meta.anonymous_id);
         this.prevNavigateFun()
      } else {
        this.setState({
          isLoading: false,
        });

        //Below Logic Will show
        if (responseJson?.errors)
          this.handleErrorValue(responseJson.errors[0].failed_login);
        else this.showAlert("Failed", "Something went Wrong!");
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  prevNavigateFun = () =>{
    let isPrevRoute = localStorage.getItem("prevRoute");
    if(isPrevRoute && isPrevRoute.includes("SharePost")){
     localStorage.removeItem("prevRoute");
     this.props.navigation.goBack();
    }
    else{
     this.props.navigation.navigate("ActivityFeed");
    }
  }
  isMessageIDisEqual(apimethodId: any, callResponseId: any) {
    return apimethodId === callResponseId;
  }

  handleEmailChange = (e: any) => {
    this.setState({ email: e.target.value });
  };
  handlePasswordChange = (e: any) => {
    this.setState({ password: e.target.value });
  };
  handleRememberMeValue = () =>
    this.setState({
      checkedRememberMe: !this.state.checkedRememberMe,
    });

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  handleCloseError = () => {
    this.setState({
      isLoginError: false,
      isEmailError: false,
      isPasswordError: false,
      forgotEmailError: false,
    });
  };

  handleNullFields = () => {
    this.setState({
      isLoginError: true,
      LoginErrorMsg: configJSON.errorAllFieldsAreMandatory,
    });
  };
  handleEmailError = () => {
    this.setState({
      isEmailError: false,
    });
  };

  handlePasswordError = () => {
    this.setState({
      isPasswordError: false,
    });
  };
  handleErrorValue = (errorMsg: string) => {
    this.setState({
      isLoginError: true,
      LoginErrorMsg: errorMsg,
    });
  };

  handleSubmit = () => {
    this.doEmailLogIn();
  };

  doEmailLogIn(): boolean {
    const { email, password, checkedRememberMe } = this.state;

    if (this.isStringNullOrBlank(email) || this.isStringNullOrBlank(password)) {
      this.setState({
        isLoginError: true,
        LoginErrorMsg: configJSON.errorAllFieldsAreMandatory,
      });
      return false;
    }

    localStorage.setItem("checkedRememberMe", checkedRememberMe.toString());
    localStorage.setItem("email", checkedRememberMe ? email : "");
    if (checkedRememberMe) {
      localStorage.setItem('loginCredentials', JSON.stringify({ email, password }));
    }

    this.setState({
      isLoading: true,
      isEmailError: false,
      isPasswordError: false,
    });

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      Connection: "keep-alive",
      "Access-Control-Allow-Origin": "*",
    };

    const data = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password,
          remember_me: this.state.checkedRememberMe,
        },
      },
    };

    const httpBody = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),      
      configJSON.loginAPiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
}
// Customizable Area End
