//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Tooltip, withStyles, ClickAwayListener } from "@material-ui/core";
import "../../postcreation/src/PopUpMenu.web.css";
import "./ActivityFeed.css";
import {
  AddContactIcon,
  DropDownIcon,
  Search,
  RemoveContact,
  noProfileImg,
  moreIcon,
  hiddenEye,
  redReportIcon,
  upArrow,
  downArrow,
  shareIcon,
  saveIcon,
} from "./assets";
import { baseURL } from "../../../framework/src/config.js";
import FeedDropDown from "./FeedDropDown.web";
const configJSON = require("./config.js");

// Customizable Area End

const OnHoverProfileBoxToolTip = withStyles({
  tooltip: {
    backgroundColor: "transparent !important",
  },
  arrow: {
    "&:before": {
      backgroundColor: "#0F2C40 !important",
    },
  },
})(Tooltip);

let isTheProfileSame: boolean;
const OnHoverProfilebox = (props: any) => {
  // Customizable Area Start
  const [currentView, setCurrentView] = useState(false);
  const [currentViewIndex, setCurrentViewIndex] = useState();
  const [originalCurrentViewIndex, setOriginalCurrentViewIndex] =
    useState(false);
  const [showIterationList, setShowIteartionList] = useState(false);
  const [upVoteCount, setUpVoteCount] = useState(false);
  const [downVoteCount, setDownVoteCount] = useState(false);
  const [shareVoteCount, setShareVoteCount] = useState(false);
  const [saveVoteCount, setSaveVoteCount] = useState(false);
  const [cursorNone, setCursorNone] = useState(false)
  const wrapperRef = useRef(null);
  const absoluteRef = useRef(null);
  useOutsideAlerter(wrapperRef, absoluteRef);
  const userId = (index) =>
    props.IdeaVersionDetails[index]?.attributes?.profile_details?.data?.id ||
    props.id;
  const profileImage = (item) =>
    item?.attributes?.profile_details?.data?.attributes?.image
      ? baseURL + item?.attributes?.profile_details?.data?.attributes?.image
      : noProfileImg;
  const isProfileImg = (item) =>
    item?.attributes?.profile_details?.data?.attributes?.image ? true : false;
  const hoverProfileImg = props.profilePicFromBackend
    ? baseURL + props.profilePicFromBackend
    : noProfileImg;
  const imgFromBackEnd = () => (props.profilePicFromBackend ? true : false);
  const currentViewClassName = () =>
    props.IdeaVersionDetails?.length < 8
      ? "currentViewDivLong"
      : "currentViewDiv";
  const hiddenClassName = (item) =>
    item.attributes.is_hidden ||
      item.attributes.is_reported ||
      item.attributes.is_hidden_account
      ? "hiddenIteartionDiv"
      : "";
  const isHiddenClass = (item) =>
    item.attributes.is_hidden ||
      item.attributes.is_reported ||
      item.attributes.is_hidden_account ||
      item.attributes.is_reported_account
      ? "op3"
      : "";
  const iteartorUserClass = () =>
    props.IdeaVersionDetails.length < 8
      ? "SubContainerNoScroll"
      : "iterationListSubContainer";
  const currentViewCondition = (item) =>
    !item.attributes.is_hidden &&
    !item.attributes.is_reported &&
    !item.attributes.is_hidden_account &&
    !item.attributes.is_reported_account;
  const reportORhidden = (item) =>
    item.attributes.is_hidden ||
    item.attributes.is_reported ||
    item.attributes.is_hidden_account ||
    item.attributes.is_reported_account;
  const currentViewDivClass = () =>
    originalCurrentViewIndex
      ? "currentViewProfileDiv"
      : "itrationListProfileDiv";
  const originalCurrentViewDivClass = () =>
    originalCurrentViewIndex ? "currentOrigViewDiv" : "iterationListDescDiv";
  const getValue = (state: any, value1: any, value2: any) => {
    return state ? value1 : value2
  }
  
  const checkLocalOrAnonymousUser = (username:string, profileDetailsId: string) =>{
    return username === localStorage.getItem("username") || profileDetailsId === localStorage.getItem("anonymousId")
  }
  const Profilebox = (
    index: number,
    idea: any,
    Image: any,
    username: string,
    isProfilePic: boolean,
    rigthAlign: string
  ) => {
    const userProfileId = props?.IdeaVersionDetails[index]?.attributes?.profile_details?.data?.id || props?.id;
    return (
      <>
        <div
          ref={absoluteRef}
          style={{
            position: "relative",
            top: "-4px",
            paddingTop: "14px",
            paddingLeft: "14px",
            width: "282px",
            height: "82px",
            borderRadius: "4px",
            border: "2px solid #0F2C40",
            background: "#0B2434",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.6)",
            right: rigthAlign,
          }}
        >
          <div className="ProfileBoxImageBig">
            <img
              src={Image}
              className={`hoverProfileBoxImg ${props.isHiddenUser || props.isReportedUser || idea?.attributes?.is_hidden_account ||
                idea?.attributes?.is_reported_account
                ? "op3"
                : ""
                }`}
            />
            {idea.attributes?.is_reported_account
              ? <img src={redReportIcon} className="hoverhiddenProfileImg" />
              : idea.attributes?.is_hidden_account && <img src={hiddenEye} className="hoverhiddenProfileImg" />
            }
          </div>
          <div className="ProfileBoxFullName">
            <b>
              <span>
                {props?.IdeaVersionDetails[index]?.attributes?.profile_details
                  ?.data?.attributes?.first_name
                  ? props?.IdeaVersionDetails[index]?.attributes
                    ?.profile_details?.data?.attributes?.first_name
                  : props?.FirstName}{" "}
              </span>
              <span>
                {props?.IdeaVersionDetails[index]?.attributes?.profile_details
                  ?.data?.attributes?.last_name
                  ? props?.IdeaVersionDetails[index]?.attributes
                    ?.profile_details?.data?.attributes?.last_name
                  : props?.LastName}
              </span>
            </b>
          </div>
          <div className="ProfileBoxUserName">
            <span>
              {props?.IdeaVersionDetails[index]?.attributes?.profile_details
                ?.data?.attributes?.user_name
                ? props?.IdeaVersionDetails[index]?.attributes?.profile_details
                  ?.data?.attributes?.user_name
                : props?.UserName}
            </span>
          </div>
          <div>
            <div style={{ display: "flex", gap: 12 }}>
              <button
                className={
                  checkLocalOrAnonymousUser(username, userProfileId)
                    ? "disabledBtn"
                    : "AddContactDiv"
                }
                disabled={checkLocalOrAnonymousUser(username, userProfileId)}
                data-testid="btnAddContact"
                onClick={() =>
                  props.sendFriendRequest(
                    userId(index),
                    props.requestSentArray?.includes(userId(index))
                  )
                }
              >
                {props.requestSentArray?.includes(userId(index)) ? (
                  <RemoveContact />
                ) : (
                  <AddContactIcon />
                )}

                <span className="AddContactText" style={{ fontSize: 11 }}>
                  {props.requestSentArray?.includes(userId(index))
                    ? "Remove"
                    : "Add Contact"}
                </span>
              </button>
              <Link
                style={{ textDecoration: "none" }}
                to={isTheProfileSame ? `#` : `/UserProfile/${userId(index)}`}
              >
                <div className="IdeaDiv">
                  <Search />
                  <span className="AddContactText" style={{ fontSize: 11 }}>
                    Show ideas
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };

  function useOutsideAlerter(ref, absRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current &&
          !ref.current.contains(event.target)) {
          if (absRef.current && absRef.current.contains(event.target)) {
            setShowIteartionList(true);
          } else {
            setShowIteartionList(false);
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, absRef]);
  }
  const hiddenAndReportedDiv = (item: any) => (
    <div className="iterationListDescDiv hiddenIteartionDiv">
      {item.attributes.is_hidden &&
        !item.attributes.is_reported &&
        !item.attributes.is_hidden_account &&
        !item.attributes.is_reported_account && (
          <p className="txtHiddenIteartion">{configJSON.hiddenIteration}</p>
        )}
      {item.attributes.is_reported &&
        !item.attributes.is_hidden_account &&
        !item.attributes.is_reported_account && (
          <p className="txtHiddenIteartion txtReportedIteration">
            {configJSON.reportedItartion}
          </p>
        )}
      {item.attributes.is_hidden_account &&
        !item.attributes.is_reported_account && (
          <p className="txtHiddenIteartion">{configJSON.hiddenUser}</p>
        )}
      {item.attributes.is_reported_account && (
        <p className="txtHiddenIteartion txtReportedIteration">
          {configJSON.reportedUser}
        </p>
      )}

      <p className="IterationListUsername blurName">
        {item.attributes?.profile_details?.data?.attributes?.user_name}
        <span className="IteartionListDate">
          {" "}
          |{" "}
          {props.formatDate(item.attributes?.created_at)}
        </span>
      </p>
    </div>
  );

  const getBlurProfileImg = (profileImg, isHiddenAccount, isReportedAccount) => {
    return (
      <div>
        <img
          src={profileImage(profileImg)}
          className={`iterationListProfileImg ${isHiddenClass(
            profileImg
          )}`}
        />
        {isReportedAccount ?
          <img className="hiddenProfileImg" src={redReportIcon} />
          : isHiddenAccount &&
          (<img className="hiddenProfileImg" src={hiddenEye} />)}

      </div>
    )
  }

  const IterationUsers = () => {
    return (
      <div style={{ position: "relative", right: "271px" }} ref={wrapperRef}>
        <div className={iteartorUserClass()} >
          {props?.IdeaVersionDetails?.map((item: any, index) => {
            if (currentView && currentViewIndex == index) {
              return (
                <div
                  className="iterationListContainer currentViewBorder"
                  key={index}
                  data-testid="btnListViewContainer"
                  onClick={() => {
                    setCurrentViewIndex(index);
                    setCurrentView(true);
                    props.AssigningState(item.id, false, 7);
                  }}
                >
                  <div className="currentViewProfileDiv">
                    <OnHoverProfileBoxToolTip
                      arrow={true}
                      title={Profilebox(
                        index,
                        item,
                        profileImage(item),
                        item?.attributes?.profile_details?.data?.attributes
                          ?.user_name,
                        isProfileImg(item)
                      )}
                      placement="bottom-start"
                      interactive={true}
                      id={"iterationlistCurrentView"}
                    >
                      <img
                        src={profileImage(item)}
                        className="iterationListProfileImg"
                      />
                    </OnHoverProfileBoxToolTip>
                  </div>
                  <div className={currentViewClassName()}>
                    <p
                      className="txtHiddenIteartion"
                      style={{ color: "#86A2B4", fontWeight: 700 }}
                    >
                      {configJSON.currentView}
                    </p>
                    <p className="IterationListUsername mt3">
                      {
                        item.attributes?.profile_details?.data?.attributes
                          ?.user_name
                      }
                      <span className="IteartionListDate">
                        {" "}
                        |{" "}
                        {props.formatDate(item.attributes?.created_at as string)}
                      </span>
                    </p>
                  </div>
                  <div className="shareDiv">
                    <div className="subShareDiv">
                      {shareDiv(index, null)}
                      {upVoteDiv(index, null)}
                    </div>
                    <div className="subShareDiv">
                      {saveDiv(index, null)}
                      {downVoteDiv(index, null)}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="iterationListContainer"
                  key={index}
                  data-testid="btnListContainer"
                  onClick={() => {
                    if (currentViewCondition(item)) {
                      setCurrentViewIndex(index);
                      setOriginalCurrentViewIndex(false);
                      setUpVoteCount(false);
                      setDownVoteCount(false);
                      setSaveVoteCount(false);
                      setShareVoteCount(false);
                    }
                    props.handleOrgIdea(props.parentID);
                    setCurrentView(currentViewCondition(item));
                    props.AssigningState(item.id, false, 7);
                  }}
                  onMouseOver={() =>
                    props.handleHoveredText(
                      item?.attributes?.idea_context?.data?.attributes
                        ?.description,
                      item?.attributes?.idea_problem?.data?.attributes
                        ?.description,
                      item?.attributes?.idea_solution?.data?.attributes
                        ?.description,
                      true
                    )
                  }
                  onMouseLeave={() =>
                    props.handleHoveredText("", "", "", false)
                  }
                >
                  <div
                    className={`itrationListProfileDiv ${hiddenClassName(
                      item
                    )}`}
                  >
                    <OnHoverProfileBoxToolTip
                      arrow={true}
                      title={Profilebox(
                        index,
                        item,
                        profileImage(item),
                        item?.attributes?.profile_details?.data?.attributes
                          ?.user_name,
                        isProfileImg(item)
                      )}
                      placement="bottom-start"
                      interactive={true}
                      id={"iterationlist"}
                    >
                      {getBlurProfileImg(item, item.attributes.is_hidden_account, item.attributes.is_reported_account)}
                    </OnHoverProfileBoxToolTip>
                  </div>
                  {reportORhidden(item) ? (
                    hiddenAndReportedDiv(item)
                  ) : (
                    <div className="iterationListDescDiv">
                      <p className="IterationListUsername">
                        {
                          item.attributes?.profile_details?.data?.attributes
                            ?.user_name
                        }
                        <span className="IteartionListDate">
                          {" "}
                          |{" "}
                          {props.formatDate(item.attributes?.created_at)}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
        <div className={`iterationListContainer ${originalCurrentViewIndex && 'currentViewBorder'}`} style={{ width: `${getValue(props.IdeaVersionDetails.length > 7, "310px", "302px")}` }}>
          <div className={currentViewDivClass()}>
            <OnHoverProfileBoxToolTip
              arrow={true}
              title={Profilebox(
                null,
                props.fulldata,
                hoverProfileImg,
                props.fulldata?.attributes?.profile_details?.data?.attributes
                  ?.user_name,
                imgFromBackEnd
              )}
              placement="bottom-start"
              interactive={true}
              id={"iterationlistOriginalidea"}
            >
              <img src={hoverProfileImg} className="iterationListProfileImg" />
            </OnHoverProfileBoxToolTip>
          </div>

          <div
            className={originalCurrentViewDivClass()}
            data-testid="btnCurrentView"
            onClick={() => {
              props.AssigningState(null, true);
              setCurrentView(false);
              setOriginalCurrentViewIndex(true);
              setUpVoteCount(false);
              setDownVoteCount(false);
              setSaveVoteCount(false);
              setShareVoteCount(false);
              props.handleOrgIdea(props.orgId);
            }}
            onMouseOver={() =>
              props.handleHoveredText(
                props.fulldata?.attributes?.idea_context?.data?.attributes
                  ?.description,
                props.fulldata?.attributes?.idea_problem?.data?.attributes
                  ?.description,
                props.fulldata?.attributes?.idea_solution?.data?.attributes
                  ?.description,
                true
              )
            }
            onMouseLeave={() => props.handleHoveredText("", "", "", false)}
            style={{ width: "250px" }}
          >
            <p className="txtHiddenIteartion txtOriginalIdea">
              {" "}
              {originalCurrentViewIndex && (
                <span
                  className=".txtHiddenIteartion"
                  style={{ color: "#86A2B4" }}
                >
                  {configJSON.currentView}
                </span>
              )}
              {configJSON.originalIdea}
            </p>
            <p className="IterationListUsername mt3">
              {
                props.fulldata?.attributes?.profile_details?.data?.attributes
                  ?.user_name
              }
              <span className="IteartionListDate">
                {" "}
                |{" "}
                {props.formatDate(props.fulldata?.attributes?.created_at)}
              </span>
            </p>
          </div>
          {originalCurrentViewIndex && (
            <div className="shareDiv">
              <div className="subShareDiv">
                {shareDiv(null, props.fulldata)}
                {upVoteDiv(null, props.fulldata)}
              </div>
              <div className="subShareDiv">
                {saveDiv(null, props.fulldata)}
                {downVoteDiv(null, props.fulldata)}
              </div>
            </div>
          )}
        </div>
        <div className="iteartionListBtnContainer">
          <span>
            <div className="feedFilterDiv w-15">
              <ClickAwayListener onClickAway={props.handleTooltipCloseContent}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                    popperOptions: {
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: "40px, 0px",
                        },
                      },
                      tooltipPlacementRight: {
                        right: "1px",
                        top: "80px",
                      },
                    },
                  }}
                  onClose={props.handleTooltipCloseContent}
                  open={props.openPlusDialogContent}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  classes={{
                    tooltip: "menu-pop-up",
                    arrow: "menu-pop-up-arrow",
                  }}
                  interactive={true}
                  title={
                    <React.Fragment>
                      <FeedDropDown
                        id=""
                        navigation={undefined}
                        feedFilter={props.iterationListTitle}
                        handleFeedFilter={props.handleIterationListFilterText}
                        feedOptions="iterationListOptions"
                      />
                    </React.Fragment>
                  }
                  arrow={true}
                  placement="right-start"
                >
                  <div
                    className="filterContainer"
                    onClick={props.handlePlusContent}
                  >
                    <p className={`filterItrText ${getValue(props.openPlusDialogContent,"text-white", null)}`}>
                      {props.iterationListTitle}
                    </p>
                    <span
                      className={`${props.openPlusDialogContent
                        ? "feedDropDownIconTransitionOpen"
                        : "feedDropDownIconTransitionClose"
                        }`}
                    >
                      <DropDownIcon />
                    </span>
                  </div>
                </Tooltip>
              </ClickAwayListener>
            </div>
          </span>
          <span
            data-testid="btnCloseList"
            onClick={() => setShowIteartionList(false)}
          >
            <div className="filterContainer closeBtn">Close</div>
          </span>
        </div>
      </div>
    );
  };

  const shareDiv = (index: any, fulldata: any) => {
    return shareVoteCount ? (
      <button
        style={{ background: "#13344A" }}
        data-testid="shareVoteCount1"
        onClick={(e) => {
          e.preventDefault();
          setShareVoteCount(false);
        }}
      >
        <img className="smallIcon" src={shareIcon} />
        <p className="currentViewBtnTxt">{props.shareCount}</p>
      </button>
    ) : (
      <button
        data-testid="shareVoteCount"
        onClick={(e) => {
          props.handleShareBtnSubmit();
          e.preventDefault();
          setShareVoteCount(true);
          setSaveVoteCount(false);
          setUpVoteCount(false);
          setDownVoteCount(false);
        }}
      >
        <img src={shareIcon} />
      </button>
    );
  };

  const upVoteDiv = (index: any, fulldata: any) => {
    return upVoteCount ? (
      <button
        data-testid="upVoteCount1"
        style={{ borderRadius: "0px 4px 0px 0px", background: "#13344A" }}
        onClick={(e) => {
          e.preventDefault();
          setUpVoteCount(false);
        }}
      >
        <img className="smallIcon" src={upArrow} />
        <p className="currentViewBtnTxt">{props.upCount}</p>
      </button>
    ) : (
      <button
        style={{ borderRadius: "0px 4px 0px 0px" }}
        data-testid="upVoteCount"
        onClick={(e) => {
          props.handleUpVoteSubmit();
          e.preventDefault();
          setUpVoteCount(true);
          setDownVoteCount(false);
          setShareVoteCount(false);
          setSaveVoteCount(false);
        }}
      >
        <img src={upArrow} />
      </button>
    );
  };

  const saveDiv = (index: any, fulldata: any) => {
    return saveVoteCount ? (
      <button
        data-testid="saveVoteCount1"
        style={{ background: "#13344A", cursor: `${cursorNone ? "not-allowed" : "pointer"}` }}
        onClick={(e) => {
          setCursorNone(true)
          setTimeout(() => {
            props.handleSaveClickSubmit();
            setCursorNone(false)
          }, 3000);
          e.preventDefault();
        }}
      >
        <img className="smallIcon" src={saveIcon} />
        <p className="currentViewBtnTxt">{props.saveCount}</p>
      </button>
    ) : (
      <button
        data-testid="saveVoteCount"
        onClick={(e) => {
          props.handleSaveClickSubmit();
          e.preventDefault();
          setSaveVoteCount(true);
          setUpVoteCount(false);
          setDownVoteCount(false);
          setShareVoteCount(false);
        }}
      >
        <img src={saveIcon} />
      </button>
    );
  };

  const downVoteDiv = (index: any, fulldata: any) => {
    return downVoteCount ? (
      <button
        data-testid="downVoteCount1"
        style={{ background: "#13344A", borderRadius: "0px 0px 4px 0px" }}
        onClick={(e) => {
          e.preventDefault();
          setDownVoteCount(false);
        }}
      >
        <img className="smallIcon" src={downArrow} />
        <p className="currentViewBtnTxt">{props.downCount}</p>
      </button>
    ) : (
      <button
        data-testid="downVoteCount"
        onClick={(e) => {
          props.handleDownVoteSubmit();
          e.preventDefault();
          setDownVoteCount(true);
          setUpVoteCount(false);
          setSaveVoteCount(false);
          setShareVoteCount(false);
        }}
      >
        <img style={{ borderRadius: "0px 0px 4px 0px" }} src={downArrow} />
      </button>
    );
  };

  if (props.isProfileHover) {
    return showIterationList ? (
      IterationUsers()
    ) : (
      <>
        {props.IdeaVersionDetails?.length > 0 &&
          props.IdeaVersionDetails?.map((idea: any, index: number) => {
            if (index === 0) {
              return (
                <OnHoverProfileBoxToolTip
                  arrow={false}
                  title={Profilebox(
                    index,
                    idea,
                    profileImage(idea),
                    idea?.attributes?.profile_details?.data?.attributes
                      ?.user_name,
                    isProfileImg(idea),
                    "40px"
                  )}
                  placement="left"
                  interactive={true}
                  id={"onHoverProfileBox"}
                  key={index}
                >
                  <img
                    src={profileImage(idea)}
                    data-testid="btnShowUser"
                    onClick={() => props.AssigningState(idea.id, false, index)}
                    alt=""
                    className={`iteratedUser ${idea?.attributes?.profile_details?.data?.attributes?.image
                      ? ""
                      : "noProfileImg"
                      } ${index == 0 ? "mainProfilePic" : "imgIndexClassName"}`}
                  />
                </OnHoverProfileBoxToolTip>
              );
            }
          })}
        {props.IdeaVersionDetails?.length > 0 && (
          <div>
            <img src={moreIcon} className="MoreProfileIcon" />
            {props.IdeaVersionDetails.length > 1 ? (
              <span
                data-testid="btnIteartionCount"
                className="IteartionCount"
                onClick={() => setShowIteartionList(true)}
              >
                +{props.IdeaVersionDetails?.length - 1}
              </span>
            ) : (
              ""
            )}
          </div>
        )}

        {/* below is for Parent ToolTip */}
        <OnHoverProfileBoxToolTip
          arrow={false}
          title={Profilebox(
            null,
            props.fulldata,
            hoverProfileImg,
            props.fulldata?.attributes?.profile_details?.data?.attributes
              ?.user_name,
            imgFromBackEnd,
            "48px"
          )}
          placement="left"
          interactive={true}
          id={"onHoverProfileBox"}
        >
          <img
            src={hoverProfileImg}
            alt="iteratedUsers"
            className={`iteratedUser ${props?.IdeaVersionDetails?.length == 0 && "mainProfilePic"
              }  ${props?.IdeaVersionDetails?.length > 1 && "profilePicLast"}  ${props?.IdeaVersionDetails?.length == 1 && "twiImgProfilePicLast"
              }`}
            data-testid="btnShowlastUser"
            onClick={() => props.AssigningState(null, true)}
          />
        </OnHoverProfileBoxToolTip>
      </>
    );
  } else {
    return (
      <OnHoverProfileBoxToolTip
        arrow={true}
        title={Profilebox(
          null,
          props.fulldata,
          hoverProfileImg,
          props.UserName,
          imgFromBackEnd,
          "10px"
        )}
        placement="bottom-start"
        interactive={true}
        id={"onHoverProfileBox"}
      >
        <p className="feedUser">{props.UserName as string} </p>
      </OnHoverProfileBoxToolTip>
    );
  }  // Customizable Area End
};

export default OnHoverProfilebox;