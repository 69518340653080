// Customizable Area Start
import { Box, withStyles } from '@material-ui/core';
import React from 'react'
import { styles } from '../../../components/src/WebHeader/NotificationPopUp';
import { personImg, noUserImg, bellImg, noBellImg } from './assets';
import NotificationsController from './NotificationController.web';
import "./PendingFriendRequests.css"
class VerticalDotsDropDown extends NotificationsController {
    render() {
        const { classes } = this.props;
        return (
            <>
                <Box className={classes.verticalDropBox} >
                    {this.props.is_notify ?
                        <p className="menu-title verticalDropDownText" onClick={this.props.handleUserNotication} ><span style={{ margin: '0 10' }} ><img src={personImg} width={"20px"} /></span>Notifications from this user ON</p>
                        :
                        <p className="menu-title verticalDropDownText" onClick={this.props.handleUserNotication} ><span style={{ margin: '0 10' }}><img src={noUserImg} width={"20px"} /></span>Notifications from this user OFF</p>
                    }
                </Box>
                <Box
                    className={classes.verticalDropBox}
                >
                    {this.props.ideaBoxnotify ?
                        <p className="menu-title verticalDropDownText" data-testid="testPTag" onClick={this.props.handleIdeaBoxNotification}><span style={{ margin: '0 10' }}><img src={bellImg} width={"20px"} /></span>Notifications from this idea ON</p>
                        : <p className="menu-title verticalDropDownText" onClick={this.props.handleIdeaBoxNotification}><span style={{ margin: '0 10' }}><img src={noBellImg} width={"20px"} /></span>Notifications from this idea OFF</p>
                    }
                </Box>
            </>
        )
    }
}

export default withStyles(styles)(VerticalDotsDropDown);
export { VerticalDotsDropDown };

// Customizable Area End