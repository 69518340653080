// Customizable Area Start 
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import UserDetailsContext from "../../../web/src/Context/Context.web";
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";
let FormData = require('form-data');
import { AddImage, NoImg } from "./assets";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  handleDate: (value: string, type: "day" | "month" | "year") => void;
  day?: number;
  month?: number;
  year?: number;
  errorMsg?: string;
  dayErr: boolean;
  monthErr: boolean;
  yearErr: boolean;
  history?: any;
  imgUrl: any;
  handleImage: (img: any, file: any) => void;
  handleCloseDailog: (img: any) => void;
  modelTitle: any;
  img: any;
  // onClose:any;
}

interface S {
  firstName: any;
  middleName: string;
  lastName: any;
  date: any;
  day: any;
  month: any;
  year: any;
  dateOfBirth: any;
  selectedDate: any
  city: string;
  country: string;
  background: string;
  deleteModalOpen: boolean;
  profilePic: any;
  profilePicImageCrop: any
  imageData: any;
  profileBanner: any;
  profileBannerimg: any;
  deleteAccountPassword: string;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  profilePicFromBackEnd: any;
  bannerPicFromBackEnd: any;
  passwordIncorrent: boolean;
  errorMsg: string;

  errorMsgText: string;
  isFirstNameError: boolean;
  isMiddleNameError: boolean;
  isDayError: boolean;
  isMonthError: boolean;
  isYearError: boolean;
  isLastNameError: boolean;
  isCityError: boolean;
  isCountryError: boolean;
  isBackgroundError: boolean;
  isAccountDeleted: boolean;

  isImageDailog: boolean;
  isImageCropDailog: boolean;
  isEditImageDailog: boolean;
  isBackgroundImageDailog: boolean;
  isBackImageDailog: boolean;

  cropImage: any;
  zoomImage: any;
  rotationImage: any;
  croppedAreaPixels: any;
  profileBannerimgCrop: any;
  isProfileImageTypeError: boolean;

  isAlertOpen: boolean;
  alertMessageText: string;
  apiResponse: any;
}

interface SS {
  id: any;
}

export default class UserProfileBasicControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  nameReg: RegExp;
  backgroundReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  userProfileUploadApiID: string = "";
  deleteApiCallId: any;
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      isBackImageDailog: false,
      isBackgroundImageDailog: false,
      isEditImageDailog: false,
      isImageCropDailog: false,
      isImageDailog: false,
      dateOfBirth: {
        day: null,
        month: null,
        year: null
      },
      firstName: "",
      lastName: "",
      email: "",
      date: null,
      day: "",
      month: "",
      year: "",
      selectedDate: new Date().toISOString().slice(0, 10),
      deleteModalOpen: false,
      profilePic: null,
      profilePicImageCrop: null,
      profileBannerimgCrop: null,
      city: "",
      country: "",
      background: "",
      middleName: "",
      imageData: null,
      profileBanner: null,
      profileBannerimg: null,
      deleteAccountPassword: "",

      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      profilePicFromBackEnd: null,
      bannerPicFromBackEnd: null,
      passwordIncorrent: false,
      errorMsg: "",
      errorMsgText: "",
      isFirstNameError: false,
      isMiddleNameError: false,
      isLastNameError: false,
      isDayError: false,
      isMonthError: false,
      isYearError: false,
      isCityError: false,
      isCountryError: false,
      isBackgroundError: false,
      isAccountDeleted: false,
      cropImage: { x: 0, y: 0 },
      zoomImage: 1,
      rotationImage: 0,
      croppedAreaPixels: null,
      isProfileImageTypeError: false,
      isAlertOpen: false,
      alertMessageText: "",
      apiResponse: {}
    };

    this.arrayholder = [];
    this.passwordReg = /\\w+/;
    this.emailReg = /\\w+/;
    this.nameReg = new RegExp("^[A-Za-z ]+$");
    this.backgroundReg = new RegExp("^[A-Za-z ]+$");
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.deleteApiCallId:
            this.handleDeleteAccount(responseJson);
            break;
          case this.userProfileUploadApiID:
            this.handleUpdateProfileRes(responseJson);
            break;
          case this.userProfileGetApiCallId:
            this.handleGetUserProfile(responseJson);
            break;
          default:
            break;
        }
      }
    }
  }

  handleUpdateProfileRes(responseJson: any) {
    this.setState({ apiResponse: responseJson })
    if (responseJson?.data?.attributes?.image) {
      this.context.setProfilePic(
        `${baseURL}${responseJson?.data?.attributes?.image}`
      );
      localStorage.setItem("profilepic", responseJson?.data?.attributes?.image)
    }
    this.handleUpdateProfile(responseJson)
  }

  handleDeleteAccount(responseJson: any) {
    this.setState({ apiResponse: responseJson });
    if (responseJson.message === " Successfully account deleted") {
      this.setState({
        isAccountDeleted: true,
      });
      setTimeout(() => {
        localStorage.clear();
        this.props?.history?.push("/");
      }, 5000);
    } else {
      this.handleDeleteAccountError(responseJson)
    }
  }

  handleDeleteAccountError(responseJson: any) {
    this.setState({ apiResponse: responseJson });
    if (responseJson.status === 500) {
      this.setState({
        errorMsg: "Internal Server Error. Try again after sometime ",
        passwordIncorrent: true,
      });
    } else if (responseJson.errors[0].password) {
      this.setState({
        errorMsg: responseJson.errors[0].password,
        passwordIncorrent: true,
      });
    } else if (responseJson.errors[0].token) {
      this.setState({
        errorMsg: "Invalid Token. Please login again. Redirecting...",
        passwordIncorrent: true,
      });
      setTimeout(() => {
        this.props?.history?.push("/");
      }, 2000);
    } else if (responseJson.errors) {
      this.setState({
        errorMsg: responseJson.errors || "Something went wrong!",
        passwordIncorrent: true,
      });
    } else {
      this.setState({
        errorMsg: "Something went wrong!",
        passwordIncorrent: true,
      });
    }

  }

  handleGetUserProfile(responseJson: any) {
    const responseData = responseJson?.data?.attributes;
    localStorage.setItem("profilepic", responseData?.image);
    this.setState({apiResponse: responseJson})
    if (!responseJson.errors) {
      this.setState({
        firstName: responseData?.first_name,
        middleName: responseData?.middle_name !== "null" ? responseData?.middle_name : "",
        lastName: responseData?.last_name,
        email: responseData?.email,
        day: responseData?.day ?? "",
        month: responseData?.month ?? "",
        year: responseData?.year ?? "",
        city: responseData?.city !== "null" ? responseData?.city : "",
        country: responseData?.country !== "null" ? responseData?.country : "",
        background:
          responseData.background !== "null"
            ? responseData.background
            : "",
      });

      this.setBannerImg(responseData);
    } else if (responseJson.errors[0].token) {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Please login again. Redirecting..."
      })
      setTimeout(() => {
        this.goToEmailAccountLogin(true)
      }, 2000);
    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: responseJson.error || responseJson.message || "Something went wrong!"
      })
    }
  }

  setBannerImg(responseData: any) {
    if (responseData.banner)
      this.setState({
        profileBannerimg: null,
        bannerPicFromBackEnd: `${baseURL}${responseData.resize_banner != null ? responseData.resize_banner : responseData.banner}`,
      });

    if (responseData.image) {
      this.setState({
        profilePicFromBackEnd: `${baseURL}${responseData.resize_image != null ? responseData.resize_image : responseData.image}`,
      });
      this.context.setProfilePic(
        `${baseURL}${responseData.resize_image != null ? responseData.resize_image : responseData.image}`
      );
    } else {
      this.context.setProfilePic(null);
    }
  }

  handleUpdateProfile(responseJson: any) {
    if (responseJson?.meta?.message) {
      this.setState({
        profilePic: null,
        profileBannerimg: null,
        isAlertOpen: true,
        alertMessageText: "Profile Updated!"
      });

      if (responseJson?.data?.attributes?.banner !== null) {
        this.setState({ bannerPicFromBackEnd: `${baseURL}${responseJson?.data?.attributes?.banner}` });
      } else {
        this.setState({ profileBannerimg: null });
      }
    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: responseJson.error || responseJson.message || "Something went wrong!"
      })
    }
  }

  handleCloseAlertMessagePopup = () => {
    this.setState({
      isAlertOpen: false,
      alertMessageText: ""
    })
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }
  handleZoomIn = () => this.setState({ zoomImage: this.state.zoomImage - 0.1 })
  handleZoomOut = () => this.setState({ zoomImage: this.state.zoomImage + 0.1 })
  handleRotationIn = () => this.setState({ rotationImage: this.state.rotationImage - 5 })
  handleRotationOut = () => this.setState({ rotationImage: this.state.rotationImage + 5 })
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }


  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUser
    );

    const header = {
      token: localStorage.getItem("authToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        profilePic: URL.createObjectURL(img),
      });
    }
  };


  validateDateOfBirth = () => {
    if (this.state.day && this.state.month && this.state.year) {
      this.setState({ ...this.state.dateOfBirth, dateOfBirth: { day: this.state.day, month: this.state.month, year: this.state.year } })
    }
  }


  isValidDate = () => {
    if (this.state.day === null) {
      this.setState({
        isDayError: true,
        errorMsgText: "Select Day",
      });
      return false;
    }
    if (this.state.month === null) {
      this.setState({
        isMonthError: true,
        errorMsgText: "Select month",
      });
      return false;
    }
    if (this.state.year === null) {
      this.setState({
        isYearError: true,
        errorMsgText: "Select Year",
      });
      return false;
    }
  }

  checkUtfNameValidation = (name: string) => {
    return this.isStringNullOrBlank(name) || !(name.match(/[\p{L}-]+/ug) || this.nameReg.test(name))
  }

  checkNameValidation = (name: string) => {
    return !this.isStringNullOrBlank(name) && !this.nameReg.test(name)
  }

  checkDateFieldValidation = () => {
    return this.isStringNullOrBlank(this.state.day) && this.isStringNullOrBlank(this.state.month) && this.isStringNullOrBlank(this.state.year)
  }

  checkAllValidations = () => {
    this.setState({
      isFirstNameError: false,
      isMiddleNameError: false,
      isLastNameError: false,
      isCityError: false,
      isCountryError: false,
      isBackgroundError: false,
      isDayError: false,
      isMonthError: false,
      isYearError: false
    });

    if (this.checkUtfNameValidation(this.state.firstName)) {
      this.setState({
        isFirstNameError: true,
        errorMsgText: configJSON.errorNameNotValid,
      });
      return false;
    }

    if (!this.isStringNullOrBlank(this.state.middleName)) {
      if (this.checkUtfNameValidation(this.state.middleName)) {
        this.setState({
          isMiddleNameError: true,
          errorMsgText: configJSON.errorNameNotValid,
        });
        return false;
      }
    }

    if (this.checkUtfNameValidation(this.state.lastName)) {
      this.setState({
        isLastNameError: true,
        errorMsgText: configJSON.errorNameNotValid,
      });
      return false;
    }

    if (this.checkNameValidation(this.state.city)) {
      this.setState({
        isCityError: true,
        errorMsgText: configJSON.errorNameNotValid,
      });
      return false;
    }

    if (this.checkNameValidation(this.state.country)) {
      this.setState({
        isCountryError: true,
        errorMsgText: configJSON.errorNameNotValid,
      });
      return false;
    }

    if (this.checkDateFieldValidation()) {
      //console.log("===")
    } else {
      if (this.isStringNullOrBlank(this.state.day)) {

        this.setState({
          isDayError: true,
          errorMsgText: "Select day"
        });
        return false;
      }

      if (this.isStringNullOrBlank(this.state.month)) {

        this.setState({
          isMonthError: true,
          errorMsgText: "Select Month",
        });
        return false;
      }

      if (this.isStringNullOrBlank(this.state.year)) {

        this.setState({
          isYearError: true,
          errorMsgText: "Select Year",
        });
        return false;

      }
    }

    this.uploadImageWithParsingURl();
  }


  uploadImageWithParsingURl() {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const formData = new FormData();
    formData.append("profile[first_name]", this.state.firstName);
    formData.append("profile[middle_name]", this.state.middleName);
    formData.append("profile[last_name]", this.state.lastName);
    formData.append("profile[day]", this.state.day);
    formData.append("profile[month]", this.state.month);
    formData.append("profile[year]", this.state.year);
    formData.append("profile[city]", this.state.city);
    formData.append("profile[country]", this.state.country);
    formData.append("profile[background]", this.state.background);
    if (this.state.imageData)
      formData.append("profile[image]", this.state.imageData);
    if (this.state.profileBanner)
      formData.append("profile[banner]", this.state.profileBanner);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileUploadApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    // localStorage.removeItem("profilepic")
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAccount = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const formData = new FormData();
    formData.append("password", this.state.deleteAccountPassword);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAPiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getExtension(filename: any) {
    return filename.split('.').pop()
  }
  setImageData = async (event: any) => {
    let file = event?.target?.files[0];
    this.setState({ isProfileImageTypeError: false })
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (img.name.match(/\.(jpg|jpeg|png|svg)$/)) {
        this.setState({ profilePicImageCrop: URL.createObjectURL(img) },
          () => { this.setState({ isImageCropDailog: true }) }
        )
      } else {
        this.setState({
          profilePic: null,
          isProfileImageTypeError: true,
          isAlertOpen: true,
          alertMessageText: "Selected images are not valid type!"
        })
      }

    }
  }

  setProfileBanner = async (event: any) => {
    let file = event?.target?.files[0];

    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      if (img.name.match(/\.(jpg|jpeg|png|svg)$/)) {
        this.setState({
          profileBannerimgCrop: URL.createObjectURL(img),
          isBackgroundImageDailog: true
        });
      } else {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "Selected images are not valid type!"
        })
      }
    }
  };

  goToEmailAccountLogin = (value: boolean) => {
    const msg: Message = new Message(getName(MessageEnum.NewPostIdeaMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), value);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
    this.send(msg);
  }

  async componentDidMount() {
    super.componentDidMount();
  }


  isOrStyle = (state1: boolean, state2: boolean, style1: string, style2: string) => {
    return state1 || state2 ? style1 : style2
  }

  checkDateYearValidation = () => {
    if (this.state.month === "1") {
      if (this.state.day >= 29) {
        this.setState({ day: 29 });
      }
    }
    if (
      this.state.month === "3" ||
      this.state.month === "5" ||
      this.state.month === "8" ||
      this.state.month === "10"
    ) {
      if (this.state.day >= 30) {
        this.setState({ day: 30 });
      }
    }
  }

  checkPath = (pathName: any) => {
    return pathName === "/UserProfileBasicBlock/AlertWeb" ? "Unsaved images will be discarded. You sure you want to continue?" : true
  }

  getBannerImg = () => {
    return this.state.profileBannerimg || this.state.bannerPicFromBackEnd || NoImg
  }

  getProfileCropImg = () => {
    return this.state.profileBannerimgCrop || this.context.bannerPicFromBackEnd || AddImage
  }

  getProfileImg = () => {
    return this.state.profilePic || this.context.profile_pic || AddImage
  }
  getProfileImgCrop = () => {
    return this.state.profilePicImageCrop || this.context.profile_pic || AddImage
  }

}

UserProfileBasicControllerWeb.contextType = UserDetailsContext;

// Customizable Area End