import React from "react";
// Customizable Area Start
import FriendListControllerWeb, {
  Props,
} from "./FriendListController.web";
import "./FriendsList.css";
import { Box, Button, Tooltip, MenuItem, Menu } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import FriendPageMenu from "./FriendPageMenu";
import { contactSearch, listMenu, groupMenu, noProfileImg, downArrow } from "./assets";
import FriendPageList from "./FriendPageList";
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";
import UserProfileCard from "./UserProfileCard.web";
import ActivityPopup from "../../ActivityFeed/src/ActivityPopup.web";


const contactList = [
  {
    id: "contacts",
    displayName: 'Contacts',
    value: 'contacts'
  },
  {
    id: "reportedUsers",
    displayName: 'Reported Users',
    value: 'ReportedUsers'
  },
  {
    id: "hiddenUsers",
    displayName: 'Hidden Users',
    value: 'HiddenUsers'
  }
]
// Customizable Area End
export default class FriendList extends FriendListControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAllContactsList();
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <UserProfileCard
          bannerFromBackend={this.state.bannerFromBackend}
          userProfilePic={
            this.state.profilePicFromBackend
          }
          reportedUser={false}
          hiddenUser={false}
          userName={this.state.userName}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          userEmail={this.state.userEmail}
          profileId={""}
          handleSendFriendRequest={undefined}
          requestSentArray={undefined}
          navigation={undefined}
          id={""}
        />
        <section className="friendSearchContainer">
          <p className="contactsHeading">{this.state.searchTerm?.length == 0 ?
            <div>
              <Button
                id="selectedContact"
                aria-controls={this.isRenderCondition(this.state.open, 'basic-menu', undefined)}
                aria-haspopup="true"
                aria-expanded={this.isRenderCondition(this.state.open, 'true', undefined)}
                onClick={this.handleClick}
                className="boxOption"
              >
                {this.state.selectedContactList}
                <img style={{ marginLeft: "13px" }} src={downArrow} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                style={{ top: "50px" }}
                className="contactMenu"
              >
                {contactList.map(item => {
                  return (
                    <MenuItem key={item.id} id={item.id} className="selectOption" onClick={() => this.handleSelectOption(item.displayName, item.value)}>{item.displayName}</MenuItem>
                  )
                })}
              </Menu>
            </div>
            : "Results"}
          </p>

          <p className="contactsCount">({this.state.friendList.length})</p>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <div className="contactSeachContainer">
            <InputBase
              id="searchContact"
              type="text"
              autoComplete="off"
              className="searchInputText"
              onChange={(e) => this.handleSearch(e)}
              endAdornment={<img src={contactSearch} />}
            />
          </div>
          <Button id="changeMenu" className="cntMenuSelection" onClick={this.changeMenu}>
            <Tooltip
              title={
                <span className="tooltipGrid">
                  {`Switch to ${this.isRenderCondition(this.state.isGridView, 'list', 'grid')} view`}
                </span>
              }
            >
              <img src={this.isRenderCondition(this.state.isGridView, listMenu, groupMenu)} className="cntMenuIcon" />
            </Tooltip>
          </Button>
        </section>

        <section className="FriendPageMenuContainer">
          {this.state.friendList.map((item: any) => {
            let profileID = item.id;
            const { banner, image, email, user_name } = item.attributes;
            console.log('san item', item);

            return (
              <React.Fragment key={profileID}>
                {this.state.isGridView ?
                  <FriendPageMenu
                    navigation={undefined} id={""} open={false} anchorEl={null}
                    friendBanner={this.isRenderCondition(banner, `${baseURL}${banner}`, null)}
                    friendProfilePic={this.isRenderCondition(image, `${baseURL}${image}`, noProfileImg)}
                    friendProfileImg={this.isRenderCondition(image, "contactPicImg", "contactPicNoImg")}
                    friendFullName={item.fullName}
                    friendUserName={user_name}
                    friendEmail={""}
                    friendIdeasId={profileID}
                    handleRemoveContact={() => this.removeContact(profileID)}
                    reportID={profileID}
                    reportUserProfileFun={this.handleReportContact}
                    unReportUserProfileFn={() => this.unReportUserProfile(profileID)}
                    ideaVisibilityOffFun={this.userProfileIdeaVisibility}
                    ideaVisibilityOnFun={this.userProfileIdeaVisibility}
                    ideaVisibleBoolean={item.attributes.visibility_status}
                    reportedStatus={item.attributes.is_reported}
                    hiddenStatus={item.attributes.is_hidden}
                    selectedContactListType={this.state.selectedContactList}
                  /> :
                  <FriendPageList
                    navigation={undefined} id={""} open={false} anchorEl={null}
                    friendBanner={undefined}
                    friendProfilePic={this.isRenderCondition(image, `${baseURL}${image}`, noProfileImg)}
                    friendProfileImg={this.isRenderCondition(image, "contactPicImg", "contactPicNoImg")}
                    friendFullName={item.fullName}
                    friendUserName={user_name}
                    friendEmail={email}
                    friendIdeasId={profileID}
                    handleRemoveContact={() => this.removeContact(profileID)}
                    reportID={profileID}
                    reportUserProfileFun={this.handleReportContact}
                    unReportUserProfileFn={() => this.unReportUserProfile(profileID)}
                    ideaVisibilityOffFun={this.userProfileIdeaVisibility}
                    ideaVisibilityOnFun={this.userProfileIdeaVisibility}
                    ideaVisibleBoolean={item.attributes.visibility_status}
                    reportedStatus={item.attributes.is_reported}
                    hiddenStatus={item.attributes.is_hidden}
                    selectedContactListType={this.state.selectedContactList}
                  />
                }
              </React.Fragment>
            )
          })}
        </section>

        <ActivityPopup
          testId="submitpopup"
          open={this.state.isAlertOpen}
          title={this.state.alertMessageText}
          leftBtnTxt="ok"
          handleLeftBtn={this.handleCloseAlertMessagePopup}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End