// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import NotificationsController, {
  Props,
  configJSON,
} from "../../../blocks/notifications/src/NotificationController.web";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import "./WebHeader.css";
import NotificationPostFeed from "../../../blocks/notifications/src/NotificationPostFeed.web";

export const styles = (theme: any) => ({
  notificationText: {
    fontFamily: "Jost-Regular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "26px",
    // textTransform: "uppercase",
    color: "#6A8393",
  },
  otherBox: {
    width: 330,
    padding: 0,
    cursor: "default",
    minHeight: 50,
    backgroundColor:'#081b27',
    maxWidth:'max-content'
  },
  circularProgress: {
    width: 336,
    height: 68,
    background: "#030F17",
    borderRadius: 4,
    marginTop:12
  },
  noPendingNotification:{
   padding:"20px 16px",
   fontFamily: 'Jost-regular',
    fontWeight: 400,
    fontSize: 14,
    color: '#86A2B4',
  },
  RecentActivityBtn: {
    padding: "10px 0px",
    background: "#0D2738",
    marginTop: 20,
    borderRadius: 4,
    cursor: "pointer",
    width: 336
  },
  verticalDropBox: {
    cursor: "pointer",
    height: 40,
    display: "flex",
    alignItems: "center",
    margin: "0 !important",
    paddingRight: 5,
    "&:hover": {
      background: "#13344A",
      borderRadius: 4,
    },
  }
});

class NotificationPopUp extends NotificationsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    // this.getPendingRequests()
    this.getNotifications();
  }
  render() {
    const { classes } = this.props;
    if(this.state.isLoading){
      return (
        <>
          <Box className={classes.otherBox}>
        <Box style={{width:350}}>
          <Box className={`${classes.notificationText}`}>
           
        <b style={{marginLeft:16}} className={"notificationPopUpText"}>NOTIFICATIONS</b>
          <Box className="notificationScollbar">
            {this.state.isLoading && <div
                style={{
                  margin: "0.6rem 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
      )
    }
  
    else  {
      return (
        <>
        <Box className={classes.otherBox}>
          <Box style={{width:350}}>
            <Box className={`${classes.notificationText}`}> 
          <b style={{marginLeft:16}} className={"notificationPopUpText"}>NOTIFICATIONS</b>
            <Box style={this.state.notifications && this.state.notifications.length > 0 ? {maxHeight: 400,marginTop:12} : {height :"auto"}} className="notificationScollbar" >
            {this.state.notifications && this.state.notifications.length > 0 ? (
                this.state.notifications.map((each, index) => {
                  if (index < 15) {
                      return (
                        <Box
                          key={index}
                          className="notifyIcon"
                        > 
                          <NotificationPostFeed
                          isNotiPopUp={true}
                          friendRequest={each?.attributes?.notificationable_type !== "BxBlockSettings::AddIdea"}
                          friendRequestUpdate={this.friendRequestUpdate}
                          notificationDetail={each}
                          isNotify={this.notifyOnIdea}
                          isIdeaBox={this.notifyIdeaBox}
                          makeNotificationAsRead={() => this.makeNotificationAsRead(each?.attributes?.notificationable_id)}
                          navigation={undefined}
                          id={""}
                          classes={classes}
                          deleteNotifications={this.deleteNotifications}
                          getPendingRequests={this.getPendingRequests}
                          isPopup={false}
                        />
                      
                        </Box>
                      );
                    }
                })
              ) : (
                <Box className={`${classes.circularProgress}`}>
                  <div className={classes.noPendingNotification}>
                  No pending notification
                  </div>
                </Box>
              )}
            </Box>
            </Box>
          </Box>
        </Box>
         <Box
            className={"ActivitiShowBtn"}
            onClick={() => {
              this.props.closePopUP();
              this.props.history.push("/Notifications");
            }}
          >
            <Typography className="seeActivityText">See all activities</Typography>
          </Box>
        </>
     
      );
    }
   
  }
}
//@ts-ignore
export default withStyles(styles)(withRouter(NotificationPopUp));
// Customizable Area End
