// Customizable Area Start
import React from "react";

let jsdiff = require('diff');


const ActivityText = (props: { inputA: any; inputB: any }) => {
    const { inputA, inputB } = props;    
    let diff = jsdiff.diffWordsWithSpace(inputA, inputB);


    let result = diff.map(function (part: { removed: any; added: any; value: any }, index: any) {     
        let spanRemoved = part.removed ? 'textDeleted' : 'lightgrey';
        let spanClass = part.added ? "textAdded" : spanRemoved
        return (<span key={index} className={spanClass}>{part.value.replace(/ +(?= )/g,'')}</span>)
    });

    return (
        <div data-testid="activityText" className='diff-result'>{result}</div>
    )
}

export default ActivityText;

// Customizable Area End