import React from "react";
// Customizable Area Start
import EmailAccountRegistrationControllerWeb, {
  Props,
} from "./EmailAccountRegistrationControllerWeb";

import { Button } from "@material-ui/core";
import CustomInput from "../../../components/src/CustomInput";
import Alert from "@material-ui/lab/Alert";
import ProtectedRouteWeb from "../../email-account-login/src/ProtectedRoute.web";
import CustomMenuSideBar from "../../../components/src/CustomMenuSideBar";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";

// Customizable Area End
const configJSON = require("./config");

export default class ChangeEmailAccount extends EmailAccountRegistrationControllerWeb {
  constructor(props: Props) {
    // Customizable Area Start
    // Customizable Area End
    super(props);
  }
      // Customizable Area Start
    // Customizable Area End
  render() {
        // Customizable Area Start
    return (
      <ProtectedRouteWeb>
        <section className="passwordContainer sideBarContainer">
          <section className="sideBar">
            <CustomMenuSideBar />
          </section>
          <section className="sideBarInnerContainer profileInnerContainer">
            <h2 className="changePasswordHeader">
              {configJSON.labelChangeMail}
            </h2>
            <div className="LoginErrorBox">
              <CustomErrorAlert
                isError={this.state.isFieldEmpty}
                handleError={this.handleEmptyFieldError}
                errorMsg={configJSON.errorAllFieldsAreMandatory}
              />
            </div>
            <div className="changeEmailPwdContainer">
              <div className="form-group">
                <CustomInput
                  id="txtInputOldEmail"
                  placeholder={configJSON.labelOldMail}
                  handleInputChange={this.handleOldEmailInput}
                  isError={this.state.isOldEmailCorrect}
                  errorMsg={this.state.errorMsgText}
                  handleError={this.handleOldMailError}
                />
              </div>
              <div className="form-group">
                <CustomInput
                  id="txtInputNewMail"
                  placeholder={configJSON.labelNewMail}
                  handleInputChange={this.handleNewEmailInput}
                  isError={this.state.isEmailError}
                  errorMsg={this.state.errorMsgText}
                  handleError={this.handleNewMailError}
                />
              </div>
              <div className="emailRule">
                <p>{configJSON.labelMailRule1}</p>
                <p>{configJSON.labelMailRule2}</p>
                <p>{configJSON.labelMailRule3}</p>
              </div>
              <div className="form-group">           
                <CustomInput
                  id="txtInputConfirmNewMail"
                  placeholder={configJSON.labelConfirmNewMail}
                  handleInputChange={this.handleNewEmailConfirmInput}
                  isError={this.state.areBothMailIdsEqual}
                  errorMsg={this.state.errorMsgText}
                  handleError={this.handleBothMailsCorrectError}
                  pressEnter={this.handleSubmitEmail}
                />
              </div>                      
            </div>
            <div
              className={`alertContainer ${
                this.state.isMailChanged ? "" : "hideAlert"
              }`}
            >
              <Alert severity="success">{configJSON.LabelMailSuccessMsg}</Alert>
            </div>
            <div className="submitButton">
              <Button id="change-mail-button" onClick={this.handleChangeEmail}>
                {configJSON.btnChange}
              </Button>
            </div>
          </section>
        </section>
      </ProtectedRouteWeb>
    );
    // Customizable Area End
  }
}