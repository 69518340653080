import React from "react";
// Customizable Area Start
import EmailAccountRegistrationControllerWeb, {
  Props
} from "./EmailAccountRegistrationControllerWeb";
import {
  Button,
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
export const configJSON = require("./config");
// Customizable Area End

export default class CookieInfo extends EmailAccountRegistrationControllerWeb {
  constructor(props: Props) {
    // Customizable Area Start
    // Customizable Area End
    super(props);
  }
  // Customizable Area Start
  handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    this.setState({
      open: true,
      scroll: scrollType
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div>
        <Button
          id="openDialog"
          onClick={this.handleClickOpen("paper")}
          style={{
            fontSize: "10px",
            fontWeight: 500,
            textTransform: "uppercase",
            fontFamily: "Jost-Regular",
            color: "#6A8393",
            cursor: "pointer"
          }}
        >
          Information
        </Button>
        <Dialog
          id="closeDialog"
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="termsPopUpContainer"
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={this.handleClose}
            aria-label="close"
            className="close-icon"
          >
            <CloseIcon />
          </IconButton>

          <DialogTitle id="scroll-dialog-title">privacy policy</DialogTitle>
          <DialogContent
            className="privacyDialog"
            dividers={this.state.scroll === "paper"}
          >
            <p className="privacyContent">
              <span>{configJSON.privacyParagraph}</span>

              <ol>
                <li>
                  {configJSON.point1}<br/>
                  <span>{configJSON.subPoint1}</span>
                  <div className="privacySubOl">{configJSON.point11}</div>
                  <div className="privacySubOl">{configJSON.point12}</div>
                  <div style={{ marginTop: "28px", fontWeight: 400 }}>
                    {configJSON.point1footer}
                  </div>
                  <table>
                    <tr>
                      <th style={{ width: "60px" }} />
                      <th style={{ width: "120px" }}>{configJSON.table1th1}</th>
                      <th style={{ width: "280px" }}>{configJSON.table1th2}</th>
                    </tr>
                    <tr>
                      <td>{configJSON.table1td11}</td>
                      <td>{configJSON.table1td12}</td>
                      <td>{configJSON.table1td13}</td>
                    </tr>
                    <tr>
                      <td>{configJSON.table1td21}</td>
                      <td>{configJSON.table1td22}</td>
                      <td>{configJSON.table1td23}</td>
                    </tr>
                    <tr>
                      <td>{configJSON.table1td31}</td>
                      <td>{configJSON.table1td32}</td>
                      <td>{configJSON.table1td33}</td>
                    </tr>
                    <tr>
                      <td>{configJSON.table1td41}</td>
                      <td>{configJSON.table1td42}</td>
                      <td>{configJSON.table1td43}</td>
                    </tr>
                  </table>
                </li>
                <li>
                  {configJSON.point2}
                  <br />
                  <span>
                    {configJSON.subPoint2}
                    <ul>
                      <li>{configJSON.point21}</li>
                      <li>{configJSON.point22}</li>
                      <li>{configJSON.point23}</li>
                      <li>{configJSON.point24}</li>
                      <li>{configJSON.point25}</li>
                      <li>{configJSON.point26}</li>
                      <li>{configJSON.point27}</li>
                    </ul>
                  </span>
                </li>
                <li>
                  {configJSON.point3}
                  <br />
                  <span>{configJSON.subPoint3}</span>
                </li>
                <li>
                  {configJSON.point4}
                  <br />
                  <span>{configJSON.subPoint4}</span>
                  <table>
                    <tr>
                      <th style={{ width: "50%" }}>{configJSON.table2th1}</th>
                      <th>{configJSON.table2th2}</th>
                    </tr>
                    <tr>
                      <td>{configJSON.table2td11}</td>
                      <td>{configJSON.table2td12}</td>
                    </tr>
                    <tr>
                      <td>{configJSON.table2td21}</td>
                      <td>{configJSON.table2td22}</td>
                    </tr>
                    <tr>
                      <td>{configJSON.table2td31}</td>
                      <td>{configJSON.table2td32}</td>
                    </tr>
                  </table>
                </li>
                <li>
                  {configJSON.point5}
                  <br />
                  <span>{configJSON.subPoint5}</span>
                </li>
                <li>
                  {configJSON.point6}
                  <br />
                  <span>{configJSON.subPoint6}</span>
                  <ol style={{ fontWeight: 400, marginTop: "5px" }}>
                    <span>{configJSON.point61}</span>
                    <br />
                    <span>{configJSON.point62}</span>
                    <br />
                    <span>{configJSON.point63}</span>
                    <br />
                    <span>{configJSON.point64}</span>
                    <br />
                    <span>{configJSON.point65}</span>
                    <br />
                      <span>{configJSON.point66}</span>
                    </ol>
                    <span style={{ position:"relative", top:"4px" }}>{configJSON.point6footer}</span>
                </li>
                <li>
                  {configJSON.point7}
                  <br />
                  <span>
                    {configJSON.subPoint7}
                    <ul>
                      <li>{configJSON.point71}</li>
                      <li>{configJSON.point72}</li>
                      <li>{configJSON.point73}</li>or
                      <li>{configJSON.point74}</li>
                    </ul>
                  </span>
                </li>
                <li>
                  {configJSON.point8}
                  <br />
                  <span>{configJSON.subPoint8}</span>
                </li>
                <li>
                  {configJSON.point9}
                  <br />
                  <span>{configJSON.point91}</span>
                  <br />
                  <span>
                    {configJSON.point92}
                    <u>
                      <a>{configJSON.contactEmail}</a>
                    </u>
                  </span>
                </li>
              </ol>
            </p>
          </DialogContent>
        </Dialog>
      </div>
    );
    // Customizable Area End
  }
}
