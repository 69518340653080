Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.labelLoginTitle = "Login";
exports.placeHolderEmail = "E-mail";
exports.placeHolderPassword = "Password";
exports.labelForgotPassword = "Forgot password?";
exports.labelRememberMe = "Remember me";
exports.labelNoAccount = "Don't have an account yet?";
exports.labelSignUp = "Sign up";
exports.btnTxtLogin = "Sign in";
exports.errorPasswordNotValid ="Invalid format or too weak password. Minimum length: 8 characters. Please include a number, an uppercase letter, a lowercase letter, and a special character (e.g., ?, !, #)."
exports.errorEmailNotValid = "Email not valid.";
exports.errorStarredFieldsAreMandatory = "All fields are mandatory.";

exports.errorTitle = "Error";
exports.errorBlankField = "can't be blank";
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Incorrect password";
exports.errorLoginIncorrectMsg ="Incorrect e-mail or password."
exports.labelHeader = "The reason we require login";

exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.loginAPiURL= "bx_block_login/logins";
exports.forgotPasswordEndPoint= "forgot_password";

exports.labelCopyRight = "© 2021-2023 IDEA ATLAS LTD. All rights reserved.";
// Customizable Area End
